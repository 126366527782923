import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";

export const EditButon = ({target, targetData}) => {
    
  const navigate = useNavigate();
  
  return (
    <Button
	  onClick={(event)=>{navigate(target,{state:targetData?targetData:{}})}}
      variant="text"
      sx={{
        minWidth: "50px",
        width: "50px !important",
        height: "50px",
        p: 0,
        boxShadow: "0px 0px 10px 0.2px #0000001A",
        borderRadius: "32px",
      }}
    >
      <EditIcon />
    </Button>
  );
};
