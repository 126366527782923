import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { EditButon } from "./EditButon";
import { useSelector } from "react-redux";
import { t } from "i18next";

export const PersonalDataCard = ({ globalEvent, setGlobalEvent }) => {
  const { user } = useSelector((state) => state.user);

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                }}
              >
                {t("profile.personalData.title")}
              </Typography>

              <Box sx={{ mt: 3 }}>
                <Typography variant="body2">{user?.yearOfBirth}</Typography>
                <Typography variant="body2">
                  {user?.address?.country}
                </Typography>
                <Typography variant="body2">
                  {user?.address?.postalCode} {user?.address?.city}
                </Typography>
                <Typography variant="body2">{user?.phone}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <EditButon target="/add-user-data" targetData={{ state: {} }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
