import React from "react";
import { Modal, Box, Typography, Button, Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

const MessageModal = ({
  open,
  onClose,
  title,
  description,
  buttonText = "Ok",
  onAccept,
  icon: Icon,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            sx={{
              width: 100,
              maxWidth: 100,
              height: 100,
              p: "50px",
              bgcolor: "#FFF",
              boxShadow: "0 0 50px rgba(0,0,0,0.1)",
              borderRadius: "100px",
              mb: 3,
            }}
          >
            {Icon && React.isValidElement(Icon)
              ? Icon
              : Icon && (
                  <Icon sx={{ mr: 1, fontSize: "100px", color: "#62C275" }} />
                )}
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="div"
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "40px",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography
            id="modal-description"
            sx={{
              my: 2,
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "22px",
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            onClick={onAccept}
            className="secondary-blue-text-btn"
            variant="contained"
            sx={{
              width: "100%",
              height: "52px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              lineHeight: "20.16px",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                mr: 1,
                lineHeight: "20.16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {buttonText}
              <Box sx={{ ml: 2 }}>
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7929 5H1C0.723858 5 0.5 4.77614 0.5 4.5C0.5 4.22386 0.723858 4 1 4H10.7929L7.64645 0.853553C7.45118 0.658291 7.45118 0.341709 7.64645 0.146447C7.84171 -0.0488155 8.15829 -0.0488155 8.35355 0.146447L12.3536 4.14645C12.5488 4.34171 12.5488 4.65829 12.3536 4.85355L8.35355 8.85355C8.15829 9.04882 7.84171 9.04882 7.64645 8.85355C7.45118 8.65829 7.45118 8.34171 7.64645 8.14645L10.7929 5Z"
                    fill="#62C275"
                  />
                </svg>
              </Box>
            </Stack>
          </Button>
        </Box>
      </Box>
    </Snackbar>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FAFCFC",
  boxShadow: 24,
  p: "64px",
  borderRadius: "32px",
};

export default MessageModal;
