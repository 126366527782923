import { useCallback, useState, useRef } from "react";
import { Box, Paper, Typography, IconButton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

function AddPictureToAsset({ onFileUpload }) {
  const inputFile = useRef(null);

  const [dragOver, setDragOver] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const filesArray = Array.from(event.dataTransfer.files);
        onFileUpload(filesArray);
      }
    },
    [onFileUpload]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files.length > 0) {
        const filesArray = Array.from(event.target.files);
        onFileUpload(filesArray);
        event.target.value = null;
      }
    },
    [onFileUpload]
  );

  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? "2px dashed #2B36741A" : "2px dashed #2B36741A",
        borderRadius: "16px",
        padding: 32,
        textAlign: "center",
        cursor: "pointer",
        width: "fill 399px",
        background: dragOver ? "#eee" : "#FAFCFC",
      }}
    >
      <input
        ref={inputFile}
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "Center",
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{ opacity: 0.6 }}
          >
            <svg
              width="69"
              height="69"
              viewBox="0 0 69 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.8334 14.6667H51.5C53.0648 14.6667 54.3334 15.9352 54.3334 17.5V23.1667C54.3334 24.7315 53.0648 26 51.5 26H45.8334C44.2686 26 43 24.7315 43 23.1667V17.5C43 15.9352 44.2686 14.6667 45.8334 14.6667ZM45.8334 17.5V23.1667H51.5V17.5H45.8334Z"
                fill="#2B3674"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.4167 9H52.9167C56.8287 9 60 12.1713 60 16.0833V41.5833C60 45.4953 56.8287 48.6667 52.9167 48.6667H52.6803C51.8299 50.7007 50.0672 52.256 47.8619 52.8073L24.834 58.5641C20.9414 59.4636 17.1229 57.1103 16.2388 53.279L10.2876 27.4906C9.41924 23.7277 11.7251 19.9627 15.4716 19.026L20.3334 17.8106V16.0833C20.3334 12.1713 23.5047 9 27.4167 9ZM27.4167 48.6667H49.4012C48.8371 49.3399 48.0664 49.8357 47.1747 50.0586L24.1715 55.8094C21.8209 56.3523 19.53 54.9405 18.9996 52.6419L13.0484 26.8535C12.5274 24.5958 13.9109 22.3368 16.1588 21.7748L20.3334 20.7311V41.5833C20.3334 45.4953 23.5047 48.6667 27.4167 48.6667ZM57.1667 38.1632V16.0833C57.1667 13.7361 55.2639 11.8333 52.9167 11.8333H27.4167C25.0695 11.8333 23.1667 13.7361 23.1667 16.0833V32.4965L29.2483 26.4149C29.8016 25.8617 30.6985 25.8617 31.2518 26.4149L41.7639 36.927L49.2976 31.9046C49.8594 31.53 50.6076 31.6041 51.0851 32.0816L57.1667 38.1632ZM49.9029 34.9063L57.1313 42.1347C56.861 44.2214 55.0771 45.8333 52.9167 45.8333H27.4167C25.0695 45.8333 23.1667 43.9305 23.1667 41.5833V36.5034L30.25 29.4201L40.5816 39.7517C41.0592 40.2292 41.8073 40.3033 42.3692 39.9287L49.9029 34.9063Z"
                fill="#2B3674"
              />
            </svg>
          </IconButton>
          <Typography
            variant="body4"
            sx={{ fontWeight: 700, mt: 2, opacity: 0.6 }}
          >
            {ReactHtmlParser(t("upload.image1"))}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "primary.main", fontWeight: 700, mt: 2, opacity: 0.6 }}
          >
            {ReactHtmlParser(t("upload.image2"))}
          </Typography>
          <Button
            variant="outlined"
            className="small-btn outlined-btn"
            onClick={(event) => {
              inputFile.current.value = "";
              inputFile.current.type = "text";
              inputFile.current.type = "file";
              inputFile.current.click();
            }}
            sx={{
              mt: 2,
              boxShadow: "0px 0px 16px 0px #0000001A",
            }}
            startIcon={
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 3.5H15.5V1C15.5 0.723858 15.2761 0.5 15 0.5C14.7239 0.5 14.5 0.723858 14.5 1V3.5H12C11.7239 3.5 11.5 3.72386 11.5 4C11.5 4.27614 11.7239 4.5 12 4.5H14.5V7C14.5 7.27614 14.7239 7.5 15 7.5C15.2761 7.5 15.5 7.27614 15.5 7V4.5H18C18.2761 4.5 18.5 4.27614 18.5 4C18.5 3.72386 18.2761 3.5 18 3.5ZM9.5 4C9.5 3.72386 9.27614 3.5 9 3.5H1C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5H9C9.27614 4.5 9.5 4.27614 9.5 4ZM12 8.5C12.2761 8.5 12.5 8.72386 12.5 9C12.5 9.27614 12.2761 9.5 12 9.5H1C0.723858 9.5 0.5 9.27614 0.5 9C0.5 8.72386 0.723858 8.5 1 8.5H12ZM18.5 14C18.5 13.7239 18.2761 13.5 18 13.5H1C0.723858 13.5 0.5 13.7239 0.5 14C0.5 14.2761 0.723858 14.5 1 14.5H18C18.2761 14.5 18.5 14.2761 18.5 14Z"
                  fill="#2B3674"
                />
              </svg>
            }
          >
            {ReactHtmlParser(t("common.browse"))}
          </Button>
        </Box>
      </label>
    </Paper>
  );
}

export default AddPictureToAsset;
