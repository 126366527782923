
export default function authHeader() {
  let token = localStorage.getItem('token');
  //let token = window.token;
  console.log(token)
  if (token != '') {
    return { Authorization: 'Bearer ' + window.localStorage.getItem('token') };
	//return { Authorization: 'Bearer ' + window.token };	
  } else {
    return {};
  }
}

export function fetchWithAuthentication(url, authToken) {
  const headers = new Headers();
  headers.set('Authorization', 'Bearer ' + window.localStorage.getItem('token'));
  //headers.set('Authorization', 'Bearer ' + window.token );	
  return fetch(url, { headers });
}