import DirectionsCarIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import CandlestickIcon from "@mui/icons-material/CandlestickChartOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShareIcon from "../../Components/Commons/Icons/ShareIcon";
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceOutlined";
import BitcoinIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import HeartIcon from "../../Components/Commons/Icons/HeartIcon";
import CreditIcon from "../../Components/Commons/Icons/CreditIcon";
import OtherAssetIcon from "../../Components/Commons/Icons/OtherAssetIcon";
import { parseBoolean } from "../helper";
import exchangeVal from "../../Components/Commons/CurrencyExchange";

const initialState = {
  items: [],
  cards: [],
  sums: {},
  categoryVisibility:
    localStorage.getItem("assetCategoryVisibility") !== null
      ? parseBoolean(localStorage.getItem("assetCategoryVisibility"))
      : true,
  loading: false,
  error: null,
  assetTypes: [
    {
      type: "real-estate",
      typeCamel: "realEstate",
      labelCode: "real-estate",
      icon: HomeWorkIcon,
      displayHome: true,
    },
    {
      type: "bank-account",
      typeCamel: "bankAccount",
      labelCode: "bank-account",
      icon: CreditCardIcon,
      displayHome: true,
    },
    {
      type: "vehicle",
      typeCamel: "vehicle",
      labelCode: "vehicle",
      icon: DirectionsCarIcon,
      displayHome: true,
    },
    {
      type: "insurance",
      typeCamel: "insurance",
      labelCode: "insurance",
      icon: HeartIcon,
      displayHome: true,
    },
    {
      type: "security",
      typeCamel: "security",
      labelCode: "security",
      icon: CandlestickIcon,
      displayHome: true,
    },
    {
      type: "credit",
      typeCamel: "credit",
      labelCode: "credit",
      icon: CreditIcon,
      displayHome: true,
    },
    {
      type: "share",
      typeCamel: "share",
      labelCode: "share",
      icon: ShareIcon,
      displayHome: true,
    },
    {
      type: "claim",
      typeCamel: "claim",
      labelCode: "claim",
      icon: AccountBalanceIcon,
      displayHome: true,
    },
    {
      type: "housing-savings",
      typeCamel: "housingSavings",
      labelCode: "housing-savings",
      icon: AccountBalanceIcon,
      displayHome: false,
    },
    {
      type: "crypto",
      typeCamel: "insurance",
      labelCode: "crypto",
      icon: BitcoinIcon,
      displayHome: true,
    },
    {
      type: "other",
      typeCamel: "insurance",
      labelCode: "other",
      icon: OtherAssetIcon,
      displayHome: true,
    },
  ],
};

const getTypeOrder = () => {
  const order = {};
  initialState.assetTypes.forEach((asset, index) => {
    order[asset.typeCamel] = index;
  });
  return order;
};

const typeOrder = getTypeOrder();

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ASSETS_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_ASSETS_SUCCESS":
      const sums = action.payload.assets.reduce((acc, asset) => {
        const { assetType, value, currency } = asset;
        if (value && currency) {
          let val;
          const bCurrency = localStorage.getItem("baseCurrency");
          if (currency.toLowerCase() !== bCurrency.toLowerCase()) {
            val = !isNaN(value) && value !== undefined ? value : 0;

            val = exchangeVal(
              value,
              currency ? currency.toLowerCase() : "huf",
              "usd"
            );

            val = Math.ceil(
              exchangeVal(val, "usd", bCurrency.toLowerCase()) * 1
            );
          } else {
            val = !isNaN(value) && value !== undefined ? value : 0;
          }


          if (!acc[assetType]) {
            acc[assetType] = 0;
          }
          if (assetType === "credit") {
            val = val > 0 ? val * -1 : val;
          }
          acc[assetType] += parseFloat(val);

          if (!acc.total) {
            acc.total = 0;
          }
          acc.total += parseFloat(val);
        }
        return acc;
      }, {});

      return {
        ...state,
        loading: false,
        items: action.payload.assets,
        sums,
      };
    case "FETCH_ASSETS_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "PREPARE_ASSET_CARDS":
      const sortedCards = action.payload.sort(
        (a, b) => typeOrder[a.type] - typeOrder[b.type]
      );
      return { ...state, cards: sortedCards };
    case "SET_HOMEPAGE_CATEGORY_VISIBILITY":
      console.log("SET_HOMEPAGE_CATEGORY_VISIBILITY", action.payload);
      if (typeof action.payload === "undefined") {
        return { ...state };
      }
      localStorage.setItem("assetCategoryVisibility", action.payload);
      return { ...state, categoryVisibility: action.payload };
    default:
      return state;
  }
};

export default assetReducer;
