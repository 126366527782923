import { Box, Grid } from "@mui/material";
import { Footer } from "../Footer/Footer";
import { ProfileTopMenu } from "../Navigation/LoginNavigation/ProfileTopMenu";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const AuthLayout = ({
  children,
  backgroundImage,
  globalEvent,
  setGlobalEvent,
  title,
}) => {
  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia("(max-width: 900px)");
      setMobileView(mql.matches);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Box
        sx={{
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ProfileTopMenu />
              {children}
            </Grid>
            {!mobileView ? (
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={backgroundImage}
                  sx={{
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </Box>
      {mobileView ? <Footer /> : ""}
    </>
  );
};

export default AuthLayout;
