import React, {  useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Stack,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  InputBase,
} from "@mui/material";
import { Footer } from "../Footer/Footer";
import { MainMenu } from "../Navigation/MainMenu";
import { MainMobileMenu } from "../Navigation/MainMobileMenu";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';
import { DesktopTopMenu } from "../Navigation/HomeNavigation/DesktopTopMenu";
import { MobileTopMenu } from "../Navigation/HomeNavigation/MobileTopMenu";
import { checkHasToken, checkTokenIsValid } from "../DataModule/tokenFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/Actions/userAction";

const StaticPage = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageName } = useParams();

  const { t, i18n } = useTranslation();	

  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );
  const [hasToken, setHasToken] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  
  const dispatch = useDispatch();
  const { user, loading, loggedIn } = useSelector((state) => state.user);
  
  window.scrollTo(0, 0);

  useEffect(() => {
    const tokenExists = checkHasToken();

    if (tokenExists) {
      setHasToken(true);
      const isValid = checkTokenIsValid();
      setTokenIsValid(isValid);

      if (isValid) {
        dispatch(getCurrentUser());
      }
    }
  }, [dispatch, loggedIn]);
  
  return (
    <>
	
	
	{(hasToken && tokenIsValid) && (
          <>
	{!mobileView ? (
          <>
            <MainMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MainMobileMenu  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
        </Box>
	)}
		  </>
        )}

		{(hasToken === false || tokenIsValid === false) && (
		<>  
		{!mobileView ? (
        <>
          <DesktopTopMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} outer={true}/>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MobileTopMenu  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} outer={true} />
        </Box>
		)}
		  </>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: { xl: "center" },
          }}
        >
          <Grid
            container
            sx={{
              mt: { xs: 0, sm: 8 },
              pl: { xs: 2, md: 27 },
              pr: { xs: 2, md: 17 },
              mb: 20,
              width: { xl: "1530px" },
              minHeight: "100vh",
              textAlign: "left",
            }}
          >

				{ReactHtmlParser(t("link."+pageName))}				

          </Grid>

        </Box>
		<Footer />

    </>
  );
};

export default StaticPage;
