import { Typography, Stack, Button, Avatar, Box } from "@mui/material";

const UserCard = ({
  name,
  date,
  editButtonText,
  onEdit,
  actionButtonText,
  onAction,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        borderTop: "1px solid #EBEBEB",
        py: 2,
      }}
    >
      <Avatar sx={{ width: 46, height: 46 }}></Avatar>
      <Stack direction="column" spacing={0} alignItems="left">
        <Typography
          variant="caption"
          component="div"
          sx={{
            color: "#2B3674",
            fontSize: "12px",
            fontWeight: "700",
            lineHeight: "19px",
          }}
        >
          {name}
        </Typography>
        {date && (
          <Typography
            variant="small"
            sx={{
              fontSize: "10px",
              fontWeight: 400,
              color: "rgba(43, 54, 116, 0.6)",
            }}
            component="div"
            color="text.secondary"
          >
            {date}
          </Typography>
        )}
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      {onEdit && editButtonText && (
        <Button
          className="secondary-blue-text-btn"
          size="small"
          onClick={onEdit}
          sx={{
            color: "#1542B9",
            fontSize: "12px",
            fontWeight: 700,
          }}
        >
          {editButtonText}
        </Button>
      )}
      {onAction && actionButtonText && (
        <Button
          size="contained"
          onClick={onAction}
          sx={{
            borderRadius: "64px",
            width: "118px",
            py: 0.5,
            px: 1,
            bgcolor: "#62C275",
            color: "#fff",
            fontSize: "16px",
            fontWeight: 500,
            "&:hover": {
              bgcolor: "#6FD884",
              color: "#fff",
            },
            "&:focus": {
              bgcolor: "#78EF90",
              color: "#fff",
            },
          }}
        >
          {actionButtonText}
        </Button>
      )}
    </Stack>
  );
};

export default UserCard;
