import { useCallback, useState, useRef, useEffect } from "react";
import { Box, Paper, Typography, IconButton, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { handleFileUpload, resetFileState } from "../../store/Actions/fileActions";
import { t } from "i18next";
import DisplayUploadedFiles from "./DisplayUploadedFiles";

const FileUploader = ({ icon: Icon, uploadText, fileType, onFileUploaded, fileCount, globalEvent, setGlobalEvent }) => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0]);
      }
    },
    [dispatch]
  );

  const onFileUpload = useCallback((file) => {
    dispatch(handleFileUpload(file, fileType, onFileUploaded));
  });

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files.length > 0) {
        Array.from(event.target.files).forEach((file) => {
          onFileUpload(file);
          event.target.value = null; 
        });
      }	  
    },
    [onFileUpload]
  );

  return (
    <Box>
      <Paper
        variant="outlined"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          mt: 2,
        }}
        style={{
          border: dragOver ? "2px dashed #2B36741A" : "2px dashed #2B36741A",
          borderRadius: "16px",
          padding: 32,
          textAlign: "center",
          cursor: "pointer",
          width: "fill 399px",
          background: dragOver ? "#eee" : "#FAFCFC",
        }}
      >
        <input
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleChange}
        />
        <label htmlFor="raised-button-file">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "Center",
            }}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Icon style={{ fontSize: 65 }} />
            </IconButton>
            <Typography variant="body4" sx={{ fontWeight: 700, mt: 2 }}>
              {uploadText}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: "primary.main", fontWeight: 700, mt: 2 }}
            >
              {t("fileUploader.uploadText2")}
            </Typography>
            <Button
              variant="outlined"
              className="small-btn outlined-btn"
              onClick={(event) => {       
				inputFile.current.click(event);
              }}
              sx={{
                mt: 2,
                boxShadow: "0px 0px 16px 0px #0000001A",
              }}
              startIcon={
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 3.5H15.5V1C15.5 0.723858 15.2761 0.5 15 0.5C14.7239 0.5 14.5 0.723858 14.5 1V3.5H12C11.7239 3.5 11.5 3.72386 11.5 4C11.5 4.27614 11.7239 4.5 12 4.5H14.5V7C14.5 7.27614 14.7239 7.5 15 7.5C15.2761 7.5 15.5 7.27614 15.5 7V4.5H18C18.2761 4.5 18.5 4.27614 18.5 4C18.5 3.72386 18.2761 3.5 18 3.5ZM9.5 4C9.5 3.72386 9.27614 3.5 9 3.5H1C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5H9C9.27614 4.5 9.5 4.27614 9.5 4ZM12 8.5C12.2761 8.5 12.5 8.72386 12.5 9C12.5 9.27614 12.2761 9.5 12 9.5H1C0.723858 9.5 0.5 9.27614 0.5 9C0.5 8.72386 0.723858 8.5 1 8.5H12ZM18.5 14C18.5 13.7239 18.2761 13.5 18 13.5H1C0.723858 13.5 0.5 13.7239 0.5 14C0.5 14.2761 0.723858 14.5 1 14.5H18C18.2761 14.5 18.5 14.2761 18.5 14Z"
                    fill="#2B3674"
                  />
                </svg>
              }
            >
              {t("fileUploader.browseBtn")}
            </Button>
          </Box>
        </label>
      </Paper>

      <DisplayUploadedFiles editMode={true} />
    </Box>
  );
};

export default FileUploader;
