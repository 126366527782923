const DotIcon = ({color = 'white'}) => {
    return (
        <svg
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-0.00129818 3.72967C-0.00129818 2.00509 1.46938 0.602356 3.27686 0.602356C5.08434 0.602356 6.55501 2.00534 6.55501 3.72967C6.55501 5.45403 5.08464 6.85726 3.27686 6.85726C1.46938 6.85726 -0.00129818 5.45428 -0.00129818 3.72967Z"
                fill={color}
              />
            </svg>
    );
};

export default DotIcon;