import { fetchWithAuth } from "../api";

export const fetchPostMortemEntries = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PM_ENTRIES_REQUEST" });
    try {
      const data = await fetchWithAuth("post-mortem-requests");
      dispatch({
        type: "FETCH_PM_ENTRIES_SUCCESS",
        payload: data.postMortemRequests,
      });
    } catch (error) {
      dispatch({ type: "FETCH_PM_ENTRIES_FAILURE", payload: [] });
    }
  };
};

export const setEntryTitle = (title) => {
  return {
    type: "SET_PM_ENTRY_TITLE",
    payload: title,
  };
};

export const setEntryContent = (content) => {
  return {
    type: "SET_PM_ENTRY_CONTENT",
    payload: content,
  };
};

export const resetEntry = () => {
  return {
    type: "RESET_PM_ENTRY",
  };
};

export const saveEntry = (entryId, entryData, navigate) => {
  return async (dispatch) => {
    try {
      await fetchWithAuth(
        entryId ? "post-mortem-requests/" + entryId : "post-mortem-requests",
        {
          method: entryId ? "PATCH" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            postMortemRequest: entryData,
          }),
        },
        navigate
      );
      dispatch({ type: "SAVE_PM_ENTRY_SUCCESS" });
      dispatch(fetchPostMortemEntries());
    } catch (error) {
      dispatch({ type: "SAVE_PM_ENTRY_FAILURE", payload: error });
    }
  };
};

export const deleteEntry = (entryId) => {
  return async(dispatch) => {
    try {
      await fetchWithAuth("post-mortem-requests/" + entryId, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" }
      });

      dispatch({ type: "DELETE_PM_ENTRY", payload: entryId });
    } catch(error) {
      console.log('errror', error);
    }
  }
}
