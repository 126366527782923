import React, {
  useRef,
  props,
  useEffect,
  useReducer,
  useState,
  Suspense,
} from "react";
import { Footer } from "../Footer/Footer";

import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MainMenu } from "../Navigation/MainMenu";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import {
  Typography,
  Grid,
  Stack,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material";

import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

import ShowFileOfAsset from "../FileHandlers/ShowFileOfAsset";
import AddFileToAsset from "../FileHandlers/AddFileToAsset";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import DefaultLayout from "../Layout/DefaultLayout";

import currencyJSON from "../Assets/Data/Common-Currency.json";
import currencyMinJSON from "../Assets/Data/Common-Currency-min.json";

import AssetHeader from "../Commons/AssetHeader";
import AssetHeaderBottom from "../Commons/AssetHeaderBottom";

import DialogPrompt from "../PopupHandler/DialogPrompt";

const EditInsurance = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [chid, setChid] = useState(localStorage.chid);

  const [accountCurrencyMore, setAccountCurrencyMore] = useState(false);
  const [openAccountCurrency, setOpenAccountCurrency] = useState(false);

  const formatValue = (price) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice.replaceAll(" ", "  ");
  };

  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    doSaveAsset(e, collectData(data, e));
    console.log(data, e);
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const [id, setID] = useState(null);
  const [draft, setDraft] = useState(true);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [insName, setInsName] = useState("");
  const [insNumber, setInsNumber] = useState("");

  const [hasBeneficiary, setHasBeneficiary] = useState("");
  const [benName, setBenName] = useState("");
  const [benAddress, setBenAddress] = useState("");
  const [benBirthPlace, setBenBirthPlace] = useState("");
  const [benBirthDate, setBenBirthDate] = useState("");

  const [value, setVal] = useState("");
  const [currency, setCurrency] = useState("");
  const [description, setDescription] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [attachmentIds, setAttachmentIds] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const [showPersonalData, setShowPersonalData] = useState(false);
  const handleShowPersonalData = () => {
    setShowPersonalData(!showPersonalData);
  };

  const formRef = useRef();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    if (isValid) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [isValid, category]);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [showAdditionalBeneficiary, setShowAdditionalBeneficiary] =
    useState(false);

  const setBeneficiary = (benIndex, benName) => {
    if (!beneficiaries[benIndex]) {
      setBeneficiaries((oldArray) => [...oldArray, { name: benName }]);
    } else {
      const nextBeneficiaries = [...beneficiaries];
      nextBeneficiaries[benIndex] = { name: benName };
      setBeneficiaries(nextBeneficiaries);
    }
  };

  const deleteBeneficiary = (benIndex) => {
    const nextBeneficiaries = [...beneficiaries];
    nextBeneficiaries.splice(benIndex, 1);
    //console.log(nextBeneficiaries);
    setBeneficiaries([...nextBeneficiaries]);
  };

  const [extraCategoryOpen, setExtraCategoryOpen] = useState(false);
  const [extraCategory, setExtraCategory] = useState("");

  const [extraFields, setExtraFields] = useState([]);
  const setExtraField = (extraFieldIndex, extraFieldName, extraFieldValue) => {
    //console.log(extraFields)
    if (!extraFields[extraFieldIndex]) {
      setExtraFields((oldArray) => [
        ...oldArray,
        { name: extraFieldName, value: extraFieldValue },
      ]);
    } else {
      const nextExtraFields = [...extraFields];
      nextExtraFields[extraFieldIndex] = {
        name: extraFieldName,
        value: extraFieldValue,
      };
      setExtraFields(nextExtraFields);
    }
  };

  const deleteExtraField = (extraFieldIndex) => {
    const nextExtraFields = [...extraFields];
    nextExtraFields.splice(extraFieldIndex, 1);
    setExtraFields((oldArray) => [...nextExtraFields]);
  };

  const doSaveAsset = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doSaveAsset", data: data });
  };

  const onFileUpload = (data) => {
	setGlobalEvent({
      type: "checkPlan",
      data: { type: "document", subtype: "insurance", file: data, fileCount: attachmentFiles.length  },
    });
	
	//setGlobalEvent({
    //  type: "doFileUpload",
    //  data: { file: data, uploadType: "document" },
    //});
  };

  const collectData = (data, e) => {
    let collectedData = {};
    collectedData["asset"] = {};

    if (id * 1 > 0) {
      collectedData["asset"]["id"] = id;
    }

    collectedData["asset"]["assetType"] = "insurance";
    collectedData["asset"]["value"] = data.value
      ? data.value.replace(/\D/g, "") * 1
      : 0;
    collectedData["asset"]["currency"] = data.currency;
    //collectedData['asset']['draft'] = draft;

    collectedData["asset"]["details"] = {};
    collectedData["asset"]["details"]["name"] = data.name;
    collectedData["asset"]["details"]["insName"] = data.insName;
    collectedData["asset"]["details"]["insNumber"] = data.insNumber;

    collectedData["asset"]["details"]["beneficiaries"] = beneficiaries;
    collectedData["asset"]["details"]["hasBeneficiary"] = hasBeneficiary;
    collectedData["asset"]["details"]["benName"] = data.benName;
    collectedData["asset"]["details"]["benAddress"] = data.benAddress;
    collectedData["asset"]["details"]["benBirthPlace"] = data.benBirthPlace;
    collectedData["asset"]["details"]["benBirthDate"] = data.benBirthDate;

    collectedData["asset"]["details"]["category"] = category;
    collectedData["asset"]["details"]["description"] = description;

    collectedData["asset"]["details"]["extraFields"] = extraFields;

    collectedData["attachmentIds"] = attachmentIds;

    return collectedData;
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editMode]);

  const setData = (data, e) => {
    setID(data?.id ? data?.id : null);
    setEditMode(false);
    setVal(data?.value ? formatValue(data?.value * 1) : 0);
    setCurrency(data?.currency ? data?.currency : "HUF");

    setName(data?.details?.name ? data?.details?.name : "");
    setCategory(data?.details?.category ? data?.details?.category : "");

    if (data?.details?.category) {
      if (
        data?.details?.category != "életbiztosítás" &&
        data?.details?.category != "balesetbiztosítás" &&
        data?.details?.category != "lakásbiztosítás"
      ) {
        setExtraCategory(data?.details?.category);
      }
    }

    setInsName(data?.details?.insName ? data?.details?.insName : "");
    setInsNumber(data?.details?.insNumber ? data?.details?.insNumber : "");

    setBeneficiaries(
      data?.details?.beneficiaries ? data?.details?.beneficiaries : []
    );

    setHasBeneficiary(
      data?.details?.hasBeneficiary ? data?.details?.hasBeneficiary : false
    );
    setBenName(data?.details?.benName ? data?.details?.benName : "");
    setBenAddress(data?.details?.benAddress ? data?.details?.benAddress : "");
    setBenBirthPlace(
      data?.details?.benBirthPlace ? data?.details?.benBirthPlace : ""
    );
    setBenBirthDate(
      data?.details?.benBirthDate ? data?.details?.benBirthDate : ""
    );

    setDescription(
      data?.details?.description ? data?.details?.description : ""
    );
    setExtraFields(
      data?.details?.extraFields ? data?.details?.extraFields : []
    );
  };

  useEffect(() => {
    console.log(state);
    setData(state?.data);
    setEditMode(state?.editmode ? state?.editmode : false);
    if (state?.data?.id) {
      setGlobalEvent({
        type: "doGetAttachments",
        data: { id: state?.data?.id },
      });
    }
  }, [state]);

  useEffect(() => {
    setValue("name", name);
  }, [name]);

  useEffect(() => {
    setValue("insName", insName);
  }, [insName]);

  useEffect(() => {
    setValue("insNumber", insNumber);
  }, [insNumber]);

  useEffect(() => {
    setValue("benName", benName);
  }, [benName]);

  useEffect(() => {
    setValue("benAddress", benAddress);
  }, [benAddress]);

  useEffect(() => {
    setValue("benBirthPlace", benBirthPlace);
  }, [benBirthPlace]);

  useEffect(() => {
    setValue("benBirthDate", benBirthDate);
  }, [benBirthDate]);

  useEffect(() => {
    setValue("value", value);
  }, [value]);

  useEffect(() => {
    setValue("currency", currency);
  }, [currency]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ EditInsurance");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseCheckPlan") {
    
	if (globalEvent?.data.subtype  == "insurance"){
	
	setGlobalEvent({
      type: "doFileUpload",
      data: { file: globalEvent?.data.file, uploadType: globalEvent?.data.type },
    });
	
	}
	
	}

    if (globalEvent?.type == "responseFileUpload") {
      let aryFiles = [];

      setAttachmentIds([...attachmentIds, globalEvent?.data?.attachment?.id]);

      aryFiles.push(globalEvent?.data.attachment);

      setAttachmentFiles([...attachmentFiles, ...aryFiles]);

      let collectedData = {};
      collectedData["asset"] = {};
      if (id) {
        collectedData["asset"]["id"] = id;
      }
      collectedData["asset"]["assetType"] = "insurance";
      collectedData["attachmentIds"] = [
        ...attachmentIds,
        globalEvent?.data?.attachment?.id,
      ];

      setGlobalEvent({ type: "doSaveAssetImage", data: collectedData });
    }

    if (globalEvent?.type == "responseSaveAssetImage") {
      setAttachmentIds([]);
	  if (globalEvent?.data?.asset.id) {
        setID(globalEvent?.data?.asset.id ? globalEvent?.data?.asset.id : null);
      }
    }

    if (globalEvent?.type == "responseDeleteAttachment") {
      if (state?.data?.id) {
        setGlobalEvent({
          type: "doGetAttachments",
          data: { id: state?.data?.id },
        });
      }
    }

    if (globalEvent?.type == "responseGetAttachments") {
      console.log(globalEvent?.data.attachments);
      let aryFiles = [];

      const length = Array.isArray(globalEvent?.data.attachments)
        ? globalEvent?.data.attachments.length
        : 0;
      for (let i = 0; i < length; i++) {
        aryFiles.push(globalEvent?.data.attachments[i]);
      }

      setAttachmentFiles([...aryFiles]);
    }
  }, [globalEvent]);

  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        {editMode !== true && (
          <Box
            className="my-container"
            sx={{
              mt: { xs: 0, sm: 8 },
              mb: 20,
              minHeight: "100vh",
              textAlign: "left",
            }}
          >
            <Grid container>
              <Grid item xs={12}>

              <AssetHeader
                chid={chid}
                id={id}
                setEditMode={setEditMode}
                setGlobalEvent={setGlobalEvent}
                titleKey={'asset.insuranceData'}  />

              </Grid>
              <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                <Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {name}
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" }, my: 2 }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.insuranceDescription"))}
                    </Typography>
                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 2 }}
                    >
                      {description}
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  className="add-asset-form-card"
                  sx={{ px: 2, py: 1, width: { xs: "auto", md: "100%" } }}
                >
                  <CardContent
                    sx={{
                      "&.MuiCardContent-root": {
                        py: 1,
                      },
                    }}
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={{ xs: "center", sm: "space-between" }}
                      alignItems={{ xs: "space-between", sm: "center" }}
                      sx={{ mb: 0 }}
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 700,
                            mb: 0,
                            color: "primary.light",
                          }}
                        >
                          {ReactHtmlParser(t("asset.actualBalance"))}:
                        </Typography>
                        <Typography
                          variant="body4"
                          sx={{ color: "primary.light" }}
                        >
                          {ReactHtmlParser(t("common.refreshed"))}: 2023.01.12.
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700 }}
                      >{`${formatValue(value)} ${currency}`}</Typography>
                    </Stack>
                  </CardContent>
                </Card>
				
				<AssetHeaderBottom chid={chid}
                id={id}
                setEditMode={setEditMode}
                setGlobalEvent={setGlobalEvent}
                titleKey={'asset.bankAccountData'}  />
				
              </Grid>
              <Grid item xs={12} md={5} sx={{ pl: { xs: 0, md: 10 } }}>
                <Box>
                  <Box sx={{ mb: 6, mt: { md: 0, xs: 6 } }}>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.insurance.documentsTitle"))}{" "}
                      {attachmentFiles.length > 0 && (
                        <>({attachmentFiles.length})</>
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "primary.main" }}
                    ></Typography>
                  </Box>
                  <ShowFileOfAsset
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                    itemList={attachmentFiles}
                    editMode={editMode}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/***** ******/}

        {editMode !== false && (
          <>
            <Box
              ref={formRef}
              component="form"
              className="my-container"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <Grid
                sx={{
                  mt: { xs: 0, sm: 8 },
                  mb: 20,
                  minHeight: "100vh",
                  textAlign: "left",
                }}
                container
              >
                <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 6, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
                  >
                    {ReactHtmlParser(t("asset.insuranceNew"))}
                  </Typography>
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
                  >
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, mb: 4 }}
                      >
                        {ReactHtmlParser(t("asset.insurance.insuranceData"))}
                      </Typography>
                      <Box
                        sx={{
                          mt: 4,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 700, mb: 4 }}
                        >
                          {ReactHtmlParser(t("asset.insurance.category"))}
                        </Typography>
                        <Stack
                          direction={{ xs: "column", sm: "row", mt: 4, mb: 4 }}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Button
                            className={
                              "asset-categories-btn " +
                              (category == "életbiztosítás"
                                ? "asset-categories-selected-btn"
                                : "")
                            }
                            variant="contained"
                            onClick={(event) => {
                              setCategory("életbiztosítás");
                            }}
                          >
                            {ReactHtmlParser(t("asset.insurance.catLife"))}
                          </Button>
                          <Button
                            className={
                              "asset-categories-btn " +
                              (category == "balesetbiztosítás"
                                ? "asset-categories-selected-btn"
                                : "")
                            }
                            variant="contained"
                            onClick={(event) => {
                              setCategory("balesetbiztosítás");
                            }}
                          >
                            {ReactHtmlParser(t("asset.insurance.catAccident"))}
                          </Button>
                          <Button
                            className={
                              "asset-categories-btn " +
                              (category == "lakásbiztosítás"
                                ? "asset-categories-selected-btn"
                                : "")
                            }
                            variant="contained"
                            onClick={(event) => {
                              setCategory("lakásbiztosítás");
                            }}
                          >
                            {ReactHtmlParser(t("asset.insurance.catHome"))}
                          </Button>

                          <Button
                            className={
                              "asset-categories-btn " +
                              (category == "egyéb"
                                ? "asset-categories-selected-btn"
                                : "")
                            }
                            variant="contained"
                            onClick={(event) => {
                              setCategory("egyéb");
                            }}
                          >
                            {ReactHtmlParser(t("asset.insurance.catOther"))}
                          </Button>
                        </Stack>

                        <Stack spacing={2} sx={{ mb: 4, mt: 4 }}>
                          <div>
                            <TextField
                              key="name"
                              className="asset-field-fullwidth"
                              label={ReactHtmlParser(t("field.name"))}
                              type="text"
                              required={true}
                              {...register("name", {
                                required: t("errors.field.required"),
                                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.name && (
                              <span role="alert">{errors.name.message}</span>
                            )}
                          </div>
                        </Stack>
                        <Stack spacing={2} sx={{ mb: 4 }}>
                          <div>
                            <TextField
                              key="insName"
                              className="asset-field-fullwidth"
                              label={ReactHtmlParser(t("field.insName"))}
                              type="text"
                              required={true}
                              {...register("insName", {
                                required: t("errors.field.required"),
                                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.insName && (
                              <span role="alert">{errors.insName.message}</span>
                            )}
                          </div>
                        </Stack>

                        <Stack spacing={2} sx={{ mb: 4, display: "none" }}>
                          <div>
                            <TextField
                              key="insNumber"
                              className="asset-field-fullwidth"
                              label={ReactHtmlParser(t("field.insNumber"))}
                              type="text"
                              required={false}
                              onChange={(event) => {
                                setInsNumber(event.target.value);
                              }}
                              {...register("insNumber", {
                                /*required: t("errors.field.required"),
                              ,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.insNumber && (
                              <span role="alert">{errors.make.insNumber}</span>
                            )}
                          </div>
                        </Stack>
                      </Box>

                      <Box>
                        <Grid
                          container
                          sx={{
                            borderRadius: "16px",
                            pt: 2,
                            pb: 2,
                            mt: 2,
                          }}
                        >
                          <Grid
                            item
                            xs={3}
                            md={1}
                            sx={{
                              display: "flex",
                              pr: 1,
                              pl: 2,
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body3"
                              sx={{ fontWeight: 700, }}
                            >
                              <Checkbox
							     
						  icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="7.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="7.5"
                      stroke="#A6ADBA"
                    />
                  </svg>
                }
                                checked={hasBeneficiary}
                                onChange={() => {
                                  setHasBeneficiary(!hasBeneficiary);
                                }}
                                value=""
                                name="radio-buttons"
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            md={11}
                            sx={{
                              display: "flex",
                              pr: 1,
                              pl: 2,
                              alignItems: "center",
                            }}
                          >
                            <Stack
                              direction={"row"}
                              spacing={2}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 700,
                                }}
                              >
                                {ReactHtmlParser(t("field.hasBeneficiary"))}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>

                        {hasBeneficiary && (
                          <div>
                            {beneficiaries.map((key, index) => (
                              <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                                sx={{ mt: 2 }}
                              >
                                <Stack
                                  direction={"column"}
                                  spacing={1}
                                  alignItems={"left"}
                                >
                                  <Typography
                                    variant="body4"
                                    sx={{ color: "#000000", fontWeight: 700 }}
                                  >
                                    {beneficiaries[index]?.name}
                                  </Typography>
                                </Stack>

                                <Button
                                  variant="text"
                                  className="secondary-blue-text-btn"
                                  onClick={() => {
                                    /*console.log(beneficiaries); console.log(index); console.log(key); */ deleteBeneficiary(
                                      index
                                    );
                                  }}
                                >
                                  {ReactHtmlParser(t("common.delete"))}
                                </Button>
                              </Stack>
                            ))}

                            {showAdditionalBeneficiary ? (
                              <>
                                <Stack
                                  spacing={2}
                                  direction={{ xs: "column", sm: "row" }}
                                  sx={{ mt: 2 }}
                                >
                                  <TextField
                                    className="asset-field-small"
                                    label={ReactHtmlParser(t("field.name"))}
                                    type="text"
                                    onChange={(event) => {}}
                                  />
                                  <Button
                                    className="secondary-blue-text-btn"
                                    onClick={(ev) => {
                                      let bnels =
                                        ev.target.previousElementSibling.getElementsByTagName(
                                          "input"
                                        );
                                      setBeneficiary(
                                        -1,
                                        bnels[0] ? bnels[0].value : ""
                                      );
                                      setShowAdditionalBeneficiary(false);
                                    }}
                                  >
                                    {ReactHtmlParser(t("common.add"))}
                                  </Button>
                                  <Button
                                    variant="text"
                                    className="secondary-blue-text-btn"
                                    onClick={() => {
                                      setShowAdditionalBeneficiary(false);
                                    }}
                                  >
                                    {ReactHtmlParser(t("common.cancel"))}
                                  </Button>
                                </Stack>
                              </>
                            ) : (
                              <Button
                                variant="text"
                                sx={{ color: "primary.light" }}
                                onClick={() => {
                                  setShowAdditionalBeneficiary(true);
                                }}
                              >
                                {ReactHtmlParser(
                                  t("asset.insurance.addBeneficiary")
                                )}
                              </Button>
                            )}

                            <Stack
                              spacing={2}
                              sx={{ mb: 4, mt: 4, display: "none" }}
                            >
                              <div>
                                <TextField
                                  key="benName"
                                  className="asset-field-fullwidth"
                                  label={ReactHtmlParser(t("field.benName"))}
                                  type="text"
                                  //required={true}
                                  onChange={(event) => {
                                    setBenName(event.target.value);
                                  }}
                                  {...register("benName", {
                                    /*required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                  })}
                                />

                                {errors.benName && (
                                  <span role="alert">
                                    {errors.benName.message}
                                  </span>
                                )}
                              </div>

                              <div>
                                <TextField
                                  key="benAddress"
                                  className="asset-field-fullwidth"
                                  label={ReactHtmlParser(t("field.benAddress"))}
                                  type="text"
                                  //required={true}
                                  onChange={(event) => {
                                    setBenAddress(event.target.value);
                                  }}
                                  {...register("benAddress", {
                                    /*required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                  })}
                                />

                                {errors.benAddress && (
                                  <span role="alert">
                                    {errors.benAddress.message}
                                  </span>
                                )}
                              </div>
                            </Stack>

                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              spacing={{ xs: 2, sm: 1 }}
                              sx={{ display: "none" }}
                            >
                              <div>
                                <TextField
                                  key="benBirthDate"
                                  className="asset-field-small"
                                  label={ReactHtmlParser(
                                    t("field.benBirthDate")
                                  )}
                                  type="text"
                                  //required={true}
                                  onChange={(event) => {
                                    setBenBirthDate(event.target.value);
                                  }}
                                  {...register("benBirthDate", {
                                    /*required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                  })}
                                />

                                {errors.benBirthDate && (
                                  <span role="alert">
                                    {errors.benBirthDate.message}
                                  </span>
                                )}
                              </div>

                              <div>
                                <TextField
                                  key="benBirthPlace"
                                  className="asset-field-small"
                                  label={ReactHtmlParser(
                                    t("field.benBirthPlace")
                                  )}
                                  type="text"
                                  //required={true}
                                  onChange={(event) => {
                                    setBenBirthPlace(event.target.value);
                                  }}
                                  {...register("benBirthPlace", {
                                    /*required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                  })}
                                />

                                {errors.benBirthPlace && (
                                  <span role="alert">
                                    {errors.benBirthPlace.message}
                                  </span>
                                )}
                              </div>
                            </Stack>
                          </div>
                        )}
                      </Box>

                      <Box
                        sx={{
                          my: 4,
                          borderTop: "2px solid #EBEBEB",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={1}
                          sx={{ my: 4 }}
                          alignItems={"center"}
                        >
                          <Typography variant="body2" sx={{ fontWeight: 700 }}>
                            {ReactHtmlParser(t("field.description"))}
                          </Typography>
                          <Typography variant="caption">
                            ({ReactHtmlParser(t("common.optional"))})
                          </Typography>
                        </Stack>
                        <TextareaAutosize
                          key="description"
                          placeholder={ReactHtmlParser(
                            t("asset.insurance.descriptionPlaceholder")
                          )}
                          {...register("description", {
                            /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                          })}
                          value={description}
                          onChange={(event) => {
                            setDescription(event.target.value);
                          }}
                          style={{
                            font: "inherit",
                            width: "100%",
                            height: "133px",
                            resize: "none",
                            border: "1px solid #C6C6C6",
                            borderRadius: "24px",
                            marginLeft: "-10px",
                            paddingTop: "16px",
                            paddingLeft: "16px",
                            paddingRight: "5px",
                            paddingBottom: "16px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          my: 4,display:'none'
                        }}
                      >
                        <Stack
                          onClick={handleShow}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ mb: 4, cursor: "pointer" }}
                            alignItems={"center"}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 700 }}
                            >
                              {ReactHtmlParser(t("asset.actualBalance"))}
                            </Typography>
                            <Typography variant="caption">
                              ({ReactHtmlParser(t("common.optional"))})
                            </Typography>
                          </Stack>
                          <Box
                            variant="text"
                            onClick={handleShow}
                            sx={{
                              mt: -2,
                              transform: !show ? "rotate(180deg)" : "rotate(0)",
                            }}
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 20L16 12L24.5 20"
                                stroke="#2B3674"
                              />
                            </svg>
                          </Box>
                        </Stack>
                        {show ? (
                          <Stack
                            spacing={2}
                            direction={{ xs: "column", sm: "row" }}
                          >
                            <div>
                              <TextField
                                key="value"
                                className="asset-field-small"
                                label={ReactHtmlParser(t("field.value"))}
                                type="text"
                                required={true}
                                onFocus={(event) => {
                                  event.target.select();
                                }}
                                onChange={(event) => {
                                  setVal(event.target.value);
                                }}
                                {...register("value", {
                                  required: t("errors.field.required"),
                                  onChange: (event) => {
                                    console.log(
                                      formatValue(
                                        event.target.value.replace(/\D/g, "") *
                                          1
                                      )
                                    );
                                    event.target.value = formatValue(
                                      event.target.value.replace(/\D/g, "") * 1
                                    );
                                  },
                                  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                })}
                              />

                              {errors.value && (
                                <span role="alert">{errors.value.message}</span>
                              )}
                            </div>

                            <div>
                              <TextField
                                select
								closeMenuOnSelect={false}
								blurInputOnSelect={false}
                                key="currency"
                                className="asset-field-small"
                                label={ReactHtmlParser(t("field.currency"))}
                                type="text"
                                defaultValue={currency}
                                required={true}
							  onChange={(ev) => {
                              ev.preventDefault();
                              if (ev.target.value == "MORE") {
                                setAccountCurrencyMore(true);

                                setTimeout(() => {
                                  setOpenAccountCurrency(true);
                                }, 1000);
                              } else {
                                setValue("currency", ev.target.value);
                              }
                            }}
                            inputProps={{
                              open: openAccountCurrency,
                              onClose: (ev) => {
                                console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(false);
                                return false;
                              },
                              onOpen: (ev) => {
                                console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(true);
                                return false;
                              },
                            }}
                                {...register("currency", {
                                  required: t("errors.field.required"),
								  onChange: (ev) => {
                                if (ev.target.value == "MORE") {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  setAccountCurrencyMore(true);
                                  setTimeout(() => {
                                    setOpenAccountCurrency(true);
                                  }, 250);
                                  return false;
                                } else {
                                  setCurrency(ev.target.value);
                                  setValue("currency", ev.target.value);
                                }
                              },
                                  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                })}
                              >
{!accountCurrencyMore && (							  
							  <MenuItem
                                value={currency}
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
							  {currency}
                              </MenuItem>
                            )}
                            {Object.keys(currencyMinJSON).map((key, index) => {
                              return (
                                !accountCurrencyMore && (currencyJSON[key].code != currency) && (
                                  <MenuItem
                                    closeOnSelect={false}
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                            {!accountCurrencyMore && (
                              <MenuItem
                                value="MORE"
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
                                {t("common.moreCurrency")}
                              </MenuItem>
                            )}

                            {Object.keys(currencyJSON).map((key, index) => {
                              return (
                                accountCurrencyMore && (
                                  <MenuItem
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                              </TextField>

                              {errors.currency && (
                                <span role="alert">
                                  {errors.currency.message}
                                </span>
                              )}
                            </div>
                          </Stack>
                        ) : (
                          ""
                        )}
                      </Box>
                    </CardContent>
                  </Card>

                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ mt: 4, justifyContent: "end", alignItems: "center" }}
                  >
                    <Button
                      variant="text"
                      className="secondary-blue-text-btn"
                      onClick={(e) => {
                        if (!id) {
                          navigate("/asset-list");
                        } else {
                          setEditMode(false);
                        }
                      }}
                    >
                      {ReactHtmlParser(t("common.buttondocancel"))}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      className="small-btn secondary-blue-btn"
                      disabled={!isSaveEnabled}
                      onClick={(ev) => {
                        formRef.current.requestSubmit();
                      }}
                      endIcon={
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                            fill="#E5FE4A"
                          />
                        </svg>
                      }
                    >
                      {ReactHtmlParser(t("asset.insuranceSave"))}
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} sx={{ pl: { xs: 0, md: 10 }, mt: 2 }}>
                  <Box>
                    <Box sx={{ my: 6 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, mb: 0 }}
                      >
                        {ReactHtmlParser(t("asset.insurance.documentsTitle"))}{" "}
                        {attachmentFiles.length > 0 && (
                          <>({attachmentFiles.length})</>
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "primary.main" }}
                      ></Typography>
                    </Box>
                    <AddFileToAsset onFileUpload={onFileUpload} />
                    <Box sx={{ mt: 2 }}>
                      <ShowFileOfAsset
                        globalEvent={globalEvent}
                        setGlobalEvent={setGlobalEvent}
                        itemList={attachmentFiles}
                        editMode={editMode}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </DefaultLayout>
      <DialogPrompt
        cancelLabel={ReactHtmlParser(t("common.cancel"))}
        selectLabel={ReactHtmlParser(t("common.select"))}
        dialogTitle={ReactHtmlParser(t("asset.realEstate.addCategoryTitle"))}
        dialogText={ReactHtmlParser(
          t("asset.realEstate.addCategoryDescription")
        )}
        defaultValue={extraCategory}
        isOpen={extraCategoryOpen}
        onSelectValue={(val) => {
          if (val) {
            setCategory(val);
            setExtraCategory(val);
          }
        }}
        onClose={() => setExtraCategoryOpen(false)}
      />
    </>
  );
};

export default EditInsurance;
