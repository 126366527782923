import { useCallback, useState, useRef } from "react";
import { Box, Paper, Typography, IconButton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

function AddFileToAsset({ onFileUpload }) {
  const inputFile = useRef(null);

  const [dragOver, setDragOver] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0]);
      }
    },
    [onFileUpload]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files[0]) {
        onFileUpload(event.target.files[0]);
        event.target.value = null;
      }
    },
    [onFileUpload]
  );

  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? "2px dashed #2B36741A" : "2px dashed #2B36741A",
        borderRadius: "16px",
        padding: 32,
        textAlign: "center",
        cursor: "pointer",
        width: "fill 399px",
        background: dragOver ? "#eee" : "#FAFCFC",
      }}
    >
      <input
        accept=".pdf,.doc,.docx,.xls,.rtf,.txt,.odt"
        ref={inputFile}
        style={{ display: "none" }}
        id="button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="button-file">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "Center",
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{ opacity: 0.6 }}
          >
            <svg
              width="65"
              height="65"
              viewBox="0 0 65 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1667 24.5C15.9031 24.5 16.5 23.903 16.5 23.1667V13.8333C16.5 12.3606 17.6939 11.1667 19.1667 11.1667C20.6395 11.1667 21.8334 12.3606 21.8334 13.8333V23.1667C21.8334 26.8486 18.8486 29.8333 15.1667 29.8333C11.4848 29.8333 8.50004 26.8486 8.50004 23.1667V9.83333C8.50004 9.09695 7.90309 8.5 7.16671 8.5C6.43033 8.5 5.83337 9.09695 5.83337 9.83333V23.1667C5.83337 28.3213 10.012 32.5 15.1667 32.5C20.3214 32.5 24.5 28.3213 24.5 23.1667V13.8333C24.5 10.8878 22.1122 8.5 19.1667 8.5C16.2212 8.5 13.8334 10.8878 13.8334 13.8333V23.1667C13.8334 23.903 14.4303 24.5 15.1667 24.5ZM31.1667 13.8333C30.4303 13.8333 29.8334 13.2364 29.8334 12.5C29.8334 11.7636 30.4303 11.1667 31.1667 11.1667H49.8334C53.5153 11.1667 56.5 14.1514 56.5 17.8333V52.5C56.5 56.1819 53.5153 59.1667 49.8334 59.1667H20.5C16.8181 59.1667 13.8334 56.1819 13.8334 52.5V39.1667C13.8334 38.4303 14.4303 37.8333 15.1667 37.8333C15.9031 37.8333 16.5 38.4303 16.5 39.1667V52.5C16.5 54.7091 18.2909 56.5 20.5 56.5H49.8334C52.0425 56.5 53.8334 54.7091 53.8334 52.5V17.8333C53.8334 15.6242 52.0425 13.8333 49.8334 13.8333H31.1667ZM31.1667 27.1667C30.4303 27.1667 29.8334 26.5697 29.8334 25.8333C29.8334 25.097 30.4303 24.5 31.1667 24.5H44.5C45.2364 24.5 45.8334 25.097 45.8334 25.8333C45.8334 26.5697 45.2364 27.1667 44.5 27.1667H31.1667ZM25.8334 35.1667C25.097 35.1667 24.5 34.5697 24.5 33.8333C24.5 33.097 25.097 32.5 25.8334 32.5H44.5C45.2364 32.5 45.8334 33.097 45.8334 33.8333C45.8334 34.5697 45.2364 35.1667 44.5 35.1667H25.8334ZM24.5 41.8333C24.5 42.5697 25.097 43.1667 25.8334 43.1667H39.1667C39.9031 43.1667 40.5 42.5697 40.5 41.8333C40.5 41.097 39.9031 40.5 39.1667 40.5H25.8334C25.097 40.5 24.5 41.097 24.5 41.8333Z"
                fill="#2B3674"
              />
            </svg>
          </IconButton>
          <Typography
            variant="body4"
            sx={{ fontWeight: 700, mt: 2, opacity: 0.6 }}
          >
            {ReactHtmlParser(t("upload.file1"))}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "primary.main", fontWeight: 700, mt: 2, opacity: 0.6 }}
          >
            {ReactHtmlParser(t("upload.file2"))}
          </Typography>
          <Button
            variant="outlined"
            className="small-btn outlined-btn"
            onClick={(event) => {
              inputFile.current.value = "";
              inputFile.current.type = "text";
              inputFile.current.type = "file";
              inputFile.current.click();
            }}
            sx={{
              mt: 2,
              boxShadow: "0px 0px 16px 0px #0000001A",
            }}
            startIcon={
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18 3.5H15.5V1C15.5 0.723858 15.2761 0.5 15 0.5C14.7239 0.5 14.5 0.723858 14.5 1V3.5H12C11.7239 3.5 11.5 3.72386 11.5 4C11.5 4.27614 11.7239 4.5 12 4.5H14.5V7C14.5 7.27614 14.7239 7.5 15 7.5C15.2761 7.5 15.5 7.27614 15.5 7V4.5H18C18.2761 4.5 18.5 4.27614 18.5 4C18.5 3.72386 18.2761 3.5 18 3.5ZM9.5 4C9.5 3.72386 9.27614 3.5 9 3.5H1C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5H9C9.27614 4.5 9.5 4.27614 9.5 4ZM12 8.5C12.2761 8.5 12.5 8.72386 12.5 9C12.5 9.27614 12.2761 9.5 12 9.5H1C0.723858 9.5 0.5 9.27614 0.5 9C0.5 8.72386 0.723858 8.5 1 8.5H12ZM18.5 14C18.5 13.7239 18.2761 13.5 18 13.5H1C0.723858 13.5 0.5 13.7239 0.5 14C0.5 14.2761 0.723858 14.5 1 14.5H18C18.2761 14.5 18.5 14.2761 18.5 14Z"
                  fill="#2B3674"
                />
              </svg>
            }
          >
            {ReactHtmlParser(t("common.browse"))}
          </Button>
        </Box>
      </label>
    </Paper>
  );
}

export default AddFileToAsset;
