import { Box, Button, Typography, Grid } from "@mui/material";
import img from "../../Assets/who_is_it_for.png";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

export const WhoIsItFor = ({ registration }) => {
  const { t, i18n } = useTranslation();

  return (
    <div id="whoisitfor">
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          mt: 12,
          bgcolor: "#C3F0FD",
          borderRadius: 5,
          border: 2,
          borderColor: "#C3F0FD",
        }}
      >
        <Grid
          container
          sx={{
            py: 6,
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              px: 3,
			  pl: { xs: 3, md: 10 },
              order: { xs: 2, md: 1 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant={"h5"}
                sx={{
                  mt: 3,
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                {ReactHtmlParser(t("whoisitfor.title"))}
              </Typography>
              <Typography
                sx={{
                  mb: 3,
                  maxWidth: "557px",
                  mt: 1,
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                {ReactHtmlParser(t("whoisitfor.subtitle"))}
              </Typography>
              <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                <svg
                  width="175"
                  height="13"
                  viewBox="0 0 175 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_279_8608)">
                    <path
                      d="M38.0876 2.64715C54.7108 2.2664 71.343 2.02163 87.9752 1.91284C104.607 1.80406 121.086 1.84032 137.636 2.01256C147.038 2.11229 156.449 2.24827 165.851 2.42958L167.127 0.571142C145.663 0.94283 124.208 1.40517 102.752 1.95817C81.2969 2.51117 59.8416 3.15482 38.3862 3.8982C26.3419 4.31521 14.3067 4.75036 2.26235 5.22177C1.55653 5.24897 0.497784 5.43028 0.0996249 6.11926C-0.271387 6.76291 0.416342 7.09834 0.986434 7.08021C23.3467 6.50908 45.725 6.45468 68.0943 6.92609C90.4637 7.3975 112.815 8.40378 135.139 9.92679C147.681 10.779 160.214 11.8034 172.729 12.9909C173.407 13.0544 174.53 12.719 174.891 12.0935C175.29 11.4135 174.548 11.1778 174.005 11.1325C151.735 9.02024 129.411 7.4247 107.069 6.35496C84.7265 5.28523 62.3482 4.75036 39.9789 4.7413C27.4097 4.7413 14.8406 4.89541 2.2714 5.22177L0.995483 7.08021C22.4418 6.23711 43.8971 5.49374 65.3525 4.83195C86.8078 4.17923 108.263 3.6081 129.719 3.13669C141.763 2.87379 153.816 2.62902 165.861 2.42051C166.566 2.41145 167.634 2.20294 168.023 1.52303C168.412 0.843109 167.697 0.571142 167.136 0.562077C150.495 0.253848 133.872 0.0634713 117.24 0.0181436C100.608 -0.0271842 84.1293 0.0544058 67.5786 0.281045C58.1766 0.407962 48.7655 0.580208 39.3635 0.797781C38.6577 0.815912 37.5899 1.01535 37.2008 1.69527C36.8298 2.33892 37.5175 2.67435 38.0876 2.65622V2.64715Z"
                      fill="#2B3674"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_279_8608">
                      <rect width="175" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
              <Typography
                variant={"body2"}
                sx={{
                  my: 3,
                  maxWidth: "557px",
                  color: "rgba(43, 54, 116, 0.7)",
                  lineHeight: "27px",
                }}
              >
                {ReactHtmlParser(t("whoisitfor.text"))}{" "}
              </Typography>
              <Button
                className="main-blue-btn"
                onClick={registration}
                sx={{
                  width: { xs: "100%", md: "auto" },
                  my: 2,
                  py: 2.75,
                  px: 4,
                }}
                endIcon={
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                      fill="#E5FE4A"
                    />
                  </svg>
                }
                variant={"contained"}
              >
                {ReactHtmlParser(t("common.futureoflovedone"))}
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              px: 3,
              order: { xs: 1, md: 2 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              alt="background"
              src={img}
              sx={{
                maxWidth: "100%",
                height: "auto",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
