import { fetchWithAuth } from "../api";
import { resetFileState } from "./fileActions";

export const fetchTimeCapsuleEntries = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_TIMECAPSULE_ENTRIES_REQUEST" });
    try {
      const data = await fetchWithAuth("diary-items");
      if(data.errors) {
        dispatch({ type: "FETCH_TIMECAPSULE_ENTRIES_FAILURE", payload: data.errors });
      } else {
        dispatch({ type: "FETCH_TIMECAPSULE_ENTRIES_SUCCESS", payload: data.diaryItems });
      }
    } catch (error) {
      dispatch({ type: "FETCH_TIMECAPSULE_ENTRIES_FAILURE", payload: [] });
    }
  };
};

export const fetchTimeCapsuleImages = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_TIMECAPSULE_IMAGES_REQUEST" });
    try {
      const data = await fetchWithAuth("attachments/diary-items");
      dispatch({ type: "FETCH_TIMECAPSULE_IMAGES_SUCCESS", payload: data.attachments });
    } catch (error) {
      dispatch({ type: "FETCH_TIMECAPSULE_IMAGES_FAILURE", payload: [] });
    }
  };
};

export const fetchQuestion = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_TIMECAPSULE_QUESTION_REQUEST" });
    try {
      const data = await fetchWithAuth("diary-items/questions");
      dispatch({ type: "FETCH_TIMECAPSULE_QUESTION_SUCCESS", payload: data.diaryQuestion });
    } catch (error) {
      dispatch({ type: "FETCH_TIMECAPSULE_QUESTION_FAILURE", payload: [] });
    }
  };
}

export const saveEntry = (entryId, entryData) => {
  return async (dispatch) => {
    try {
      await fetchWithAuth(
        entryId ? "diary-items/" + entryId : "diary-items",
        {
          method: entryId ? "PATCH" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(entryData),
        }
      );
      dispatch(resetFileState());
      dispatch({ type: "SAVE_TIMECAPSULE_ENTRY_SUCCESS" });
      dispatch(fetchTimeCapsuleEntries());
      dispatch(fetchTimeCapsuleImages());
    } catch (error) {
      dispatch({ type: "SAVE_TIMECAPSULE_ENTRY_FAILURE", payload: error });
    }
  };
};

export const setEntryTitle = (title) => {
  return {
    type: "SET_TIMECAPSULE_ENTRY_TITLE",
    payload: title,
  };
};

export const setEntryContent = (content) => {
  return {
    type: "SET_TIMECAPSULE_ENTRY_CONTENT",
    payload: content,
  };
};

export const setEntryLocation = (location) => {
  return {
    type: "SET_TIMECAPSULE_ENTRY_LOCATION",
    payload: location,
  };
};

export const resetEntry = () => {
  return {
    type: "RESET_TIMECAPSULE_ENTRY",
  };
};

export const editEntry = (id) => {
  return {
    type: "EDIT_TIMECAPSULE_ENTRY",
    payload: id,
  };
};

export const deleteEntry = (entryId) => {
  
  return async (dispatch) => {
    try {
      await fetchWithAuth(
        "diary-items/" + entryId,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" }
        }
      );

      
      dispatch(fetchTimeCapsuleEntries());
      dispatch(fetchTimeCapsuleImages());
      return {
        type: "DELETE_TIMECAPSULE_ENTRY",
        payload: entryId
      };
    } catch (error) {
      dispatch({ type: "DELETE_TIMECAPSULE_ENTRY_FAILURE", payload: error });
    }
  };

};
