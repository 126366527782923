import React, { useEffect, useReducer, useState, Suspense } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import "./registration.css";

export const DelUsrStep3 = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Box
      sx={{
        mt: { sx: 0, sm: 16 },
        mb: 5,
        px: { xs: 2, lg: 0 },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("deleteuser.step3title"))}
        </Typography>
        <Typography variant="body3">
          {ReactHtmlParser(t("deleteuser.step3text"))}
        </Typography>
      </Box>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ mt: 4, justifyContent: "end", alignItems: "center" }}
                  >
                    <Button
                      variant="text"
                      className="secondary-blue-text-btn"
                      onClick={(e) => {
						  setGlobalEvent({ type: "doDeleteUserStart", data: {} });
                      }}
                    >
                      {ReactHtmlParser(t("deleteuser.step3cancel"))}
                    </Button>
                    <Button
                      type="button"
					  onClick={(e) => {
                          navigate("/deleteuser/step4");
                      }}
                      variant="contained"
                      className="small-btn secondary-blue-btn"
                      endIcon={
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                            fill="#E5FE4A"
                          />
                        </svg>
                      }
                    >
                      {ReactHtmlParser(t("deleteuser.step3next"))}
                    </Button>
                  </Stack>
    </Box>
  );
};
