import i18n from "i18next";
import authHeader from "../Components/DataModule/AuthHeader";


const checkPlanLimitsReached = async (response, navigate) => {
  if (response.ok || response.status === 403) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      if (data.errors && data.errors.some((err) => err.message === 'plan_limits_reached')) {
        navigate('/subscription');
        return;
      }
      return data;
    }
    return null;
  } else {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
};

export const fetchWithAuthAndStatus = async (
  endpoint,
  options = {},
  navigate
) => {
  const headers = {
    ...authHeader(),
    locale: i18n.language,
    ...options.headers,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
    {
      ...options,
      headers,
    }
  );

  const data = await checkPlanLimitsReached(response, navigate);
  return {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    data,
  };
};

export const fetchWithAuth = async (endpoint, options = {}, navigate) => {
  const headers = {
    ...authHeader(),
    locale: i18n.language,
    ...options.headers,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
    {
      ...options,
      headers,
    }
  );

  return await checkPlanLimitsReached(response, navigate);
};

export const fetchWithLocale = async (endpoint, options = {}, navigate) => {
  const headers = {
    locale: i18n.language,
    ...options.headers,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
    {
      ...options,
      headers,
    }
  );

  return await checkPlanLimitsReached(response, navigate);
};
