import React, { useState } from 'react';

import { 
Button, 
Dialog, 
DialogTitle,
DialogContent, 
DialogContentText,
DialogActions,
Typography,
Box,
TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DialogDelUsr = ({ dialogTitle, dialogText, closeLabel, deleteLabel, isOpen, onClose, onDelete, url, subject  }) => {
  const navigate = useNavigate();
  const style = {    
	'& .MuiPaper-root': {
    borderRadius: "32px",
    bgcolor: "background.paper",
    boxShadow: "4px 4px 20px 0px #0000001A",
    textAlign: "center",
	padding: 6
    },	
  };

	return (
    <Dialog disableRestoreFocus open={isOpen} onClose={onClose} sx={style}>
      <DialogTitle>
	            <Box
            sx={{
			  display: "inline-flex",
              width: "3rem",
              height: "3rem",
              mb: 4,
              p: 8,
              borderRadius: "100%",
              boxShadow: "4px 4px 50px 0px #0000001A",
            }}
          >
	  <DeleteIcon
            sx={{
              width: "3rem",
              height: "3rem",
              color: "primary.main",
              mb: 2,
            }}
          />
		  </Box>
	  <Typography id="modal-modal-title" variant="h6" component="h2">
      {dialogTitle}
      </Typography>
	  </DialogTitle>
        <DialogContent>
          <DialogContentText>
		  <Typography
            id="modal-modal-description"
            variant="body3"
            sx={{ mt: 2 }}
          >
		  {dialogText}
          </Typography>
          </DialogContentText>
		  
		  
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{closeLabel}</Button>
		  
		            <Button
            variant="contained"
            sx={{
              lineHeight: "20.16px",
              my: 3,
              py: 2,
              px: 4,
              bgcolor: "#EB8176",
            }}
			onClick={onDelete}
            endIcon={
              	  <DeleteIcon/>
            }
          >
			{deleteLabel}
          </Button>
		  
        </DialogActions>	
    </Dialog>
  );
}
export default DialogDelUsr;