import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";

const AssetHeader = ({ titleKey, setEditMode, setGlobalEvent, id, chid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ md: "row", xs: "column" }}
      justifyContent={"space-between"}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        sx={{ mb: 6 }}
      >
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="text"
          sx={{
            height: "50px",
            width: "50px !important",
            minWidth: "50px",
            p: 0,
            boxShadow: "0px 0px 10px 0px #0000001A",
            borderRadius: "32px",
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.19697 14.5546L8.4042 15.3477C8.06852 15.6834 7.52573 15.6834 7.19362 15.3477L0.251538 8.40706C-0.0841421 8.07128 -0.0841421 7.52833 0.251538 7.19612L7.19362 0.251947C7.5293 -0.0838342 8.07209 -0.0838342 8.4042 0.251947L9.19697 1.04496C9.53622 1.38431 9.52908 1.93798 9.18268 2.27018L4.87959 6.37096H15.1427C15.6177 6.37096 15.9998 6.75317 15.9998 7.22826V8.37134C15.9998 8.84643 15.6177 9.22865 15.1427 9.22865H4.87959L9.18268 13.3294C9.53264 13.6616 9.53978 14.2153 9.19697 14.5546Z"
              fill="#2B3674"
            />
          </svg>
        </Button>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          {ReactHtmlParser(t(titleKey))}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={{ md: "end", xs: "start" }}
        sx={{ mb: { xs: 6, md: 0 } }}
      >
        {(!chid || chid === "null") && (
          <>
            <Button
              onClick={() => setEditMode(true)}
              variant="outlined"
              className="small-btn outlined-btn"
              startIcon={<EditIcon />}
              sx={{
				display: 'none',  
                boxShadow: "0px 0px 10px 0px #0000001A",
              }}
            >
              {ReactHtmlParser(t("asset.buttondoedit"))}
            </Button>
            <Button
              variant="text"
              sx={{
                display: 'none',
				minWidth: "50px",
                width: "50px !important",
                height: "50px",
                p: 0,
                boxShadow: "0px 0px 10px 0px #0000001A",
                borderRadius: "32px",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setGlobalEvent({
                  type: "doDeleteAsset",
                  data: { id: id },
                });
              }}
            >
              <DeleteIcon sx={{ color: "info.secondary" }} />
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AssetHeader;
