import React, {
  useRef,
  useEffect,
  useReducer,
  useState,
  Suspense,
} from "react";
import { Footer } from "../Footer/Footer";

import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { MainMenu } from "../Navigation/MainMenu";
import {
  Typography,
  Grid,
  Stack,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  InputLabel,
  Switch,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { MainMobileMenu } from "../Navigation/MainMobileMenu";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import ShowPictureOfAsset from "../FileHandlers/ShowPictureOfAsset";
import ShowFileOfAsset from "../FileHandlers/ShowFileOfAsset";
import AddPictureToAsset from "../FileHandlers/AddPictureToAsset";
import AddFileToAsset from "../FileHandlers/AddFileToAsset";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import DefaultLayout from "../Layout/DefaultLayout";

import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "../Commons/Icons/DeleteIcon";

import currencyJSON from "../Assets/Data/Common-Currency.json";
import currencyMinJSON from "../Assets/Data/Common-Currency-min.json";


import DialogPrompt from "../PopupHandler/DialogPrompt";
import CountrySelect from "../Commons/CountrySelect";
import { useDispatch, useSelector } from "react-redux";
import { loadCountries } from "../../store/Actions/countryAction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssetHeader from "../Commons/AssetHeader";
import AssetHeaderBottom from "../Commons/AssetHeaderBottom";

const EditRealEstate = ({ globalEvent, setGlobalEvent, title, data }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [chid, setChid] = useState(localStorage.chid);

  const { countries } = useSelector((state) => state.country);

  const formatValue = (price) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice.replaceAll(" ", "  ");
  };

  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [showPropertyData, setShowPropertyData] = useState(true);
  const handleShowPropertyData = () => {
    setShowPropertyData(!showPropertyData);
  };

  const [showOwnershipData, setShowOwnershipData] = useState(false);
  const handleShowOwnershipData = () => {
    setShowOwnershipData(!showOwnershipData);
  };

  const [showDescription, setShowDescription] = useState(false);
  const handleShowDescription = () => {
    setShowDescription(!showDescription);
  };

  const [showPriceData, setShowPriceData] = useState(false);
  const handleShowPriceData = () => {
    setShowPriceData(!showPriceData);
  };

  const [showRentalData, setShowRentalData] = useState(false);
  const handleShowRentalData = () => {
    setShowRentalData(!showRentalData);
  };

  const [showPersonalData, setShowPersonalData] = useState(false);
  const handleShowPersonalData = () => {
    setShowPersonalData(!showPersonalData);
  };

  const [accountCurrencyMore, setAccountCurrencyMore] = useState(false);
  const [openAccountCurrency, setOpenAccountCurrency] = useState(false);

  const streetTypes = [
    { name: t('natureOfPublicSpaceTypes.street'), code: "street" },
    { name: t('natureOfPublicSpaceTypes.road'), code: "road" },
    { name: t('natureOfPublicSpaceTypes.boulevard'), code: "boulevard" },
    { name: t('natureOfPublicSpaceTypes.square'), code: "square" },
    { name: t('natureOfPublicSpaceTypes.alley'), code: "alley" },
    { name: t('natureOfPublicSpaceTypes.promenade'), code: "promenade" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const formRef = useRef();

  const onSubmit = (data, e) => {
    doSaveAsset(e, collectData(data, e));
    console.log(data, e);
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const [id, setID] = useState(null);
  const [category, setCategory] = useState("Lakás");
  const [draft, setDraft] = useState(true);
  const [value, setVal] = useState("");
  const [currency, setCurrency] = useState("");

  const [mortgageValue, setMortgageValue] = useState("");
  const [mortgageCurrency, setMortgageCurrency] = useState("");

  const [renterName, setRenterName] = useState("");
  const [renterCost, setRenterCost] = useState("");

  const [aParcelNumber, setAParcelNumber] = useState("");
  const [ownershipPercent, setOwnershipPercent] = useState("100%");
  const [ownershipDate, setOwnershipDate] = useState("");
  const [description, setDescription] = useState("");
  const [hasUsufruct, setHasUsufruct] = useState(false);
  const [hasMortgage, setHasMortgage] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [attachmentIds, setAttachmentIds] = useState([]);
  const [attachmentImages, setAttachmentImages] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const [owners, setOwners] = useState([]);
  const [showAdditionalOwner, setShowAdditionalOwner] = useState(false);

  const [extraFields, setExtraFields] = useState([]);

  const categoryMap = {
    'Ház': "asset.realEstate.catHouse",
    'Lakás': "asset.realEstate.catFlat",
    'Telek': "asset.realEstate.catSite",
    'Üzlet': "asset.realEstate.catShop",
    'Garázs': "asset.realEstate.catGarage",
    'Nyaraló': "asset.realEstate.catVacationHome",
    'Raktár': "asset.realEstate.catWarehouse",
    'Egyéb': "asset.realEstate.catOther",
  };

  const defaultCategoryVisibility = ["Ház", "Lakás", "Telek"];
  const [showOtherCategories, setShowOtherCategories] = useState(false);
  const categoriesToShow = showOtherCategories
    ? Object.keys(categoryMap)
    : defaultCategoryVisibility;

  const setExtraField = (extraFieldIndex, extraFieldName, extraFieldValue) => {
    //console.log(extraFields)
    if (!extraFields[extraFieldIndex]) {
      setExtraFields((oldArray) => [
        ...oldArray,
        { name: extraFieldName, value: extraFieldValue },
      ]);
    } else {
      const nextExtraFields = [...extraFields];
      nextExtraFields[extraFieldIndex] = {
        name: extraFieldName,
        value: extraFieldValue,
      };
      setExtraFields(nextExtraFields);
    }
  };

  const deleteExtraField = (extraFieldIndex) => {
    const nextExtraFields = [...extraFields];
    nextExtraFields.splice(extraFieldIndex, 1);
    setExtraFields((oldArray) => [...nextExtraFields]);
  };

  const setOwner = (ownerIndex, ownerName, ownerPercent) => {
    //console.log(owners)
    if (!owners[ownerIndex]) {
      setOwners((oldArray) => [
        ...oldArray,
        { name: ownerName, percent: ownerPercent },
      ]);
    } else {
      const nextOwners = [...owners];
      nextOwners[ownerIndex] = { name: ownerName, percent: ownerPercent };
      setOwners(nextOwners);
    }
  };

  const deleteOwner = (ownerIndex) => {
    const nextOwners = [...owners];
    nextOwners.splice(ownerIndex, 1);
    //console.log(nextOwners);
    setOwners([...nextOwners]);
  };

  const doSaveAsset = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doSaveAsset", data: data });
  };

  const onFileUpload = (data) => {
	setGlobalEvent({
      type: "checkPlan",
      data: { type: "document", subtype: "realEstate", file: data, fileCount: attachmentFiles.length  },
    });
	
	//setGlobalEvent({
    //  type: "doFileUpload",
    //  data: { file: data, uploadType: "document" },
    //});
  };
  
  const onImageUpload = (files) => {
	Array.from(files).forEach((file, index) => {
      setTimeout(() => {
	setGlobalEvent({
      type: "checkPlan",
      data: { type: "image", subtype: "realEstate", file: file, fileCount: attachmentImages.length  },
    });
	}, index * 400);
    });
    //Array.from(files).forEach((file, index) => {
    //  setTimeout(() => {
    //    setGlobalEvent({
    //      type: "doFileUpload",
    //      data: { file, uploadType: "image" },
    //   });
    //  }, index * 300);
    //});
  };
  

  const collectData = (data, e) => {
    let collectedData = {};
    collectedData["asset"] = {};

    if (id * 1 > 0) {
      collectedData["asset"]["id"] = id;
    }

    collectedData["asset"]["assetType"] = "realEstate";
    collectedData["asset"]["value"] = data.value ? data.value.replace(/\D/g,'')*1 : 0;
    collectedData["asset"]["currency"] = data.currency;

    //collectedData['asset']['draft'] = draft;

    collectedData["asset"]["details"] = {};
    collectedData["asset"]["details"]["name"] = data.name;
    collectedData["asset"]["details"]["category"] = data.category;

    collectedData["asset"]["details"]["mortgageValue"] = data.mortgagevalue * 1;
    collectedData["asset"]["details"]["mortgageCurrency"] =
      data.mortgagecurrency;

    collectedData["asset"]["details"]["renterName"] = data.rentername * 1;
    collectedData["asset"]["details"]["renterCost"] = data.rentercost;

    collectedData["asset"]["details"]["aCountry"] = data.aCountry;
    collectedData["asset"]["details"]["aPostalcode"] = data.aPostalcode;
    collectedData["asset"]["details"]["aCity"] = data.aCity;
    collectedData["asset"]["details"]["aStreet"] = data.aStreet;
    collectedData["asset"]["details"]["aStreetType"] = data.aStreetType;
    collectedData["asset"]["details"]["aAddress"] = data.aAddress;

    collectedData["asset"]["details"]["aParcelNumber"] = data.aParcelNumber;
    collectedData["asset"]["details"]["ownershipPercent"] =
      data.ownershipPercent;
    collectedData["asset"]["details"]["ownershipDate"] = data.ownershipDate;
    collectedData["asset"]["details"]["owners"] = owners;

    collectedData["asset"]["details"]["description"] = description;
    collectedData["asset"]["details"]["hasUsufruct"] = hasUsufruct;
    collectedData["asset"]["details"]["hasMortgage"] = hasMortgage;

    collectedData["asset"]["details"]["extraFields"] = extraFields;

    collectedData["attachmentIds"] = attachmentIds;

    return collectedData;
  };

  const setData = (data, e) => {
    setID(data?.id ? data?.id : null);
    setEditMode(false);
    setVal(data?.value ? formatValue(data?.value*1) : 0);
    setCurrency(data?.currency ? data?.currency : "HUF");

    setMortgageValue(
      data?.details?.mortgageValue ? data?.details?.mortgageValue : 0
    );
    setMortgageCurrency(
      data?.details?.mortgageCurrency ? data?.details?.mortgageCurrency : "HUF"
    );

    setRenterName(data?.details?.renterName ? data?.details?.renterName : "");
    setRenterCost(data?.details?.renterCost ? data?.details?.renterCost : 0);

    setValue('name', data?.details?.name ? data?.details?.name : "");
    setCategory(data?.details?.category ? data?.details?.category : "Lakás");

    setValue('aCountry', data?.details?.aCountry ? data?.details?.aCountry : "HU")
    setValue('aPostalcode', data?.details?.aPostalcode ? data?.details?.aPostalcode : "");
    setValue('aCity', data?.details?.aCity ? data?.details?.aCity : "");
    setValue('aStreet',data?.details?.aStreet ? data?.details?.aStreet : "");
    setValue('aStreetType', data?.details?.aStreetType ? data?.details?.aStreetType : "");
    setValue('aAddress',data?.details?.aAddress ? data?.details?.aAddress : "");
    setDescription(
      data?.details?.description ? data?.details?.description : ""
    );

    setAParcelNumber(
      data?.details?.aParcelNumber ? data?.details?.aParcelNumber : ""
    );
    setOwnershipPercent(
      data?.details?.ownershipPercent ? data?.details?.ownershipPercent : ""
    );
    setOwnershipDate(
      data?.details?.ownershipDate ? data?.details?.ownershipDate : ""
    );
    setOwners(data?.details?.owners ? data?.details?.owners : []);

    setExtraFields(
      data?.details?.extraFields ? data?.details?.extraFields : []
    );

    setHasUsufruct(data?.details?.hasUsufruct !== true ? false : true);
    setHasMortgage(data?.details?.hasMortgage !== true ? false : true);
  };

  useEffect(() => {
    console.log(state);
    setData(state?.data);
    setEditMode(state?.editmode ? state?.editmode : false);
    if (state?.data?.id) {
      setGlobalEvent({
        type: "doGetAttachments",
        data: { id: state?.data?.id },
      });
    }

    dispatch(loadCountries());
  }, [state]);


  useEffect(() => {
    setValue("category", category);
  }, [category]);

  useEffect(() => {
    setValue("value", value);
  }, [value]);

  useEffect(() => {
    setValue("currency", currency);
  }, [currency]);

  useEffect(() => {
    setValue("mortgagevalue", mortgageValue);
  }, [mortgageValue]);

  useEffect(() => {
    setValue("mortgagecurrency", mortgageCurrency);
  }, [mortgageCurrency]);

  useEffect(() => {
    setValue("rentername", renterName);
  }, [renterName]);

  useEffect(() => {
    setValue("rentercost", renterCost);
  }, [renterCost]);



  useEffect(() => {
    setValue("aParcelNumber", aParcelNumber);
  }, [aParcelNumber]);

  useEffect(() => {
    setValue("ownershipPercent", ownershipPercent);
  }, [ownershipPercent]);

  useEffect(() => {
    setValue("ownershipDate", ownershipDate);
  }, [ownershipDate]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ EditRealEstate");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseCheckPlan") {
    
	if (globalEvent?.data.subtype  == "realEstate"){
	
	setGlobalEvent({
      type: "doFileUpload",
      data: { file: globalEvent?.data.file, uploadType: globalEvent?.data.type },
    });
	
	}
	
	}

    if (globalEvent?.type == "responseFileUpload") {
      let aryImages = [];
      let aryFiles = [];

      setAttachmentIds([...attachmentIds, globalEvent?.data?.attachment?.id]);

      if (globalEvent?.data.attachment.attachmentType == "image") {
        aryImages.push(globalEvent?.data.attachment);
      } else {
        aryFiles.push(globalEvent?.data.attachment);
      }

      setAttachmentImages([...attachmentImages, ...aryImages]);
      setAttachmentFiles([...attachmentFiles, ...aryFiles]);

      let collectedData = {};
      collectedData["asset"] = {};
      if (id) {
        collectedData["asset"]["id"] = id;
      }
      collectedData["asset"]["assetType"] = "realEstate";
      collectedData["attachmentIds"] = [
        ...attachmentIds,
        globalEvent?.data?.attachment?.id,
      ];

      setGlobalEvent({ type: "doSaveAssetImage", data: collectedData });
    }

    if (globalEvent?.type == "responseSaveAssetImage") {
      setAttachmentIds([]);
	  if (globalEvent?.data?.asset?.id) {
        setID(globalEvent?.data?.asset?.id ? globalEvent?.data?.asset?.id : null);
      }
    }

    if (globalEvent?.type == "responseDeleteAttachment") {
      if (state?.data?.id) {
        setGlobalEvent({
          type: "doGetAttachments",
          data: { id: state?.data?.id },
        });
      }
    }

    if (globalEvent?.type == "responseGetAttachments") {
      console.log(globalEvent?.data.attachments);
      let aryImages = [];
      let aryFiles = [];

      const length = Array.isArray(globalEvent?.data.attachments)
        ? globalEvent?.data.attachments.length
        : 0;
      for (let i = 0; i < length; i++) {
        if (globalEvent?.data.attachments[i].attachmentType == "image") {
          aryImages.push(globalEvent?.data.attachments[i]);
        } else {
          aryFiles.push(globalEvent?.data.attachments[i]);
        }
      }

      setAttachmentImages([...aryImages]);
      setAttachmentFiles([...aryFiles]);
    }
  }, [globalEvent]);

  
  const name = watch("name");
  const aCountry = watch("aCountry");
  const aPostalcode = watch("aPostalcode");
  const aCity = watch("aCity");
  const aStreet = watch("aStreet");
  const aAddress = watch("aAddress");
  const aStreetType = watch("aStreetType");
/*
  useEffect(() => {
    const checkRequiredFields = () => {
      if (
        name &&
        aCountry &&
        aPostalcode &&
        aCity &&
        aStreet &&
        aAddress
      ) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    };

    checkRequiredFields();
  }, [name, aCountry, aPostalcode, aCity, aStreet, aAddress]);
*/

  useEffect(() => {
    const checkRequiredFields = () => {
      if (
        category &&
		aCountry &&
        aCity 
      ) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    };

    checkRequiredFields();
  }, [name, aCountry, aPostalcode, aCity, aStreet, aAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editMode]);

  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        {editMode !== true && (
          <Box
            className="my-container"
            sx={{
              mt: { xs: 0, sm: 8 },
              mb: 20,
              minHeight: "100vh",
              textAlign: "left",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                
              <AssetHeader
                chid={chid}
                id={id}
                setEditMode={setEditMode}
                setGlobalEvent={setGlobalEvent}
                titleKey={'asset.realEstateData'}  />

              </Grid>
              <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                <Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {name}
                    </Typography>

                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 0.5 }}
                    >
                      {categoryMap[category]
                        ? ReactHtmlParser(t(categoryMap[category]))
                        : category}
                    </Typography>

                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 0.5 }}
                    >
                      {aCountry} {aPostalcode} {aCity} {aStreet} {aStreetType} {aAddress}
                    </Typography>

                    {aParcelNumber != "" && (
                      <>
                        <Typography
                          variant="body4"
                          sx={{ color: "primary.main", mt: 0.5 }}
                        >
                          {ReactHtmlParser(t("field.aParcelNumber"))}:{" "}
                          {aParcelNumber}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>

                <Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" }, my: 2 }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.realEstate.ownership"))}
                    </Typography>
                    
                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 2 }}
                    >
                      {ReactHtmlParser(t("field.ownershipDate"))}:{" "}
                      {ownershipDate}
                      <div>
                        ({ReactHtmlParser(t("asset.realEstate.profileowner"))}):{" "}
                        {ownershipPercent}
                      </div>
                    </Typography>
                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 1 }}
                    >
                      {owners.map((key, index) => (
                        <div>
                          {owners[index]?.name}:{owners[index]?.percent}
                        </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                {(description != '') && (
				<Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" }, my: 2 }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.realEstateDescription"))}
                    </Typography>
                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 2 }}
                    >
                      {description}
                    </Typography>
                  </CardContent>
                </Card>)}
				
                <Card
                  className="add-asset-form-card"
                  sx={{ px: 2, py: 1, width: { xs: "auto", md: "100%" } }}
                >
                  <CardContent
                    sx={{
                      "&.MuiCardContent-root": {
                        py: 1,
                      },
                    }}
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={{ xs: "center", sm: "space-between" }}
                      alignItems={{ xs: "space-between", sm: "center" }}
                      sx={{ mb: 0 }}
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 700,
                            mb: 0,
                            color: "primary.light",
                          }}
                        >
                          {ReactHtmlParser(t("asset.actualValue"))}:
                        </Typography>
                        <Typography
                          variant="body4"
                          sx={{ color: "primary.light" }}
                        >
                          {ReactHtmlParser(t("common.refreshed"))}: 2023.01.12.
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700 }}
                      >{`${formatValue(value)} ${currency}`}</Typography>
                    </Stack>
                  </CardContent>
                </Card>

				<AssetHeaderBottom chid={chid}
                id={id}
                setEditMode={setEditMode}
                setGlobalEvent={setGlobalEvent}
                titleKey={'asset.bankAccountData'}  />

              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                sx={{ pl: { xs: 0, md: 10 }, mt: { md: 0, xs: 6 } }}
              >
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 700, mb: 6 }}>
                    {ReactHtmlParser(t("asset.realEstate.imagesTitle"))}{" "}
                    {attachmentImages.length > 0 && (
                      <>({attachmentImages.length})</>
                    )}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <ShowPictureOfAsset
                      globalEvent={globalEvent}
                      setGlobalEvent={setGlobalEvent}
                      itemList={attachmentImages}
                      editMode={editMode}
                    />
                  </Box>

                  <Box sx={{ my: 6 }}>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.realEstate.documentsTitle"))}{" "}
                      {attachmentFiles.length > 0 && (
                        <>({attachmentFiles.length})</>
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "primary.main" }}
                    >
                      {ReactHtmlParser(
                        t("asset.realEstate.documentsDescription")
                      )}
                    </Typography>
                  </Box>
                  <ShowFileOfAsset
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                    itemList={attachmentFiles}
                    editMode={editMode}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/***** ******/}

        {editMode !== false && (
          <>
            <Box
              ref={formRef}
              component="form"
              className="my-container"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <Grid
                sx={{
                  mt: { xs: 0, sm: 8 },
                  mb: 20,
                  minHeight: "100vh",
                  textAlign: "left",
                }}
                container
              >
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 6, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
                  >
                    {editMode && id ? ReactHtmlParser(t("asset.realEstateEdit")) : ReactHtmlParser(t("asset.realEstateNew")) }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          py: 1,
                        },
                      }}
                    >
                      <Stack
                        onClick={handleShowPropertyData}
                        sx={{ cursor: "pointer" }}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 700,
                            mb: 0,
                          }}
                        >
                          {ReactHtmlParser(t("asset.realEstateData"))}
                        </Typography>
                        <Box
                          variant="text"
                          onClick={handleShowPropertyData}
                          sx={{
                            mt: 0.7,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: !showPropertyData
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.5 20L16 12L24.5 20" stroke="#1542B9" />
                          </svg>
                        </Box>
                      </Stack>
                      {showPropertyData ? (
                        <>
                          <div>
                            <TextField
                              tabIndex="0"
                              key="name"
                              className="asset-field-fullwidth"
                              label={ReactHtmlParser(t("field.name"))}
                              type="text"
                              required={false}
                              sx={{ mt: 4 }}
                              {...register("name", {
                               /* required: t('errors.field.required'),
                                pattern: {
                                  value: /^(?!\s*$).+/,
                                  message: t('errors.field.invalidName')
                                },*/
                              })}
                            />

                            {errors.name && (
                              <span role="alert">{errors.name.message}</span>
                            )}
                          </div>

                          <Box sx={{ borderTop: "2px solid #EBEBEB", my: 6 }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 700, mb: 4, mt: 6 }}
                            >
                              {ReactHtmlParser(t("asset.realEstate.category"))}
                            </Typography>

                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              spacing={1}
                              alignItems={"center"}
                            >
                              {categoriesToShow.map((cat) => (
                                <Button
                                  key={cat}
                                  className={`asset-categories-btn ${
                                    category === cat
                                      ? "asset-categories-selected-btn"
                                      : ""
                                  }`}
                                  variant="contained"
                                  onClick={() => setCategory(cat)}
                                >
                                  {ReactHtmlParser(t(categoryMap[cat]))}
                                </Button>
                              ))}

                              {!showOtherCategories && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "primary.main",
                                    fontWeight: 700,
                                    pl: 2,
                                  }}
                                  onClick={() =>
                                    setShowOtherCategories(!showOtherCategories)
                                  }
                                >
                                  {ReactHtmlParser(
                                    t("asset.realEstate.morecategory")
                                  )}
                                </Typography>
                              )}
                            </Stack>
                          </Box>
                          <Box
                            sx={{
                              mt: 6,
                              borderTop: "2px solid #EBEBEB",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 700, mb: 2, mt: 6 }}
                            >
                              {ReactHtmlParser(
                                t("asset.realEstate.addressTitle")
                              )}
                            </Typography>
                            <Stack spacing={2} sx={{ mb: 0 }}>
                              
                            <div>
                              <CountrySelect
                                register={register}
                                errors={errors}
                                countries={countries}
                                defaultValue={aCountry}
                                onCountryChanged={(value) => setValue('aCountry', value)}
                              />

                                  <div>
                                    {errors.aCountry && (
                                      <span role="alert">
                                        {errors.aCountry.message}
                                      </span>
                                    )}
                                  </div>

                                </div>
                              <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={{ xs: 2, sm: 1 }}
                              >
                                <div>
                                  <TextField
                                    key="aPostalcode"
                                    className="asset-field-small"
                                    sx={{width: "220px !important"}}
                                    label={ReactHtmlParser(
                                      t("field.aPostalcode")
                                    )}
                                    type="text"
                                    required={false}
                                    {...register("aPostalcode", { 
                                    /*  required: t('errors.field.required'),
                                      pattern: {
                                        value: /^[A-Za-z0-9 -]{3,10}$/,
                                        message: t('errors.field.invalidPostalCode')
                                      }*/
                                    })}
                                  />

                                  {errors.aPostalcode && (
                                    <span role="alert">
                                      {errors.aPostalcode.message}
                                    </span>
                                  )}
                                </div>

                                <div style={{"width" : "100%"}}>
                                  <TextField
                                    key="aCity"
                                    className="asset-field-large"
                                    sx={{width: '100% !important'}}
                                    label={ReactHtmlParser(t("field.aCity"))}
                                    type="text"
									required={true}
                                    {...register("aCity", {
                                      required: t('errors.field.required'),
                                      pattern: {
                                        value: /^(?!\s*$)[a-zA-Z\s\u00C0-\u024F]+$/,
                                        message: t('errors.field.invalidCity')
                                      },
                                    })}
                                  />

                                  {errors.aCity && (
                                    <span role="alert">
                                      {errors.aCity.message}
                                    </span>
                                  )}
                                </div>
                              </Stack>

                              <div>
                                <TextField
                                  key="aStreet"
                                  className="asset-field-fullwidth"
                                  label={ReactHtmlParser(t("field.aStreet"))}
                                  type="text"
								  required={false}
                                    {...register("aStreet", {
                                      /*required: t('errors.field.required'),
                                      pattern: {
                                        value: /^(?!\s*$)[a-zA-Z\s\u00C0-\u024F]+$/,
                                        message: t('errors.field.invalidStreet')
                                      },*/
                                    })}
                                />

                                {errors.aStreet && (
                                  <span role="alert">
                                    {errors.aStreet.message}
                                  </span>
                                )}
                              </div>

                              <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                              >
                                <div>


                                  <TextField
								    id="aStreetType"
                                    name="aStreetType"
                                    key="aStreetType"
                                    className="asset-field-fullwidth"
                                    label={ReactHtmlParser(t("field.aStreetType"))}
                                    type="text"
									required={false}
                                    {...register("aStreetType", {
                                      /*required: t('errors.field.required'),
                                      pattern: {
                                        value: /^(?!\s*$).+/,
                                        message: t('errors.field.invalidAddress')
                                      },*/
                                    })}
                                  />

                                  {errors.aStreetType && (
                                    <span role="alert">
                                      {errors.aStreetType.message}
                                    </span>
                                  )}
                                </div>

                                <div>
                                  <TextField
                                    key="aAddress"
                                    className="asset-field-fullwidth"
                                    label={ReactHtmlParser(t("field.aAddress"))}
                                    type="text"
									required={false}
                                    {...register("aAddress", {
                                      /*required: t('errors.field.required'),
                                      pattern: {
                                        value: /^(?!\s*$).+/,
                                        message: t('errors.field.invalidAddress')
                                      },*/
                                    })}
                                  />
                                  {errors.aAddress && (
                                    <span role="alert">
                                      {errors.aAddress.message}
                                    </span>
                                  )}
                                </div>
                              </Stack>

                              <div>
                                <TextField
                                  key="aParcelNumber"
                                  className="asset-field-small"
                                  label={ReactHtmlParser(
                                    t("field.aParcelNumber")
                                  )}
                                  type="text"
                                  required={false}
                                  onChange={(event) => {
                                    setAParcelNumber(event.target.value);
                                  }}
                                  {...register("aParcelNumber", {
                                  /*  required: t('errors.field.required'),
                                    pattern: {
                                      value: /^(?!\s*$).+/,
                                      message: t('errors.field.invalidParcelNumber')
                                    }, */
                                  })}
                                />

                                {errors.aParcelNumber && (
                                  <span role="alert">
                                    {errors.aParcelNumber.message}
                                  </span>
                                )}
                              </div>
                            </Stack>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" }, mt: 2 }}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          py: 1,
                        },
                      }}
                    >
                      <Stack
                        onClick={handleShowOwnershipData}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ pb: 0, cursor: "pointer" }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 700,
                            mb: 0,
                            opacity: !showOwnershipData ? 0.5 : 1,
                          }}
                        >
                          {ReactHtmlParser(t("asset.realEstate.ownership"))}
                        
                        {" "}
                          <Typography variant="caption">
                            ({ReactHtmlParser(t("common.optional"))})
                          </Typography>
                        </Typography>
                        <Box
                          variant="text"
                          onClick={handleShowOwnershipData}
                          sx={{
                            mt: 0.7,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: !showOwnershipData
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.5 20L16 12L24.5 20" stroke="#1542B9" />
                          </svg>
                        </Box>
                      </Stack>
                      {showOwnershipData ? (
                        <>
                          <Box sx={{ my: 2 }}>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            ></Stack>
                          </Box>
                          <Box>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              <Stack>
                                <TextField
                                  key="ownershipPercent"
                                  className="asset-field-small"
                                  label={ReactHtmlParser(
                                    t("field.ownershipPercent")
                                  )}
                                  type="text"
                                  sx={{
                                    maxWidth: "94px",
                                    textAlign: "center",
                                    "&.MuiInputBase-root": { paddingLeft: 0 },
                                  }}
                                  onChange={(event) => {
                                    setOwnershipPercent(event.target.value);
                                  }}
                                  {...register("ownershipPercent", {
                                    /*required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                  })}
                                />
                                {errors.ownershipPercent && (
                                  <span role="alert">
                                    {errors.ownershipPercent.message}
                                  </span>
                                )}
                              </Stack>
							  <Stack sx={{height: "100%"}} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                              <Typography
							    variant="body4"
                                sx={{ color: "#000000", fontWeight: 700 }}
                              >
                                (
                                {ReactHtmlParser(
                                  t("asset.realEstate.profileowner")
                                )}
                                )
                              </Typography>
                              </Stack>
							</Stack>

                            {owners.map((key, index) => (
                              <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                                sx={{ mt: 2 }}
                              >
                                <Stack
                                  direction={"column"}
                                  spacing={1}
                                  alignItems={"left"}
                                >
                                  <Typography
                                    variant="body4"
                                    sx={{ color: "#000000", fontWeight: 700 }}
                                  >
                                    {owners[index]?.name}
                                  </Typography>

                                  <TextField
                                    className="asset-field-small"
                                    label={ReactHtmlParser(t("field.percent"))}
                                    type="text"
                                    onBlur={(ev) => {
                                      setOwner(
                                        index,
                                        owners[index]?.name,
                                        ev.target.value
                                      );
                                    }}
                                    defaultValue={owners[index]?.percent}
                                  />
                                </Stack>

                                <Button
                                  variant="text"
                                  className="secondary-blue-text-btn"
                                  onClick={() => {
                                    /*console.log(owners); console.log(index); console.log(key); */ deleteOwner(
                                      index
                                    );
                                  }}
                                >
                                  {ReactHtmlParser(t("common.delete"))}
                                </Button>
                              </Stack>
                            ))}

                            {showAdditionalOwner ? (
                              <>
                                <Stack
                                  spacing={2}
                                  direction={{ xs: "column", sm: "row" }}
                                  sx={{ mt: 2 }}
                                >
                                  <TextField
                                    className="asset-field-small"
                                    label={ReactHtmlParser(
                                      t("field.ownername")
                                    )}
                                    type="text"
                                    onChange={(event) => {}}
                                  />
                                  <TextField
                                    className="asset-field-small"
                                    label={ReactHtmlParser(
                                      t("field.ownerpercent")
                                    )}
                                    type="text"
                                    onChange={(event) => {}}
                                  />
                                  <Button
                                    className="secondary-blue-text-btn"
                                    onClick={(ev) => {
                                      let opels =
                                        ev.target.previousElementSibling.getElementsByTagName(
                                          "input"
                                        );
                                      let onels =
                                        ev.target.previousElementSibling.previousElementSibling.getElementsByTagName(
                                          "input"
                                        );
                                      setOwner(
                                        -1,
                                        onels[0] ? onels[0].value : "",
                                        opels[0] ? opels[0].value : ""
                                      );
                                      setShowAdditionalOwner(false);
                                    }}
                                  >
                                    {ReactHtmlParser(t("common.add"))}
                                  </Button>
                                  <Button
                                    variant="text"
                                    className="secondary-blue-text-btn"
                                    onClick={() => {
                                      setShowAdditionalOwner(false);
                                    }}
                                  >
                                    {ReactHtmlParser(t("common.cancel"))}
                                  </Button>
                                </Stack>
                              </>
                            ) : (
                              <Button
                                variant="text"
                                sx={{ color: "primary.light" }}
                                onClick={() => {
                                  setShowAdditionalOwner(true);
                                }}
                              >
                                {ReactHtmlParser(
                                  t("asset.realEstate.addowner")
                                )}
                              </Button>
                            )}
                          </Box>
                          <Box
                            sx={{
                              mt: "1em",
                              pt: 4,
                              borderTop: "2px solid #EBEBEB",
                            }}
                          >
                            <TextField
                              className="asset-field-small"
                              key="ownershipDate"
                              type="text"
                              label={ReactHtmlParser(t("field.ownershipDate"))}
                              onChange={(event) => {
                                setOwnershipDate(event.target.value);
                              }}
                              {...register("ownershipDate", {
                                /*required: t('errors.field.required'),
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />
                          </Box>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ mt: 4, alignItems: "center" }}
                          >
                            <Checkbox
							   
						  icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="7.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="7.5"
                      stroke="#A6ADBA"
                    />
                  </svg>
                }
                              checked={hasUsufruct}
                              onChange={(event, val) => {
                                setHasUsufruct(val);
                              }}
                            />
                            <Typography
                              variant="body3"
                              sx={{
                                fontSize: "14px",
                                color: "#000000",
                                lineHeight: "17.64px",
                              }}
                            >
                              {ReactHtmlParser(
                                t("asset.realEstate.hasUsufruct")
                              )}
                            </Typography>
                          </Stack>
                        </>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" }, mt: 2 }}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          py: 1,
                        },
                      }}
                    >
                      <Stack
                        onClick={handleShowDescription}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ pb: 0, cursor: "pointer" }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"baseline"}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              mb: 0,
                              opacity: !showDescription ? 0.5 : 1,
                            }}
                          >
                            {ReactHtmlParser(t("asset.realEstateDescription"))}
                          </Typography>
                          <Typography variant="caption">
                            ({ReactHtmlParser(t("common.optional"))})
                          </Typography>
                        </Stack>
                        <Box
                          variant="text"
                          onClick={handleShowDescription}
                          sx={{
                            mt: 0.7,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: !showDescription
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.5 20L16 12L24.5 20" stroke="#1542B9" />
                          </svg>
                        </Box>
                      </Stack>
                      {showDescription ? (
                        <>
                          <TextareaAutosize
                            key="description"
                            placeholder={ReactHtmlParser(
                              t("asset.realEstate.descriptionPlaceholder")
                            )}
                            {...register("description", {
                              /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                            })}
                            value={description}
                            onChange={(event) => {
                              setDescription(event.target.value);
                            }}
                            style={{
                              font: "inherit",
                              width: "100%",
                              height: "133px",
                              resize: "none",
                              border: "1px solid #C6C6C6",
                              borderRadius: "24px",
                              marginTop: "20px",
                              marginLeft: "-10px",
                              paddingTop: "16px",
                              paddingLeft: "16px",
                              paddingRight: "5px",
                              paddingBottom: "16px",
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" }, mt: 2 }}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          py: 1,
                        },
                      }}
                    >
                      <Stack
                        onClick={handleShowPriceData}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ pb: 0, cursor: "pointer" }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"baseline"}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              mb: 0,
                              opacity: !showPriceData ? 0.5 : 1,
                            }}
                          >
                            {ReactHtmlParser(t("asset.realEstate.valueTitle"))}
                          </Typography>
                          <Typography variant="caption">
                            ({ReactHtmlParser(t("common.optional"))})
                          </Typography>
                        </Stack>
                        <Box
                          variant="text"
                          onClick={handleShowPriceData}
                          sx={{
                            mt: 0.7,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: !showPriceData
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.5 20L16 12L24.5 20" stroke="#1542B9" />
                          </svg>
                        </Box>
                      </Stack>
                      {showPriceData ? (
                        <>
                          <Stack
                            spacing={2}
                            direction={{ xs: "column", sm: "row" }}
                            sx={{ mt: 2 }}
                          >
                            <div>
                              <TextField
                                key="value"
                                className="asset-field-small"
                                label={ReactHtmlParser(t("field.value"))}
                                type="text"
                                required={true}
                                onFocus={(event) => {
                                  event.target.select();
                                }}
                                onChange={(event) => {
                                  setVal(event.target.value);
                                }}
                                {...register("value", {
                                  required: t("errors.field.required"),
		  onChange: (event) => {console.log(formatValue(event.target.value.replace(/\D/g,'')*1)); event.target.value = formatValue(event.target.value.replace(/\D/g,'')*1)}
                                  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                })}
                              />

                              {errors.value && (
                                <span role="alert">{errors.value.message}</span>
                              )}
                            </div>

                            <div>
                              <TextField
                                select
                                closeMenuOnSelect={false}
								blurInputOnSelect={false}
								key="currency"
                                className="asset-field-currency"
                                label={ReactHtmlParser(t("field.currency"))}
                                type="text"
								required={true}
                                defaultValue={currency}
							  onChange={(ev) => {
                              ev.preventDefault();
                              if (ev.target.value == "MORE") {
                                setAccountCurrencyMore(true);

                                setTimeout(() => {
                                  setOpenAccountCurrency(true);
                                }, 1000);
                              } else {
                                setValue("currency", ev.target.value);
                              }
                            }}
                            inputProps={{
                              open: openAccountCurrency,
                              onClose: (ev) => {
                                console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(false);
                                return false;
                              },
                              onOpen: (ev) => {
                                console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(true);
                                return false;
                              },
                            }}
                                {...register("currency", {
                                  required: t("errors.field.required"),
								  onChange: (ev) => {
                                if (ev.target.value == "MORE") {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  setAccountCurrencyMore(true);
                                  setTimeout(() => {
                                    setOpenAccountCurrency(true);
                                  }, 250);
                                  return false;
                                } else {
                                  setCurrency(ev.target.value);
                                  setValue("currency", ev.target.value);
                                }
                              },
                                  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                })}
                              >
							 {!accountCurrencyMore && (							  
							  <MenuItem
                                value={currency}
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
							  {currency}
                              </MenuItem>
                            )}
                            {Object.keys(currencyMinJSON).map((key, index) => {
                              return (
                                !accountCurrencyMore && (currencyJSON[key].code != currency) && (
                                  <MenuItem
                                    closeOnSelect={false}
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                            {!accountCurrencyMore && (
                              <MenuItem
                                value="MORE"
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
                                {t("common.moreCurrency")}
                              </MenuItem>
                            )}

                            {Object.keys(currencyJSON).map((key, index) => {
                              return (
                                accountCurrencyMore && (
                                  <MenuItem
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                              </TextField>

                              {errors.currency && (
                                <span role="alert">
                                  {errors.currency.message}
                                </span>
                              )}
                            </div>
                          </Stack>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ mt: 2, alignItems: "center" }}
                          >
                            <Checkbox
							   
						  icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="7.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="7.5"
                      stroke="#A6ADBA"
                    />
                  </svg>
                }
                              checked={hasMortgage}
                              onClick={(event, val) => {
                                setMortgageValue(0);
                              }}
                              onChange={(event, val) => {
                                setHasMortgage(val);
                              }}
                            />
                            <Typography
                              variant="body3"
                              sx={{
                                fontSize: "14px",
                                color: "#000000",
                                lineHeight: "17.64px",
                              }}
                            >
                              {ReactHtmlParser(
                                t("asset.realEstate.hasMortgage")
                              )}
                            </Typography>
                          </Stack>

                          {hasMortgage && (
                            <>
                              <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                                sx={{ mt: 2 }}
                              >
                                <div>
                                  <TextField
                                    key="mortgagevalue"
                                    className="asset-field-small"
                                    label={ReactHtmlParser(
                                      t("field.mortgagevalue")
                                    )}
                                    type="text"
                                    onFocus={(event) => {
                                      event.target.select();
                                    }}

                                    onChange={(event) => {
                                      setMortgageValue(event.target.value);
                                    }}
                                    {...register("mortgagevalue", {
                                      onChange: (event) => {
                                        event.target.value = formatValue(event.target.value.replace(/\D/g,'')*1)
                                      }

                                      /*required: t('errors.field.required')
		  ,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                    })}
                                  />

                                  {errors.value && (
                                    <span role="alert">
                                      {errors.value.message}
                                    </span>
                                  )}
                                </div>

                                <div>
                                  <TextField
                                    select
                                    key="mortgagecurrency"
                                    className="asset-field-currency"
                                    label={ReactHtmlParser(
                                      t("field.mortgagecurrency")
                                    )}
                                    type="text"
                                    defaultValue={mortgageCurrency}
                                    onChange={(event) => {
                                      setMortgageCurrency(event.target.value);
                                    }}
                                    {...register("mortgagecurrency", {
                                      /*required: t('errors.field.required')
		  ,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                    })}
                                  >
                                    {Object.keys(currencyJSON).map(
                                      (key, index) => {
                                        return (
                                          <MenuItem
                                            value={currencyJSON[key].code}
                                            id={index}
                                            key={index}
                                          >
                                            {currencyJSON[key].code}
                                          </MenuItem>
                                        );
                                      }
                                    )}{" "}
                                  </TextField>
                                  {errors.currency && (
                                    <span role="alert">
                                      {errors.currency.message}
                                    </span>
                                  )}
                                </div>
                              </Stack>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                  {//Hide Rental Card}
                  /*
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" }, mt: 2 }}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          py: 1,
                        },
                      }}
                    >
                      <Stack
                        onClick={handleShowRentalData}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ pb: 0, cursor: "pointer" }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"baseline"}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              mb: 0,
                              opacity: !showRentalData ? 0.5 : 1,
                            }}
                          >
                            {ReactHtmlParser(t("asset.realEstate.rentalTitle"))}
                          </Typography>
                          <Typography variant="caption">
                            ({ReactHtmlParser(t("common.optional"))})
                          </Typography>
                        </Stack>
                        <Box
                          variant="text"
                          onClick={handleShowRentalData}
                          sx={{
                            mt: 0.7,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: !showRentalData
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.5 20L16 12L24.5 20" stroke="#1542B9" />
                          </svg>
                        </Box>
                      </Stack>
                      {showRentalData ? (
                        <Stack
                          spacing={2}
                          direction={{ xs: "column", sm: "row" }}
                          sx={{ mt: 2 }}
                        >
                          <TextField
                            key="rentername"
                            className="asset-field-small"
                            label={ReactHtmlParser(t("field.rentername"))}
                            type="text"
                            onChange={(event) => {
                              setMortgageValue(event.target.value);
                            }}
                            {...register("rentername", {
                              //,required: t('errors.field.required')
		 // pattern: {
          //  value: ,
          //  message: "Entered value does not match format"
         // }
                            })}
                          />
                          <TextField
                            key="rentercost"
                            className="asset-field-small"
                            label={ReactHtmlParser(t("field.rentercost"))}
                            type="text"
                            onChange={(event) => {
                              setMortgageValue(event.target.value);
                            }}
                            {...register("rentercost", {
                             //,required: t('errors.field.required')
		  // pattern: {
          //  value: ,
          //  message: "Entered value does not match format"
          //}
                            })}
                          />
                        </Stack>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                      */}
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ mt: 4, justifyContent: "end", alignItems: "center" }}
                  >
                    <Button
                      variant="text"
                      className="secondary-blue-text-btn"
                      onClick={(e) => {
                        if (!id) {
                          navigate("/asset-list");
                        } else {
                          setEditMode(false);
                        }
                      }}
                    >
                      {ReactHtmlParser(t("common.buttondocancel"))}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      className="small-btn secondary-blue-btn"
                      disabled={!isSaveEnabled}
                      onClick={(ev) => {
                        formRef.current.requestSubmit();
                      }}
                      endIcon={
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                            fill="#E5FE4A"
                          />
                        </svg>
                      }
                    >
                      {ReactHtmlParser(t("asset.realEstateSave"))}
                    </Button>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{ pl: { xs: 0, md: 10 }, mt: { md: 0, xs: 6 } }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 6 }}>
                      {ReactHtmlParser(t("asset.realEstate.imagesTitle"))}{" "}
                      {attachmentImages.length > 0 && (
                        <>({attachmentImages.length})</>
                      )}
                    </Typography>
                    <AddPictureToAsset onFileUpload={onImageUpload} />
                    <Box sx={{ mt: 2 }}>
                      <ShowPictureOfAsset
                        globalEvent={globalEvent}
                        setGlobalEvent={setGlobalEvent}
                        itemList={attachmentImages}
                        editMode={editMode}
                      />
                    </Box>
                    <Box sx={{ my: 6 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, mb: 0 }}
                      >
                        {ReactHtmlParser(t("asset.realEstate.documentsTitle"))}{" "}
                        {attachmentFiles.length > 0 && (
                          <>({attachmentFiles.length})</>
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "primary.main" }}
                      >
                        {ReactHtmlParser(
                          t("asset.realEstate.documentsDescription")
                        )}
                      </Typography>
                    </Box>
                    <AddFileToAsset onFileUpload={onFileUpload} />
                    <Box sx={{ mt: 2 }}>
                      <ShowFileOfAsset
                        globalEvent={globalEvent}
                        setGlobalEvent={setGlobalEvent}
                        itemList={attachmentFiles}
                        editMode={editMode}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </DefaultLayout>
    </>
  );
};

export default EditRealEstate;
