import ReactHtmlParser from 'html-react-parser';
import { t } from "i18next";
import DefaultLayout from "../Layout/DefaultLayout";

const TermsofusePage = ({ globalEvent, setGlobalEvent, title }) => {
  
  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
	  {ReactHtmlParser(t("link.termsofuse"))}				
      </DefaultLayout>
	</>
  );
};

export default TermsofusePage;
