import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithLocale } from '../api';

export const fetchBanners = createAsyncThunk(
  'banner/fetchBanners',
  async ({ position, page }, thunkAPI) => {
    try {
      const response = await fetchWithLocale(`ad?page=${page}&position=${position}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    banners: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default bannerSlice.reducer;
