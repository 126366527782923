import { fetchWithAuth } from "../api";

export const fetchContacts = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_CONTACTS_REQUEST" });
    try {
      const data = await fetchWithAuth("contacts");
      dispatch({ type: "FETCH_CONTACTS_SUCCESS", payload: data.contacts });
    } catch (error) {
      dispatch({ type: "FETCH_CONTACTS_FAILURE", payload: error });
    }
  };
};

export const saveContact = (data) => {
  
  return async (dispatch) => {
    const contactId = data?.contact?.id;

    delete data.contact.id;
    
    await fetchWithAuth(
      contactId ? "contacts/" + contactId : "contacts",
      {
        method: contactId ? "PATCH" : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    dispatch(fetchContacts());
  };

};

export const prepareContactCards = (items) => {
  const cards = [];

  items.forEach((item) => {
    cards.push({
      key: cards.length,
      type: "person",
      title: item.lastName + " " + item.firstName,
      info: item.accessType,
      linTo: "/add-new-contact",
      linToData: { data: item },
    });
  });

  return {
    type: "PREPARE_CONTACTS_CARDS",
    payload: cards,
  };
};
