import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useRef, useState, useEffect } from "react";
import CloseIcon from "../Commons/Icons/CloseIcon";
import { parseBoolean } from "../../store/helper";

const NewAssetSelector = ({globalEvent, setGlobalEvent}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assetTypes = useSelector((state) => state.asset.assetTypes).filter(
    (row) => row.displayHome === true
  );
  const scrollRef = useRef(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [startX, setStartX] = useState(0);
  
  const categoryVisibility = useSelector(
    (state) => state.asset.categoryVisibility
  );
  const [assetType, setAssetType] = useState('');

  useEffect(() => {
    const checkScrollButtons = () => {
      const scrollElement = scrollRef.current;
      if (scrollElement) {
        const { scrollWidth, clientWidth } = scrollElement;
        setShowScrollButtons(scrollWidth > clientWidth);
      }
    };

    checkScrollButtons();
    window.addEventListener("resize", checkScrollButtons);

    console.log("assetCategoryVisibility", categoryVisibility);
    return () => window.removeEventListener("resize", checkScrollButtons);
  }, []);

  const handleScroll = (direction) => {
    if (!categoryVisibility) {
      return;
    }

    const { current: element } = scrollRef;
    const scrollAmount = 200;

    const scroll =
      direction === "left"
        ? element.scrollLeft - scrollAmount
        : element.scrollLeft + scrollAmount;
    element.scrollTo({ left: scroll, behavior: "smooth" });
  };

  const handleClickStart = (e) => {
    setStartX(e.clientX);
  };

  const handleClickEnd = (e) => {
    const endX = e.clientX;
    if (startX - endX > 50) {
      handleScroll("right");
    } else if (endX - startX > 50) {
      handleScroll("left");
    }
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].pageX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].pageX;
    if (startX - endX > 50) {
      handleScroll("right");
    } else if (endX - startX > 50) {
      handleScroll("left");
    }
  };

  const hideButtonStyle = {
    color: "rgba(43, 54, 116, .6)",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "17.64px",
  };

  const toggleCategoriesVisibility = () => {
    dispatch({
      type: "SET_HOMEPAGE_CATEGORY_VISIBILITY",
      payload: !categoryVisibility,
    });
  };


   useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ NewAssetSelector");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseCheckPlan") {
		  if (globalEvent?.data.link == assetType){
		  navigate(globalEvent?.data.link, {
            state: {
              editmode: true,				
              data: { value: 0, currency: "HUF", details: {} },
            },
          });
		  }
    }

  }, [globalEvent]);

  return (
    <>
      {categoryVisibility === true && (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="body1"
                sx={{ fontSize: 18, fontWeight: 700 }}
              >
                {t("home.newAssetButton")}
              </Typography>
            </Grid>
            {showScrollButtons && (
              <Grid item>
                <ArrowBackIosIcon
                  onClick={() => handleScroll("left")}
                  sx={{ cursor: "pointer", fill: "#2B3674" }}
                  fontSize="10"
                />
                <ArrowForwardIosIcon
                  onClick={() => handleScroll("right")}
                  sx={{ cursor: "pointer", fill: "#2B3674" }}
                  fontSize="10"
                />
              </Grid>
            )}
          </Grid>
          <Stack
            ref={scrollRef}
            direction={"row"}
            spacing={3}
            sx={{
              pt: 3,
              pb: 2,
              px: 1.5,
              display: "flex",
              position: "relative",
              overflowX: "scroll",
              boxSizing: "content-box",
              cursor: "grab",
              touchAction: "none",
              userSelect: "none",
              "&::-webkit-scrollbar": {
                width: "100%",
                background: "transparent",
              },
            }}
            onMouseDown={handleClickStart}
            onMouseUp={handleClickEnd}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {assetTypes.map((asset, index) => {
              const Icon = asset.icon;
              return (
                <Box key={index} sx={{ minWidth: "160px" }}>
                  <Card
                    onClick={() => {
                     setAssetType(`/add-new-asset/${asset.type}`);
					 setGlobalEvent({ type: "checkPlan", data: {type:'asset',subtype:(asset.type == "bank-account" ? 'bankAccount' : 
							 asset.type == "housing-savings" ? 'houseSavings' : 
							 asset.type == "real-estate" ? 'realEstate' : asset.type),link:`/add-new-asset/${asset.type}`} });
 					
					   //navigate(`/add-new-asset/${asset.type}`, {
                       // state: {
                       //   editmode: true,
                       //   data: { value: 0, currency: "HUF", details: {} },
                       // },
                      //});
                    
					}}
                    sx={{
                      boxShadow: "0px 0px 16px 0px #0000001A",
                      borderRadius: "16px",
                      width: "160px",
                      height: "140px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px solid #FFFFFF",
                      "&:hover": {
                        border: "2px solid #62C275",
                      },
                    }}
                  >
                    <CardContent>
                      <Stack
                        direction={"column"}
                        spacing={1}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Icon
                          sx={{
                            width: "48px",
                            height: "48px",
                            color: "primary.main",
                            opacity: 0.6,
                          }}
                        />
                        <Typography
                          variant="body3"
                          sx={{
                            fontSize: "14px",
                            lineHeight: "17.64px",
                            fontWeight: 700,
                          }}
                        >
                          {t(`asset-type.${asset.labelCode}`)}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              );
            })}
          </Stack>
        </>
      )}
    </>
  );
};

export default NewAssetSelector;
