import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { t } from "i18next";

const CountrySelect = ({ register, errors, countries, defaultValue, onCountryChanged, width = "100%" }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const defaultCountry = countries.find(c => c.code === defaultValue);
      setSelectedCountry(defaultCountry);
    }
  }, [countries, defaultValue]);

  const handleChange = (event, newValue) => {
    setSelectedCountry(newValue);
    if (newValue) {
      onCountryChanged(newValue.code);
    } else {
      onCountryChanged(null);
    }
  };

  return (
    <FormControl margin="normal" required fullWidth error={!!errors.country}>
      <Autocomplete
        id="country"
        name="country"
        options={countries}
        getOptionLabel={(option) => option.name}
        value={selectedCountry}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("field.country")}
            error={!!errors.country}
            sx={{
              width,
              borderRadius: "50px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px"
              },
            }}
          />
        )}
      />
      {errors.country && (
        <FormHelperText>{errors.country.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CountrySelect;
