import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContentBySlug } from "../../store/Slices/contentSlice";
import { useParams } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import ReactHtmlParser from "html-react-parser";
import { Box } from "@mui/material";

const ContentPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { content, loading, error } = useSelector((state) => state.content);

  useEffect(() => {
    if (slug) {
      dispatch(fetchContentBySlug(slug));
    }
  }, [slug, dispatch]);

  return (
    <div>
      {content ? (
        <DefaultLayout>
            
      <div className="my-container">
        <Box>
          <Box
            sx={{
              position: "relative",
              mt: 5,
              mb: 3,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                px: {md: 10},
              }}
            >
          <h1>{content.title}</h1>
          <p>{ReactHtmlParser(content.content)}</p>
          </Box>
          </Box>
          </Box>
          </div>
        </DefaultLayout>
      ) : (
        <div>No content found</div>
      )}
    </div>
  );
};

export default ContentPage;
