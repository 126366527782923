import { Button, TextField, Typography, Box, Stack } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDetails } from "../../../store/Slices/paymentSlice";
import { t } from "i18next";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { loadCountries } from "../../../store/Actions/countryAction";
import CountrySelect from "../../Commons/CountrySelect";
import {
  getCurrentUser,
  setUserCountry,
  updateBillingAddress,
} from "../../../store/Actions/userAction";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../Layout/ProfileLayout";
import loginbg from "../../Assets/login-background.png";
import { isEmptyObject } from "../../../store/helper";

const BillingDetails = () => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.country);
  const { user, loading: userLoading } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    dispatch(loadCountries());
    if (isEmptyObject(user) === true) {
      dispatch(getCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user && userLoading === false) {
      reset({
        name: user.billingAddress?.name ?? "",
        country: user.billingAddress?.country ?? "",
        city: user.billingAddress?.city ?? "",
        postalCode: user.billingAddress?.postalCode ?? "",
        street: user.billingAddress?.street ?? "",
        number: user.billingAddress?.number ?? "",
      });
    }
  }, [user, userLoading, reset]);

  const onSubmit = async (data) => {
    const paymentDetails = {
      name: data.name,
      country: data.country,
      postalCode: data.postalCode,
      city: data.city,
      street: data.street,
      number: data.number,
    };
    dispatch(setPaymentDetails(paymentDetails));
    dispatch(updateBillingAddress(paymentDetails));

    navigate("/profile", { replace: true });
  };

  const onCountryChanged = (value) => {
    setUserCountry(value);

    reset({
      country: value,
    });
  };

  const inputStyle = {
    borderRadius: "50px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
  };

  return (
    <AuthLayout backgroundImage={loginbg}>
      <div className="login-container">
        <Box
          sx={{
            mt: 16,
            px: { xs: 2, md: 0 },
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                width: "100%",
                textAlign: "start",
              }}
            >
              {t("payment.details.title")}
            </Typography>
            <Typography
              variant="body3"
              sx={{
                mb: 4,
                width: "100%",
                textAlign: "start",
              }}
            >
              {ReactHtmlParser(t("payment.details.description"))}
            </Typography>
          </Box>
          <Stack
            direction="column"
            spacing={6}
            sx={{
              mt: { xs: 0, sm: 4 },
              width: { xs: "100%", md: "auto" },
              justifyContent: "center",
            }}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label={t("payment.details.field.fullname")}
                name="name"
                autoComplete="name"
                autoFocus
                {...register("name", {
                  required: t("payment.details.validation.fullname_required"),
                })}
                sx={inputStyle}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />

              <CountrySelect
                register={register}
                errors={errors}
                countries={countries}
                defaultValue={
                  user && user.billingAddress ? user.billingAddress.country : ""
                }
                {...register("country", {
                  required: t("payment.details.validation.country_required"),
                })}
                onCountryChanged={onCountryChanged}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="postalCode"
                label={t("payment.details.field.postalCode")}
                type="text"
                id="postalCode"
                sx={inputStyle}
                autoComplete="postalCode"
                {...register("postalCode", {
                  required: t("payment.details.validation.postalcode_required"),
                  pattern: {
                    value:
                      /^(?:\d{4}|\d{5}|\d{6}|\d{7}|\d{8}|[A-Z0-9-]{3,10})$/,
                    message: t("errors.field.invalidPostalCode"),
                  },
                })}
                error={!!errors.postalCode}
                helperText={errors.postalCode && errors.postalCode.message}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="city"
                label={t("payment.details.field.city")}
                type="text"
                id="city"
                sx={inputStyle}
                autoComplete="city"
                {...register("city", {
                  required: t("payment.details.validation.city_required"),
                })}
                error={!!errors.city}
                helperText={errors.city && errors.city.message}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="street"
                label={t("payment.details.field.street")}
                type="text"
                id="street"
                sx={inputStyle}
                autoComplete="street"
                {...register("street", {
                  required: t("payment.details.validation.street_required"),
                })}
                error={!!errors.street}
                helperText={errors.street && errors.street.message}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="number"
                label={t("payment.details.field.street_number")}
                type="text"
                id="number"
                sx={inputStyle}
                autoComplete="number"
                {...register("number", {
                  required: t(
                    "payment.details.validation.streetnumber_required"
                  ),
                })}
                error={!!errors.street}
                helperText={errors.number && errors.number.message}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, boxShadow: "none", py: 2 }}
              >
                {t("payment.details.btn_next")}
              </Button>
            </Box>
          </Stack>
        </Box>
      </div>
    </AuthLayout>
  );
};

export default BillingDetails;
