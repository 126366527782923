import { Typography, Box } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";

const stripePromise = loadStripe(
  "pk_test_51P9VVtAfoVrvbvVdaU5uDlN7FhgIDfGBMdAMjmVR9XSA9GDiL1A3YxolVKDyytsCxoVBg94iRpRYhuVEULbucDct00EnOSE64b"
);

const PaymentForm = () => {
  const navigate = useNavigate();

  const clientSecret = useSelector(
    (state) => state.payment.paymentIntentSecret
  );
  const options = {
    clientSecret,
      placeholder: '',
      locale: i18n.language || 'hu',
    };

  const selectedPlanName = useSelector((state) => state.payment.plan);
  const plans = useSelector((state) => state.plan.plans);
  const selectedPlanDetails = plans.find(
    (plan) => plan.name === selectedPlanName
  );

  useEffect(() => {
    if (clientSecret == null && selectedPlanName == null) {
      navigate("/profile");
    }

    if (clientSecret == null) {
      navigate("/payment");
    }
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <Typography component="h1" variant="h5">
        {t("payment.stripe.title")}
      </Typography>
      <Typography variant="body3">
        {t("payment.stripe.selectedPackage")}:{" "}
        <strong>
          {selectedPlanDetails
            ? `${selectedPlanDetails.name} (${selectedPlanDetails.price} Ft / ${t(`billingCycle.${selectedPlanDetails.billingCycle}`)})`
            : t("payment.stripe.noPackageSelected")}
        </strong>
      </Typography>
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm />
      </Elements>
    </Box>
  );
};

export default PaymentForm;
