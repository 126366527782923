import React, { useState } from 'react';

import { 
Button, 
Dialog, 
DialogTitle,
DialogContent, 
DialogContentText,
DialogActions,
Typography,
TextField } from "@mui/material";


const DialogPrompt = ({ dialogTitle, dialogText, cancelLabel, selectLabel, defaultValue, isOpen, onSelectValue, onClose  }) => {

  const style = {    
	'& .MuiPaper-root': {
    borderRadius: "32px",
    bgcolor: "background.paper",
    boxShadow: "4px 4px 20px 0px #0000001A",
    textAlign: "center",
    },	
  };

  const [value, setValue] = React.useState(defaultValue);
  return (
    <Dialog disableRestoreFocus open={isOpen} onClose={onClose} sx={style}>
      <DialogTitle>
	  <Typography id="modal-modal-title" variant="h6" component="h2">
      {dialogTitle}
      </Typography>
	  </DialogTitle>
        <DialogContent>
          <DialogContentText>
		    <Typography
            id="modal-modal-description"
            variant="body3"
            sx={{ mt: 2 }}
          >
		  {dialogText}
          </Typography>
          </DialogContentText>
		  <TextField autoFocus fullWidth onChange={(ev)=>{setValue(ev.target.value)}} value={value} onKeyDown={(e) => {
      if(e.keyCode == 13){
         onClose(); onSelectValue(value)
      }
   }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{onClose(); onSelectValue(value)}}>{selectLabel}</Button>
          <Button onClick={onClose}>{cancelLabel}</Button>
        </DialogActions>	
    </Dialog>
  );
}
export default DialogPrompt;