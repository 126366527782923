const initialState = {
  items: [],
  cards: [],
  loading: false,
  error: null,
  contactTypes: [
    {
      id: "child",
    },
    {
      id: "spouse",
    },
    {
      id: "life-partner",
    },
    {
      id: "other",
    }
  ],
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CONTACTS_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_CONTACTS_SUCCESS":
      return { ...state, loading: false, items: action.payload };
    case "FETCH_CONTACTS_FAILURE":
      return { ...state, loading: false, items: [], error: action.payload };
    case "PREPARE_CONTACTS_CARDS":
      return { ...state, cards: action.payload };
    default:
      return state;
  }
};
export default contactReducer;
