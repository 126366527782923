import React, { useEffect, useReducer, useState, Suspense } from "react";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import currencyJSON from "../Assets/Data/Common-Currency.json";
import ProfileImage from "./ProfileImage";

export const ProfileInfoCard = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const [baseCurrency, setBaseCurrency] = useState([], () => {
    const bCurrency = localStorage.getItem("baseCurrency");
    return bCurrency ? bCurrency : "huf";
  });

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ ProfileInfoCard");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseGetUser") {
      setUser(globalEvent?.data.user);
      console.log(globalEvent?.data.user);
    }
  }, [globalEvent]);

  useEffect(() => {
    localStorage.setItem("baseCurrency", baseCurrency);
  }, [baseCurrency]);

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={3}
              alignItems={"center"}
              justifyContent={{ xs: "center", md: "start" }}
            >
              <ProfileImage withUpload={true} version="large" />
              <Box>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={{ xs: "center", md: "start" }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: { xs: "center", md: "start" },
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 1,
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "20.16px",
                          textAlign: { xs: "center", md: "left" },
                        }}
                      >
                        {user?.lastName} {user?.firstName}
                      </Typography>

                      <Typography
                        variant="body3"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "17.64px",
                          textAlign: { xs: "center", md: "left" },
                        }}
                      >
                        {user?.email}
                      </Typography>
                    </div>
                    <Button
                      className="secondary-blue-text-btn"
                      variant="text"
                      sx={{
                        mt: 0,
                        pl: 0,
                        fontSize: "14px",
                        lineHeight: "17.64px",
                      }}
                      onClick={() => {
                        navigate("/change-email");
                      }}
                    >
                      {t("profile.profileInfo.changeEmail")}
                    </Button>
                    <br></br>
                    <Button
                      className="secondary-blue-text-btn"
                      variant="text"
                      sx={{
                        pl: 0,
                        fontSize: "14px",
                        lineHeight: "17.64px",
                      }}
                      onClick={() => {
                        navigate("/change-password");
                      }}
                    >
                      {t("profile.profileInfo.changePw")}
                    </Button>
                  </div>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
