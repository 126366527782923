import {
  Button,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Container,
  RadioGroup,
  Radio,
  Card,
  Box,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

const WhoSeesForm = ({ onCancel, onSave, type }) => {

  const { t, i18n } = useTranslation();

  const [reservationType, setReservationType] = useState("instant");
  const [selectedDate, setSelectedDate] = useState("2024-11-01T10:30");

   useEffect(() => {
	if (type == 'instant') setReservationType('instant');
	if (type == 'onDeath') setReservationType('onRequest');
	if (type == 'timed') setReservationType('specificTime');
  }, [type]);


  const handleRadioChange = (event) => {
    setReservationType(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ reservationType, selectedDate });
    onSave({ reservationType, selectedDate });
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormControl component="fieldset" fullWidth margin="normal">
        <RadioGroup
          aria-label="betekintési jog"
          name="betekintesiJog"
          value={reservationType}
          onChange={handleRadioChange}
        >
          <Box
            sx={{
              bgcolor: "#FAFCFC",
              borderRadius: "18px",
              padding: "10px",
              mb: 1,
            }}
          >
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "lowercase",
                },
              }}
              value="instant"
              control={type === 'instant'? <Radio defaultChecked />:<Radio />}
              label={t("contact.accesstype.instant")}
            />
          </Box>
          <Box
            sx={{
              bgcolor: "#FAFCFC",
              borderRadius: "18px",
              padding: "10px",
              mb: 1,
            }}
          >
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "lowercase",
                },
              }}
              value="onRequest"
              control={type == 'onDeath'? <Radio defaultChecked />:<Radio />}
              label={t("contact.accesstype.ondeath")}		  
            />
          </Box>
        </RadioGroup>
      </FormControl>

      <Divider />
      <Stack direction="row" sx={{mt: 2}}>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="root"
          style={{ 
            height: '32px',
          }}
          onClick={() => onCancel()}
        >
          {ReactHtmlParser(t("common.buttondocancel"))}
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            height: '32px',
            bgcolor: "#62C275",
            color: "#fff",
            fontSize: "16px",
            fontWeight: 500,
            "&:hover": {
              bgcolor: "#4D975C",
              color: "#fff",
            },
          }}
          color="primary"
        >
          {ReactHtmlParser(t("common.buttondosave"))}
        </Button>
      </Stack>
    </form>
  );
};

export default WhoSeesForm;
