export const Mailto = ({ email, subject = '', body = '',sx ,children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}
  style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#62C275",
                  textDecoration: "none",
                  mt: 1,
                  display: "flex",
                  alignItems: "center"
                }}
>{children}</a>;
};

export default Mailto;