import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth, fetchWithAuthAndStatus } from '../api';

export const createPaymentIntent = createAsyncThunk(
  'payments/intents',
  async (planName, { rejectWithValue }) => {
    try {
      const response = await fetchWithAuthAndStatus(`payments/intents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ paymentIntent: { planName } })
      });
      
      if (response.status === 204) {
        return { redirectToDashboard: true };
      }

      return response.data.paymentIntent;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    name: '',
    country: '',
    city: '',
    plan: 'free',
    plans: [],
    paymentIntentStatus: 'idle',
    paymentIntentSecret: null,
    error: null,
    subscription: {
      id: null,
      planName: 'free',
      pendingPlanName: null,
      finalPrice: 0,
      expiration: null,
      latestPaymentIntentStatus: null,
    },
  },
  reducers: {
    setPaymentDetails: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.country;
      state.address = action.payload.city;
    },
    setSelectedPlan: (state, action) => {
      state.plan = action.payload.plan;
      localStorage.setItem('plan', action.payload.plan);
    },
    loadSelectedPlan: (state) => {
      if(localStorage.getItem('plan')) {
        state.plan = localStorage.getItem('plan');
      }
    },
    resetPlan: (state) => {
      state.plan = 'free';
      localStorage.removeItem('plan');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentIntent.pending, (state) => {
        state.paymentIntentStatus = 'loading';
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.paymentIntentStatus = 'succeeded';
        if(action.payload.redirectToDashboard === undefined) {
          state.paymentIntentSecret = action.payload.clientSecret;
          state.subscription.latestPaymentIntentStatus = 'succeeded';
          state.subscription.pendingPlanName = action.payload.planName;
        }
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.paymentIntentStatus = 'failed';
        state.paymentIntentSecret = null;
        state.error = action.payload;
        state.subscription.latestPaymentIntentStatus = 'failed';
      });
  }
});

export const { setPaymentDetails, setSelectedPlan, resetPlan, loadSelectedPlan } = paymentSlice.actions;
export default paymentSlice.reducer;
