import { fetchWithAuth } from "../api";

export const handleFileUpload = (file, fileType, onFileUploaded) => {
  return async (dispatch) => {
    dispatch({ type: "UPLOAD_FILE_REQUEST" });
    try {
      let formdata = new FormData();
      formdata.append("attachment", file, file?.name);
      formdata.append("attachmentType", fileType);

      const response = await fetchWithAuth("attachments", {
        method: "POST",
        body: formdata,
      });

      if (onFileUploaded) {
        onFileUploaded(response.attachment);
      }

      dispatch({ type: "UPLOAD_FILE_SUCCESS", payload: response.attachment });
    } catch (error) {
      dispatch({ type: "UPLOAD_FILE_FAILURE", payload: error });
    }
  };
};

export const removeFile = (fileId) => {
  return async (dispatch) => {
    dispatch({ type: "UPLOAD_FILE_REMOVE", payload: fileId });
    try {
      await fetchWithAuth(`attachments/${fileId}`, {
        method: "DELETE",
      });
      dispatch({ type: "REMOVE_FILE_SUCCESS", payload: fileId });
    } catch (error) {
      dispatch({ type: "REMOVE_FILE_FAILURE", payload: error });
    }
  };
};

export const resetFileState = () => {
    return async (dispatch) => {
        dispatch({ type: "RESET_FILE_STATE" });
    }
}
