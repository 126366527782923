import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { fetchBanners } from "../../store/Slices/bannerSlice";
import { useEffect, useState } from "react";

const BannerDisplayer = ({ position, page }) => {
  const dispatch = useDispatch();
  const { banners: banner, loading } = useSelector((state) => state.banner);

  const [cardMediaWidth, setCardMediaWidth] = useState("30%");
  const [boxWidth, setBoxWidth] = useState("70%");
  const [titleFontSize, setTitleFontSize] = useState("32px");
  const [titleLineHeight, setTitleLineHeight] = useState("40px");
  const [descriptionFontSize, setDescriptionFontSize] = useState("18px");

  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(fetchBanners({ position, page }));
  }, [dispatch, position, page]);

  useEffect(() => {
    if(banner?.position === position) {
      setShow(true);
    }
  }, [loading]);
  

  useEffect(() => {
    switch (position) {
      case "top":
        setCardMediaWidth("30%");
        setBoxWidth("70%");
        setTitleFontSize("32px");
        setTitleLineHeight("48px");
        setDescriptionFontSize("18px");
        break;
      case "bottom":
        setCardMediaWidth("50%");
        setBoxWidth("50%");
        setTitleFontSize("24px");
        setTitleFontSize("32px");
        setTitleLineHeight("30px");
        setDescriptionFontSize("14px");
        break;
      case "right":
        setCardMediaWidth("100%");
        setBoxWidth("100%");
        setTitleFontSize("24px");
        setTitleLineHeight("30px");
        setDescriptionFontSize("14px");
        break;
      default:
        setCardMediaWidth("30%");
        setBoxWidth("70%");
        setTitleFontSize("32px");
        setDescriptionFontSize("18px");
    }
  }, []);

  if ((banner.image || banner.text || banner.title) && show === true) {
    return (
      <Grid
        container
        xs={12}
        sx={{ mt: 3, mb: 0 }}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: position === "right" ? "column" : "row",
            maxWidth: "1040px",
            mb: 0,
            borderRadius: "32px",
            boxShadow: "none",
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: cardMediaWidth }}
            image={banner.image}
            alt={banner.name}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: boxWidth,
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  fontSize: titleFontSize,
                  lineHeight: "150%",
                }}
              >
                {banner.title}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: descriptionFontSize }}
                paragraph
              >
                {banner.text}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                href={banner.link}
                endIcon={
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7929 13H8C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12H17.7929L14.6464 8.85355C14.4512 8.65829 14.4512 8.34171 14.6464 8.14645C14.8417 7.95118 15.1583 7.95118 15.3536 8.14645L19.3536 12.1464C19.5488 12.3417 19.5488 12.6583 19.3536 12.8536L15.3536 16.8536C15.1583 17.0488 14.8417 17.0488 14.6464 16.8536C14.4512 16.6583 14.4512 16.3417 14.6464 16.1464L17.7929 13Z"
                      fill="#2B3674"
                    />
                  </svg>
                }
                sx={{
                  mt: 4,
                  bgcolor: "#fff",
                  color: "#2B3674",
                  border: "2px solid rgba(43, 54, 116, 0.1)",
                  lineHeight: "32px",
                }}
              >
                {banner.ctaText}
              </Button>
            </CardContent>
          </Box>
        </Card>
      </Grid>
    );
  }
};

export default BannerDisplayer;
