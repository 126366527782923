import React, { useEffect, useReducer, useState } from 'react';

import {
  BrowserRouter,
  Route,
  Routes,
  useHistory,
  useLocation,
  useNavigate
} from 'react-router-dom';

import PopupHandler from '../PopupHandler/PopupHandler';
import HomeComponent from '../HomePage/HomeComponent';
import Login from '../Login/Login';
import Registration from '../Registration/Registration';
import Dashboard from '../Dashboard/Dashboard';
import Landing from '../Landing/Landing';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import {ForgotPassword as ForgotPasswordOriginal} from '../ForgotPassword/ForgotPassword_';
import { ForgotPasswordStep1 } from '../ForgotPassword/ForgotPasswordStep1';
import { ForgotPasswordStep2 } from '../ForgotPassword/ForgotPasswordStep2';
import { ValidationStep2 } from '../ValidationComponents/ValidationStep2';
import ProfilePage from '../Profile/ProfilePage';
import DataModule from '../DataModule/DataModule';
import passwordbg from '../../Assets/nathan-mcbride-DEmPhCx7y8Q-unsplash 1.png'
import { AddUserData } from '../AddUserData/AddUserData';
import { ValidationStep1 } from '../ValidationComponents/ValidationStep1';
import { ValidationHolder } from '../ValidationComponents/ValidationHolder';

import { AddDataStep1 } from '../AddUserData/AddDataStep1';
import AddAssetIndex from '../AddNewAsset/AddAssetIndex';
import AssetListIndex from '../AssetLists/AssetListIndex';
import EditVehicle from '../Vehicle/EditVehicle';
import EditBankAccount from '../BankAccount/EditBankAccount';
import EditRealEstate from '../RealEstate/EditRealEstate';
import EditInsurance from '../Insurance/EditInsurance';
import EditSecurity from '../Security/EditSecurity';
import EditHousingSavings from '../HousingSavings/EditHousingSavings';
import EditCredit from '../Credit/EditCredit';
import EditShare from '../Share/EditShare';
import EditClaim from '../Claim/EditClaim';
import EditCrypto from '../Crypto/EditCrypto';
import EditOther from '../Other/EditOther';

import StaticPage from '../StaticPage/StaticPage';
import ImpressumPage from '../StaticPage/ImpressumPage';
import TermsofusePage from '../StaticPage/TermsofusePage';
import PrivacystatementPage from '../StaticPage/PrivacystatementPage';
import CookiePage from '../StaticPage/CookiePage';
import ContactPage from '../StaticPage/ContactPage';
import LifeSignal from '../StaticPage/LifeSignal';

import ContactListIndex from '../ContactList/ContactListIndex';
import EditContact from '../Contact/EditContact';
import Gamification from '../Gamification/Gamification';
import PostMortem from '../Gamification/PostMortem';
import DashboardNew from '../Dashboard/DashboardNew';
import AuthLayout from '../Layout/AuthLayout';
import PersonalDataForm from '../Payment/PersonalDataForm';
import PaymentForm from '../Payment/PaymentForm';
import PaymentLayout from '../Layout/PaymentLayout';
import { AuthProvider } from '../../context/AuthContext';


import EducationListIndex from '../Education/EducationListIndex';
import EditSubscription from '../Profile/Subscription/EditSubsciption';
import PaymentComplete from '../Payment/PaymentComplete';
import BillingDetails from '../Profile/BillingDetails/BillingDetails';

import DeleteUser from '../DeleteUser/DeleteUser';
import ChangeEmail from '../ChangeEmail/ChangeEmail';

import BillingListIndex from '../Billing/BillingListIndex';
import AboutUs from '../AboutUs/AboutUs';
import ContentPage from '../StaticPage/ContentPage';


const AuthNavigator = () => {


  //const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  //const bhistory = useNavigate(/*{basename: baseUrl}*/);



  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null); 
  const [globalEvent, setGlobalEvent] = useState(null);
  const fireGlobalEvent = (evnt) => {
	console.log(evnt);
	setGlobalEvent(evnt);  
  }
  
  useEffect(() => {
      if (initializing) {
        setInitializing(false);
      }
  }, []);

  if (initializing) return 'Loading....';

  const basename = process.env.REACT_APP_BASE_NAME || '';

  return (
	<>
    <BrowserRouter basename={basename}>
	<DataModule globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}/>
	<PopupHandler globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}/>
	<Routes>
	{/*
        <Route path="/"  element={<HomeComponent globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Kezdőlap"}/>} />
	*/}	
  
    <Route path="/"  element={<Landing globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>} />
    <Route path="/dashboard"  element={<DashboardNew globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>} />

    <Route path="/login"  element={<Login globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Bejelentkezés"}/>} />
	  <Route path="/login/confirm"  element={<ValidationHolder background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Bejelentkezés"} validationType="login"/>} />
    <Route path="/registration">
      <Route index element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={1}/>} />
      <Route path="email" element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={2}/>} />
      <Route path="verify" element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={3}/>} />
      <Route path="consent" element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={4}/>} />
      <Route path="end" element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={5}/>} />
    </Route>

	<Route path="/forgotpassword"  element={<ForgotPasswordStep2  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} formtype="forgot"/>} />
    <Route path="/forgot-password" element={<ForgotPasswordOriginal background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}  formtype="forgot"/>}>
        <Route index element={<ForgotPasswordStep1  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} formtype="forgot"/>} />,
        <Route path="step2" element={<ForgotPasswordStep2  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} formtype="forgot"/>} />,
        <Route path="done" element={<ValidationStep2 title="A jelszó módosítás sikeres!" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et " link="" />} />,
    </Route>

      <Route path="/profile"  element={<AuthProvider><ProfilePage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="subscription" element={<PaymentLayout />}>
        <Route index element={<AuthProvider><EditSubscription globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Csomagok"}/></AuthProvider>} />
      </Route>
      <Route path="/billing-details"  element={<AuthProvider><BillingDetails globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Számlázási adatok"}/></AuthProvider>} />
      
	  <Route
        path="/change-email"
        element={<AuthProvider><ForgotPasswordOriginal background={passwordbg} formtype="change"/></AuthProvider>}
        >
		  <Route
            index
            element={<ChangeEmail background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />}
          />,
          <Route
            path="step2"
            element={<ValidationStep1  background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} validationType="changeemail"/>}
          />,
          <Route
            path="done"
            element={<ValidationStep2
                title="Az email módosítás sikeres!"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et "
                link=""
              />}
          />,
      </Route>
	  
      <Route
        path="/change-password"
        element={<AuthProvider><ForgotPasswordOriginal background={passwordbg} formtype="change"/></AuthProvider>}
        >
          <Route
            index
            element={<ForgotPasswordStep2 background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} formtype="change"/>}
          />,
          <Route
            path="step2"
            element={<ValidationStep1  background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} validationType="changepassword"/>}
          />,
          <Route
            path="done"
            element={<ValidationStep2
                title="A jelszó módosítás sikeres!"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et "
                link=""
              />}
          />,
      </Route>


      <Route path="/add-user-data"  element={<AddUserData />}>
        <Route index element={<AddDataStep1  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}  />}/>
        <Route path="step2" element={<ValidationStep1  background={passwordbg} globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} validationType="userdata"/>} />
        <Route path="done" element={<ValidationStep2  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} 
                title={"A jóváhagyás sikeres!"}
                text={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
                }
                link={"#"}
              />}/>
      </Route>
    
      <Route path="/asset-list"  element={<AuthProvider><AssetListIndex globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>}/> 

      <Route path="/add-new-asset"  element={<AuthProvider><AddAssetIndex globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>}/> 
          
      <Route path="/add-new-asset/vehicle"  element={<AuthProvider><EditVehicle globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/bank-account"  element={<AuthProvider><EditBankAccount globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/real-estate"  element={<AuthProvider><EditRealEstate globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/insurance"  element={<AuthProvider><EditInsurance globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/security"  element={<AuthProvider><EditSecurity globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/housing-savings"  element={<AuthProvider><EditHousingSavings globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/credit"  element={<AuthProvider><EditCredit globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/share"  element={<AuthProvider><EditShare globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/claim"  element={<AuthProvider><EditClaim globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/crypto"  element={<AuthProvider><EditCrypto globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/add-new-asset/other"  element={<AuthProvider><EditOther globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />


      
      <Route path="/add-new-contact"  element={<AuthProvider><EditContact globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>} />
      <Route path="/contact-list"  element={<AuthProvider><ContactListIndex globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>}/> 
      
      <Route path="/time-capsule"  element={<AuthProvider><Gamification globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>}/> 
      <Route path="/post-mortem-request"  element={<AuthProvider><PostMortem globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/></AuthProvider>}/> 

    <Route path="/payment" element={<PaymentLayout />}>
      <Route index element={<PersonalDataForm />} />
      <Route path="card-data" element={<PaymentForm />} />
      <Route path="complete" element={<PaymentComplete />} />
    </Route>
    <Route path="/faq"  element={<EducationListIndex globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/>

    <Route path="/content/:slug" element={<ContentPage />} />
	<Route path="static/impressum"  element={<ImpressumPage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	<Route path="static/termsofuse"  element={<TermsofusePage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	<Route path="static/privacystatement"  element={<PrivacystatementPage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	<Route path="static/cookie"  element={<CookiePage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	<Route path="static/contact"  element={<ContactPage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	<Route path="static/lifesignal"  element={<LifeSignal globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	<Route path="/about-us"  element={<AboutUs globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	
	
	<Route path="static_/:pageName"  element={<StaticPage globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 
	
	<Route path="/deleteuser">
      <Route index element={<AuthProvider><DeleteUser globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Fiók törlés"} cStep={1}/></AuthProvider>} />
      <Route path="step2" element={<AuthProvider><DeleteUser globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Fiók törlés"} cStep={2}/></AuthProvider>} />
      <Route path="step3" element={<AuthProvider><DeleteUser globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Fiók törlés"} cStep={3}/></AuthProvider>} />
      <Route path="step4" element={<AuthProvider><DeleteUser globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Fiók törlés"} cStep={4}/></AuthProvider>} />
      <Route path="step5" element={<AuthProvider><DeleteUser globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Fiók törlés"} cStep={5}/></AuthProvider>} />
      <Route path="step6" element={<AuthProvider><DeleteUser  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} validationType="deleteuser" cStep={6}/></AuthProvider>} />
      <Route path="step7" element={<AuthProvider><DeleteUser  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} cStep={7} /></AuthProvider>}/>
    </Route>
	
    <Route path="/billing"  element={<BillingListIndex globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>}/> 


	</Routes>
	</BrowserRouter>

	</>
  );
}

export default AuthNavigator;