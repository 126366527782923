const DeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 6V18.5C19 19.8807 17.8807 21 16.5 21H7.5C6.11929 21 5 19.8807 5 18.5V6H4.5C4.22386 6 4 5.77614 4 5.5C4 5.22386 4.22386 5 4.5 5H9V4.5C9 3.67157 9.67157 3 10.5 3H13.5C14.3284 3 15 3.67157 15 4.5V5H19.5C19.7761 5 20 5.22386 20 5.5C20 5.77614 19.7761 6 19.5 6H19ZM6 6V18.5C6 19.3284 6.67157 20 7.5 20H16.5C17.3284 20 18 19.3284 18 18.5V6H6ZM14 4.5V5H10V4.5C10 4.22386 10.2239 4 10.5 4H13.5C13.7761 4 14 4.22386 14 4.5ZM14 9.5C14 9.22386 14.2239 9 14.5 9C14.7761 9 15 9.22386 15 9.5V16.5C15 16.7761 14.7761 17 14.5 17C14.2239 17 14 16.7761 14 16.5V9.5ZM9.5 9C9.22386 9 9 9.22386 9 9.5V16.5C9 16.7761 9.22386 17 9.5 17C9.77614 17 10 16.7761 10 16.5V9.5C10 9.22386 9.77614 9 9.5 9Z"
        fill="#EB8176"
      />
    </svg>
  );
};

export default DeleteIcon;
