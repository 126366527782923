import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";

const CustomCard = ({ IconComponent, title, link }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "24px",
        cursor: "pointer",
        p: 3,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
	  onClick={() => {
                          navigate(link);
                        }}
    >
      <Box sx={{ marginRight: 2 }}>
        <Box
          bgcolor="rgba(248, 248, 248, 1)"
          borderRadius={100}
          maxWidth="64px"
          width="64px"
          height="64px"
          display="flex"
          justifyContent="center"
          alignItems="center">
          {IconComponent ? (
            <IconComponent sx={{ color: "#2B3674", fontSize: 40 }} />
          ) : null}
        </Box>
      </Box>
        <Box sx={{
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Typography
            variant="body3"
            component="div"
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "22.68px",
            }}
          >
            {ReactHtmlParser(title)}
          </Typography>
        </Box>
    </Card>
  );
};

export default CustomCard;
