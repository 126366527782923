import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2Outlined";

export const ContactCard = ({
  icon,
  title,
  info,
  linkTo,
  contactStatus,
  contactStatusText,
}) => {
  return (
    <Card
      onClick={linkTo}
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Box>
                <Person2Icon
                  sx={{
                    color: "primary.main",
                    width: "32px",
                    height: "32px",
                  }}
                />
              </Box>

              <Stack>
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {title}{" "}
					{contactStatus && (
				  <Box
                    sx={{
                      borderRadius: "3px",
                      display: "inline",
                      /*backgroundColor:
					    contactStatus == "active"
                          ? "#00A86B"
                          : contactStatus == "inactive"
                          ? "#FF4500"
                          : "#FFD800",*/
                      ml: "1rem",
                      p: "3px",
                      pl: "6px",
                      pr: "6px",
                      fontWeight: 400,
                      color: "primary.light",
                    }}
                  >
				    
					{contactStatusText}
					  </Box>)}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 400,
                    color: "primary.main",
                  }}
                >
                  {info}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              sx={{ minWidth: "44px", width: "44px !important", height: "44px" }}
            >
              <svg
                width="10"
                height="20"
                viewBox="0 0 10 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.83062 9.99998L0.297917 2.46728C0.0992002 2.26856 0.00240021 2.03203 0.00751688 1.75768C0.0126502 1.48331 0.114575 1.24678 0.313292 1.04808C0.512008 0.849359 0.748542 0.75 1.02289 0.75C1.29724 0.75 1.53378 0.849359 1.73249 1.04808L9.38827 8.71923C9.56903 8.89999 9.70301 9.10255 9.79019 9.3269C9.87736 9.55127 9.92094 9.77563 9.92094 9.99998C9.92094 10.2243 9.87736 10.4487 9.79019 10.6731C9.70301 10.8974 9.56903 11.1 9.38827 11.2807L1.71712 18.9519C1.5184 19.1506 1.28442 19.2474 1.01519 19.2423C0.745975 19.2371 0.512008 19.1352 0.313292 18.9365C0.114575 18.7378 0.0152165 18.5013 0.0152165 18.2269C0.0152165 17.9526 0.114575 17.716 0.313292 17.5173L7.83062 9.99998Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
