import AuthNavigator from "../AuthNavigator/AuthNavigator";
import React from "react";
import { ThemeProvider } from "@mui/material";
import { CustomTheme } from "./Theme";
import "./App.css";

const loadCookiebotScript = () => {
  const script = document.createElement("script");
  script.id = "Cookiebot";
  script.src = "https://consent.cookiebot.com/uc.js";
  script.setAttribute("data-cbid", process.env.REACT_APP_COOKIEBOT_CBID);
  script.setAttribute("data-blockingmode", process.env.REACT_APP_COOKIEBOT_BLOCKINGMODE);
  script.type = "text/javascript";
  script.async = true;
  document.head.appendChild(script);
};

class App extends React.Component {

  componentDidMount() {

	document.title = "B120 - The Life Tresor";

    const handleCookieConsent = () => {
      if (window.Cookiebot) {
        const consentGiven = window.Cookiebot.consents.given;
        if (consentGiven) {
          console.log('User has given consent:', window.Cookiebot.consents);
        } else {
          console.log('User has not given consent');
        }
      }
    };

    document.addEventListener('CookieConsentDeclaration', handleCookieConsent);

    loadCookiebotScript();

    const gaScript = document.createElement("script");
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
    document.head.appendChild(gaScript);

    //Google Analytics.
    gaScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_GA_TRACKING_ID);
    };

    //Hotjar
    const hotjarScript = document.createElement('script');
    hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR_ID},hjsv:${process.env.REACT_APP_HOTJAR_SV}};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(hotjarScript);

    //google login
    const gscript = document.createElement("script");
    gscript.src = "https://accounts.google.com/gsi/client";
    gscript.async = true;
    document.body.appendChild(gscript);
    gscript.onload = (evt) => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => {
          localStorage.setItem("jwt", response.credential);

		  let params = new URL(document.location.toString()).searchParams;

          let xhr = new XMLHttpRequest();

		  let json;

		  if (params.has("invitationToken")) {
          json = JSON.stringify({
            jwt: response.credential,	
			invitationToken: params.get("invitationToken")			
          });			  
		  } else {
          json = JSON.stringify({
            jwt: response.credential,		
          });
		  }

          let jsonResponse;

          xhr.onloadend = function () {
            if (xhr.status === 200) {
              console.log("google success - local");
              console.log(xhr.response);
              jsonResponse = JSON.parse(xhr.response);
              localStorage.setItem("token", jsonResponse.authorisation.token);
              document.location.href = "/";
            }
            if (xhr.status === 201) {
              console.log("google success - remote");
              console.log(xhr.response);
              jsonResponse = JSON.parse(xhr.response);
              localStorage.setItem("token", jsonResponse.token);
              document.location.href = "/";
            } else {
              console.log("google error " + this.status);
            }
          };



          xhr.open("POST", process.env.REACT_APP_BASE_URL + "/tokens/google");
          xhr.setRequestHeader(
            "Content-type",
            "application/json; charset=utf-8"
          );
          xhr.send(json);
        },
      });
    };

    //apple login
    const ascript = document.createElement("script");
    ascript.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    ascript.async = true;
    document.body.appendChild(ascript);
    ascript.onload = (evt) => {
      window.AppleID.auth.init({
        clientId: "hu.wst.b120.dev",
        scope: "name email",
        redirectURI: window.location.origin,
        state: "SignInUserAuthenticationRequest",
        nonce: String(Date.now()),
        usePopup: true,
      });
    };
  }

  componentWillUnmount() {
    document.removeEventListener('CookieConsentDeclaration', this.handleCookieConsent);
  }

  render() {
    return (
      <>
        <ThemeProvider theme={CustomTheme}>
          <AuthNavigator />
        </ThemeProvider>
      </>
    );
  }
}

export default App;
