const ShareIcon = () => (
                  <svg
                    width="38"
                    height="48"
                    viewBox="0 0 38 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
				   <g opacity="0.6">                   

				   <path
                      d="M3.6154 35C2.60513 35 1.75 34.65 1.05 33.95C0.35 33.25 0 32.3949 0 31.3846V10.6155C0 9.60528 0.35 8.75014 1.05 8.05014C1.75 7.35014 2.60513 7.00014 3.6154 7.00014H12V3.61559C12 2.60536 12.35 1.75024 13.05 1.05024C13.75 0.350244 14.6051 0.000244141 15.6154 0.000244141H22.3845C23.3948 0.000244141 24.2499 0.350244 24.9499 1.05024C25.6499 1.75024 25.9999 2.60536 25.9999 3.61559V7.00014H34.3845C35.3948 7.00014 36.2499 7.35014 36.9499 8.05014C37.6499 8.75014 37.9999 9.60528 37.9999 10.6155V31.3846C37.9999 32.3949 37.6499 33.25 36.9499 33.95C36.2499 34.65 35.3948 35 34.3845 35H3.6154ZM15 7.00014H23V3.61559C23 3.46173 22.9359 3.3207 22.8077 3.1925C22.6794 3.06426 22.5384 3.00014 22.3845 3.00014H15.6154C15.4615 3.00014 15.3205 3.06426 15.1923 3.1925C15.0641 3.3207 15 3.46173 15 3.61559V7.00014ZM35 23.5H23.9999V25.1923C23.9999 25.7077 23.8275 26.1378 23.4826 26.4827C23.1377 26.8276 22.7076 27 22.1922 27H15.8077C15.2923 27 14.8622 26.8276 14.5174 26.4827C14.1725 26.1378 14 25.7077 14 25.1923V23.5H2.99995V31.3846C2.99995 31.5385 3.06405 31.6796 3.19225 31.8078C3.32048 31.936 3.46153 32.0001 3.6154 32.0001H34.3845C34.5384 32.0001 34.6794 31.936 34.8077 31.8078C34.9359 31.6796 35 31.5385 35 31.3846V23.5ZM17 24.0001H21V20.0001H17V24.0001ZM2.99995 20.5001H14V18.8078C14 18.2925 14.1725 17.8624 14.5174 17.5175C14.8622 17.1726 15.2923 17.0001 15.8077 17.0001H22.1922C22.7076 17.0001 23.1377 17.1726 23.4826 17.5175C23.8275 17.8624 23.9999 18.2925 23.9999 18.8078V20.5001H35V10.6155C35 10.4617 34.9359 10.3206 34.8077 10.1924C34.6794 10.0642 34.5384 10.0001 34.3845 10.0001H3.6154C3.46153 10.0001 3.32048 10.0642 3.19225 10.1924C3.06405 10.3206 2.99995 10.4617 2.99995 10.6155V20.5001Z"
                      fill="#2B3674"
                    />
					</g>
                  </svg>
);
export default ShareIcon;
