const countries = [
  { name: "Ausztria", code: "AT" },
  { name: "Belgium", code: "BE" },
  { name: "Bulgária", code: "BG" },
  { name: "Ciprusi Köztársaság", code: "CY" },
  { name: "Csehország", code: "CZ" },
  { name: "Dánia", code: "DK" },
  { name: "Észtország", code: "EE" },
  { name: "Finnország", code: "FI" },
  { name: "Franciaország", code: "FR" },
  { name: "Görögország", code: "GR" },
  { name: "Hollandia", code: "NL" },
  { name: "Horvátország", code: "HR" },
  { name: "Írország", code: "IE" },
  { name: "Lengyelország", code: "PL" },
  { name: "Lettország", code: "LV" },
  { name: "Litvánia", code: "LT" },
  { name: "Luxemburg", code: "LU" },
  { name: "Magyarország", code: "HU", default: true },
  { name: "Málta", code: "MT" },
  { name: "Németország", code: "DE" },
  { name: "Olaszország", code: "IT" },
  { name: "Portugália", code: "PT" },
  { name: "Románia", code: "RO" },
  { name: "Spanyolország", code: "ES" },
  { name: "Svédország", code: "SE" },
  { name: "Szlovákia", code: "SK" },
  { name: "Szlovénia", code: "SI" },
  { name: "Izland", code: "IS" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Norvégia", code: "NO" },
  { name: "Svájc", code: "CH" },
];

export const loadCountries = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_COUNTRIES_REQUEST" });
    try {
      dispatch({ type: "FETCH_COUNTRIES_SUCCESS", payload: countries });
    } catch (error) {
      dispatch({ type: "FETCH_COUNTRIES_FAILURE", payload: error });
    }
  };
};
