import {
  Typography,
  Box,
  Stack,
  Card,
  CardContent,
  Button,
  Chip,
  Link,
  ImageList,
  ImageListItem,
  Modal,
  IconButton,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { t } from "i18next";
import GamificationEntryForm from "./GamificationEntryForm";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEntry,
  editEntry,
} from "../../store/Actions/timeCapsuleEntriesActions";
import { deleteEntry as deletePostMortemEntry } from "../../store/Actions/postMortemActions";
import PostMortemEntryForm from "./PostMortemEntryForm";
import { formatDate } from "../../store/helper";
import CloseIcon from "@mui/icons-material/Close";
import { resetFileState } from "../../store/Actions/fileActions";
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";

const Entry = ({ entryData, entryType, displayMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [editing, setEditing] = useState(false);
  const { images } = useSelector((state) => state.timeCapsule);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const entryImages = images?.filter(
    (image) => image.diaryItemId === entryData.id
  );

  const handleClick = (event) => {
    if (
      event.currentTarget &&
      event.currentTarget.offsetWidth !== 0 &&
      event.currentTarget.offsetHeight !== 0
    ) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (id) => {
    dispatch(resetFileState());
    setAnchorEl(null);
    dispatch(editEntry(id));
    if (entryImages.length > 0) {
      dispatch({ type: "SET_UPLOADED_FILES", payload: entryImages });
    }
    setEditing(true);
  };

  const onEditCancel = () => {
    setEditing(false);
  };

  const onUpdate = () => {
    setEditing(false);
  };

  const handleDelete = (id) => {
    if (entryType === "postMortem") {
      dispatch(deletePostMortemEntry(id));
    } else {
      dispatch(deleteEntry(id));
    }
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedImageIndex(0);
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? entryImages.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === entryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleConfirmDelete = () => {
    handleDelete(entryData.id);
    setDeleteConfirmation(false);
  };

  if (!editing) {
    return (
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          my: 4,
          boxShadow: "4px 4px 20px 0px #0000001A",
        }}
      >
        <CardContent>
          <Stack direction="row">
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: "rgba(43, 54, 116, 0.6)",
              }}
            >
              <CalendarMonthOutlinedIcon />
              {entryData.date
                ? formatDate(entryData.date)
                : formatDate(entryData.createdAt)}
            </Typography>

            {entryData.location && (
              <Typography
                variant="p"
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "rgba(43, 54, 116, 0.6)",
                }}
              >
                <LocationOnOutlinedIcon />
                {entryData.location}
              </Typography>
            )}

            <Box sx={{ flexGrow: 1 }} />

            {entryType !== "postMortem" && (
            <Chip
              label={
                entryData.topic
                  ? entryData.topic
                  : t("timeCapsule.entry.digitalMemory")
              }
              sx={{
                color: "rgba(43, 54, 116, 0.6)",
                backgroundColor: entryData.topic
                  ? "rgba(255, 242, 126, 0.25)"
                  : "#62C27533",
                fontWeight: 700,
                fontFamily: "Sora, sans-serif",
                boxShadow: "10px",
				display: entryType != "postMortem" ? 'auto' : 'none'
              }}
            />)}

            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  position: "relative",
                  right: "-5px",
                  top: "-8px",
				  display: displayMenu ? 'auto' : 'none'
                }}
              >
                <MoreVertOutlinedIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
			  >
                <MenuItem onClick={() => handleEdit(entryData.id)}>
                  <EditOutlinedIcon />
                  {t("timeCapsule.entry.edit")}
                </MenuItem>
                <MenuItem onClick={() => setDeleteConfirmation(true)}>
                  <DeleteOutlineOutlinedIcon />
                  {t("timeCapsule.entry.delete")}
                </MenuItem>
              </Menu>
            </div>
          </Stack>

          <Link
            sx={{
              textDecoration: "none",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                color: "#1542B9",
              }}
            >
              {entryData.title} 
            </Typography>
          </Link>

          {entryImages?.length > 0 && (
            <ImageListItem onClick={() => handleImageClick(0)}>
              <img
                src={`data:${entryImages[0].mimeType};base64,${entryImages[0].base64}`}
                alt={entryImages[0].originalName}
                loading="lazy"
                style={{
                  cursor: "pointer",
                  maxWidth: "100%",
                  borderRadius: "16px",
                  maxHeight: "310px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              />
            </ImageListItem>
          )}

          <Typography
            variant="body3"
            sx={{
              fontSize: "14px",
            }}
          >
            {entryData.text}
          </Typography>
        </CardContent>

        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
            {entryImages?.length > 0 && (
              <>
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: 8,
                    transform: "translateY(-50%)",
                  }}
                  onClick={handlePrevImage}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
                <img
                  src={`data:${entryImages[selectedImageIndex].mimeType};base64,${entryImages[selectedImageIndex].base64}`}
                  alt={entryImages[selectedImageIndex].originalName}
                  style={{ maxWidth: "100%", maxHeight: "80vh" }}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                  }}
                  onClick={handleNextImage}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Modal>

        <ConfirmationModal
          open={deleteConfirmation}
          onClose={() => setDeleteConfirmation(false)}
          title={t("deleteEntry.title")}
          description={t("deleteEntry.text")}
          cancelText={t("deleteEntry.cancelBtn")}
          confirmText={t("deleteEntry.confirmBtn")}
          onConfirm={handleConfirmDelete}
          icon={<HandGestureIcon />}
        />
      </Card>
    );
  } else {
    if (entryType === "timeCapsule") {
      return (
        <GamificationEntryForm
          isNewEntry={false}
          entryData={entryData}
          onCancel={() => onEditCancel()}
          onSubmitSuccess={() => onUpdate()}
        />
      );
    } else {
      return (
        <PostMortemEntryForm
          isNewEntry={false}
          entryData={entryData}
          onCancel={() => onEditCancel()}
          onSubmitSuccess={() => onUpdate()}
        />
      );
    }
  }
};

export default Entry;
