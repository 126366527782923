import { Grid, Typography } from "@mui/material";
import DefaultLayout from "../Layout/DefaultLayout";
import { t } from "i18next";

const NoAccess = ({ globalEvent, setGlobalEvent, title }) => {
  return (
    <>
      <DefaultLayout
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
        title={title}
      >
        <Grid
          sx={{
            mt: { xs: 0, sm: 8 },
            mb: 20,
            textAlign: "center",
            justifyContent: "center",
          }}
          container
        >
          <Typography>{t("noaccess")}</Typography>
        </Grid>
      </DefaultLayout>
    </>
  );
};

export default NoAccess;
