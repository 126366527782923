import { Box, Grid } from "@mui/material";
import { LoginTopMenu } from "../Navigation/LoginNavigation/LoginTopMenu";
import { Footer } from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

export const ForgotPassword = ({ globalEvent, setGlobalEvent, background }) => {
  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia("(max-width: 900px)");
      setMobileView(mql.matches);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid container sx={{}}>
        <Grid item xs={12} md={6}>
          <LoginTopMenu />
          <div className="login-container">
            <Outlet />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {!mobileView ? (
            <Box
              component="img"
              alt="background"
              src={background}
              sx={{
                top: 0,
                width: "100%",
                height: "100%",
              }}
            ></Box>
          ) : (
            <Footer />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
