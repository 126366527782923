import React, { useEffect, useReducer, useState, Suspense } from "react";
import { Typography, Grid, Box, Stack } from "@mui/material";
import { ProfileInfoCard } from "./ProfileInfoCard";
import { PersonalDataCard } from "./PersonalDataCard";
import { SubscriptionDataCard } from "./SubscriptionDataCard";
import { DeleteProfileCard } from "./DeleteProfileCard";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import SocialChannelsCard from "./SocialChannelsCard";
import { PromotionCodeCard } from "./PromotionCodeCard";
import { NewsletterCard } from "./NewsletterCard";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import DefaultLayout from "../Layout/DefaultLayout";
import { t } from "i18next";
import { BillingDetailsCard } from "./BillingDetails/BillingDetailsCard";
import BannerDisplayer from "../Banner/BannerDisplayer";
import TwoFactorAuth from "./TwoFactorAuth";
import { CurrencyCard } from "./CurrencyCard";
import { LocaleCard } from "./LocaleCard";
import { LifeSignalCard } from "./LifeSignalCard";

const ProfilePage = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState();
  const [userError, setUserError] = useState(false);

  useEffect(() => {
    if (!userError) setGlobalEvent({ type: "doGetUser", data: {} });
  }, []);

  useEffect(() => {
    if (!userError) setGlobalEvent({ type: "doGetUser", data: {} });
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ Landing");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseSocialDisconnect") {
      setGlobalEvent({ type: "doGetUser", data: {} });
    }

    if (globalEvent?.type == "responseGetUser") {
      setUser(globalEvent?.data.user);
      console.log(globalEvent?.data.user);

      if (!!globalEvent?.data.user.emailVerified) {
        setVerified(true);
      }
      if (!!globalEvent?.data.user.email_verified_at) {
        setVerified(true);
      }

      setChecked(true);
    }

    if (globalEvent?.type == "responseGetUserError") {
      localStorage.removeItem("token");
      //window.token = null;
      setUserError(true);
      setGlobalEvent({ type: "goHome", data: {} });
    }
  }, [globalEvent]);

  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        <BannerDisplayer position={"top"} page={"profile"} />
        <Box className="my-container">
          <Grid
            sx={{
              mt: { xs: 0, sm: 8 },
              mb: 20,
              minHeight: "100vh",
              textAlign: "left",
            }}
            container
          >
            <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                sx={{ mb: 6 }}
              >
                {/*<BackButton linkTo="/dashboard" />*/}
                <Typography variant="h6">{t("profile.title")}</Typography>
              </Stack>
              <ProfileInfoCard
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <PersonalDataCard
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <LocaleCard
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <CurrencyCard
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <LifeSignalCard
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <SubscriptionDataCard />
              <BillingDetailsCard />
              <PromotionCodeCard
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <SocialChannelsCard
                user={user}
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
              <NewsletterCard />
              <TwoFactorAuth />
              <DeleteProfileCard />
              <BannerDisplayer position={"bottom"} page={"profile"} />
            </Grid>
            <Grid item xs={12} lg={5} sx={{ pl: { xs: 0, sm: 6 }, mt: 11 }}>
              <Stack direction={"column"} spacing={2} alignItems={"left"}>
                <HelpCard />
                <ReferralCard />
                <BannerDisplayer position={"right"} page={"profile"} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DefaultLayout>
    </>
  );
};

export default ProfilePage;
