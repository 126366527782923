import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  TextField,
  Link,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { t } from "i18next";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { updateUserMarketingPreference } from "../../store/Actions/userAction";

export const NewsletterCard = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);

  const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);

  useEffect(() => {
    if (user?.emailMarketing !== undefined) {
      setNewsletterSubscribed(user.emailMarketing);
    }
  }, [user]);

  const handleNewsletterSubscribtion = () => {
    dispatch(updateUserMarketingPreference(true));
    setNewsletterSubscribed(!newsletterSubscribed);
  };

  const handleNewsletterUnubscribtion = () => {
    dispatch(updateUserMarketingPreference(false));
    setNewsletterSubscribed(!newsletterSubscribed);
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                }}
              >
                {t("profile.newsletter.title")}
              </Typography>

              <Box sx={{}}>
                <Typography variant="body2">
                  {t("profile.newsletter.description")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: { md: "end" },
              alignItems: "center",
            }}
          >
            {!newsletterSubscribed && (
              <Button
                className="green-text-btn"
                onClick={handleNewsletterSubscribtion}
                sx={{
                  color: "socialLink.main",
                  fontSize: "12px",
                  width: "fit-content!important",
                  pl: 0,
                }}
              >
                {t("profile.newsletter.subscribeTxt")}
              </Button>
            )}
            {newsletterSubscribed && (
              <Button
                onClick={handleNewsletterUnubscribtion}
                sx={{
                  color: "info.secondary",
                  fontSize: "12px",
                  width: "fit-content!important",
                  pl: 0,
                }}
              >
                {t("profile.newsletter.unsubscribeTxt")}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NewsletterCard;
