import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSystemMessages,
  dismissMessage,
} from "../../store/Slices/systemMessageSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

const SystemMessages = (type = "home") => {
  const dispatch = useDispatch();
  const { messages, status, error } = useSelector(
    (state) => state.systemMessages
  );

  useEffect(() => {
    dispatch(fetchSystemMessages());
  }, [dispatch]);

  const handleDismiss = (id) => {
    dispatch(dismissMessage(id));
    dispatch(fetchSystemMessages());
  };

  if (type === "home") {
    return (
      <Box sx={{ width: "100%" }}>
        {messages.map((message) => (
          <Paper
            key={message.id}
            sx={{
              backgroundColor: "#e0f7fa",
              mb: 0,
              borderBottom: "1px solid rgba(43, 54, 116, .2)",
              width: "100%",
              position: "relative",
              zIndex: 2,
              boxShadow: "none",
            }}
          >
            <Container
              maxWidth="lg"
              sx={{ display: "flex", alignItems: "center", p: 2 }}
            >
              <InfoOutlinedIcon sx={{ color: "#2B3674", mr: 1 }} />
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {message.messageText}
              </Typography>
              <IconButton
                onClick={() => handleDismiss(message.id)}
                sx={{ color: "#2B3674" }}
              >
                <CloseIcon />
              </IconButton>
            </Container>
          </Paper>
        ))}
      </Box>
    );
  } else {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          {messages.map((message) => (
            <Paper
              key={message.id}
              sx={{
                backgroundColor: "#fff",
                mb: 2,
                width: "100%",
                borderRadius: "24px",
                boxShadow: "none",
                border: '1px solid rgba(43, 54, 116, 0.1)'
              }}
            >
                <Grid container alignItems="center" spacing={2} sx={{ p: 2 }}>
                  <Grid item>
                    <Box
                      sx={{
                        backgroundColor: "#FFF27E",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "64px",
                        height: "64px",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ color: "#2B3674", fontSize: "32px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 18 }}>
                      {message.title}
                    </Typography>
                    <Typography variant="body2" sx={{fontSize: 16}}>
                      {message.messageText}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => handleDismiss(message.id)}
                      sx={{ color: "#333" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
            </Paper>
          ))}
        </Box>
      </>
    );
  }
};

export default SystemMessages;
