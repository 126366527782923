import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import { DeleteModal } from "./DeleteModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DeleteButton = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
		onClick={(e) => {
          navigate("/deleteuser");
        }}
        variant="text"
        sx={{
          minWidth: "50px",
          width: "50px",
          height: "50px",
          p: 0,
          boxShadow: "0px 0px 10px 0.2px #0000001A",
          borderRadius: "32px",
        }}
      >
        <DeleteIcon sx={{ color: "info.secondary" }} />
      </Button>
    </>
  );
};

export default DeleteButton;
