const initialState = {
  items: [],
  loading: false,
  error: null,
  newEntry: {
    id: null,
    title: "",
    content: "",
    contact_id: null,
  },
};

const postMortemEntriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PM_ENTRIES_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_PM_ENTRIES_SUCCESS":
      return { ...state, loading: false, items: action.payload };
    case "FETCH_PM_ENTRIES_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "SET_PM_ENTRY_TITLE":
      return {
        ...state,
        newEntry: {
          ...state.newEntry,
          title: action.payload,
        },
      };
    case "SET_PM_ENTRY_CONTENT":
      return {
        ...state,
        newEntry: {
          ...state.newEntry,
          content: action.payload,
        },
      };
    case "RESET_PM_ENTRY":
      return {
        ...state,
        newEntry: initialState.newEntry,
      };

    case "EDIT_PM_ENTRY":
      const entry = state.items.find((row) => row.id === action.payload);

      if (entry) {
        return {
          ...state,
          newEntry: entry,
        };
      }

      return state;

    case "DELETE_PM_ENTRY":
      const index = state.items.findIndex((row) => row.id === action.payload);
      let stateItems = [...state.items];

      if (index !== -1) {
        stateItems.splice(index, 1);
        return { ...state, loading: false, items: stateItems };
      }

      return state;
    default:
      return state;
  }
};

export default postMortemEntriesReducer;
