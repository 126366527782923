import React, { useEffect, useReducer, useState } from "react";

import { useNavigate } from "react-router-dom";
import authHeader from "./AuthHeader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAssets } from "../../store/Actions/assetAction";
import { fetchContacts } from "../../store/Actions/contactAction";
import { showSnackbar } from "../../store/Slices/snackbarSlice";

const DataModule = ({ globalEvent, setGlobalEvent }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(i18n.language);

  const [user, setUser] = useState();
  const [hats, setHats] = useState();

  const [contacts, setContacts] = useState();

  const [sums, setSums] = useState();

  const [regData, setRegData] = useState();
  const [regInvitedToken, setRegInvitedToken] = useState();
  const [regReferralToken, setRegReferralToken] = useState();
  //const [regPromoCode, setRegPromoCode] = useState(null);

  const [userData, setUserData] = useState();
  const [passwordData, setPasswordData] = useState();
  const [loginData, setLoginData] = useState();
  const [delData, setDelData] = useState();
  const [emailData, setEmailData] = useState();
  const [fileData, setFileData] = useState();

  const { plans } = useSelector((state) => state.plan);
  const { postMortem } = useSelector((state) => state.postMortem);
  const { items, question, loading, err } = useSelector(
    (state) => state.timeCapsule
  );

  const { regConsentToMarketing } = useSelector((state) => state.user);

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const callAxiosFile = (type, url, data, responseCode) => {
    let axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        ...{ "Content-Type": "multipart/form-data", locale: language },
        ...authHeader(),
      },
    });

    axiosInstance
      .post(url, data)
      .then((res) => {
        setGlobalEvent({ type: responseCode, data: res.data });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          let resData = {};
          if (error.response.data) {
            resData = error.response.data;
            resData["postData"] = data;
          } else {
            resData["postData"] = data;
          }
          resData["toastType"] = "error";
          setGlobalEvent({ type: responseCode + "Error", data: resData });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        //console.log(error.config);
      });
  };

  const callAxios = (type, url, data, responseCode) => {
    let axiosinst = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: authHeader(),
      headers: {
        ...{ locale: language },
        ...authHeader(),
      },
    });

    const interceptor = axiosinst.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const errors = error.response.data.errors;
          if (errors.some((err) => err.message === "plan_limits_reached")) {
            navigate("/subscription");
          }
          if (errors.some((err) => err.message === "invalid_email")) {
            dispatch(
              showSnackbar({
                message: t("invalid_response.invalid_email"),
                severity: "error",
              })
            );
          }
        }
        return Promise.reject(error);
      }
      /*
		error => { 
			if (error.response && error.response.status === 401) {
				//navigate('/login');
			  }
			  return Promise.reject(error);
		}
    */
    );

    if (type == "get") {
      axiosinst
        .get(url, data)
        .then((res) => {
          setGlobalEvent({ type: responseCode, data: res.data });
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //error.response.data["toastType"] = 'error';
            console.log("*******************");
            console.log(error.response.data);
            switch (error.response.status) {
              case 304:
                setGlobalEvent({
                  type: responseCode,
                  data: error.response.data,
                });
                break;
              default:
                let resData = {};
                resData = error.response.data;

                if (resData instanceof Object) {
                  resData["postData"] = data;
                } else {
                  resData = {};
                  resData["message"] = error.response.data;
                  resData["postData"] = data;
                }

                resData["toastType"] = "error";

                setGlobalEvent({
                  type: responseCode + "Error",
                  data: resData,
                });
                break;
            }
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    } else if (type == "patch") {
      axiosinst
        .patch(url, data)
        .then((res) => {
          setGlobalEvent({ type: responseCode, data: res.data });
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            error.response.data["toastType"] = "error";
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            let resData = {};
            if (error.response.data) {
              resData = error.response.data;
              resData["postData"] = data;
            } else {
              resData["postData"] = data;
            }
            resData["toastType"] = "error";
            setGlobalEvent({ type: responseCode + "Error", data: resData });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    } else if (type == "delete") {
      axiosinst
        .delete(url, data)
        .then((res) => {
          setGlobalEvent({ type: responseCode, data: res.data });
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            error.response.data["toastType"] = "error";
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            let resData = {};
            if (error.response.data) {
              resData = error.response.data;
              resData["postData"] = data;
            } else {
              resData["postData"] = data;
            }
            setGlobalEvent({ type: responseCode + "Error", data: resData });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    } else {
      axiosinst
        .post(url, data)
        .then((res) => {
          setGlobalEvent({ type: responseCode, data: res.data });
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            let resData = {};
            if (error.response.data) {
              //error.response.data["toastType"] = 'error';
              console.log("*******************");
              console.log(error.response.data);

              resData = error.response.data;
              if (resData instanceof Object) {
                resData["postData"] = data;
              } else {
                resData = {};
                resData["message"] = error.response.data;
                resData["postData"] = data;
              }
              resData["toastType"] = "error";
            } else {
              resData["toastType"] = "error";
              resData["postData"] = data;
            }
            setGlobalEvent({ type: responseCode + "Error", data: resData });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }

    return () => {
      axiosinst.interceptors.response.eject(interceptor);
    };
  };

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ DataModule");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "checkPlan") {
      let currentPlan;
      plans.forEach((plan) => {
        if (plan.name == user.subscription.planName)
          return (currentPlan = plan);
      });
      console.log(currentPlan);

      if (globalEvent?.data.type == "asset") {
        if (
          sums?.assets?.length < currentPlan?.limits?.assets.count &&
          currentPlan?.limits?.assets.assetTypes.indexOf(
            globalEvent?.data.subtype
          ) !== -1
        ) {
          setGlobalEvent({
            type: "responseCheckPlan",
            data: { link: globalEvent?.data.link },
          });
        } else {
          setGlobalEvent({ type: "responseCheckPlanError", data: {} });
        }
      }

      if (globalEvent?.data.type == "image") {
        if (
          globalEvent?.data.fileCount <
          currentPlan?.limits?.attachments.images.countPerAsset
        ) {
          setGlobalEvent({
            type: "responseCheckPlan",
            data: globalEvent?.data,
          });
        } else {
          setGlobalEvent({ type: "responseCheckPlanError", data: {} });
        }
      }

      if (globalEvent?.data.type == "document") {
        if (
          globalEvent?.data.fileCount <
          currentPlan?.limits?.attachments.documents.countPerAsset
        ) {
          setGlobalEvent({
            type: "responseCheckPlan",
            data: globalEvent?.data,
          });
        } else {
          setGlobalEvent({ type: "responseCheckPlanError", data: {} });
        }
      }

      if (globalEvent?.data.type == "postMortem") {
        if (
          (postMortem ? postMortem.length : 0) <
          currentPlan?.limits?.postMortemRequests.count
        ) {
          setGlobalEvent({
            type: "responseCheckPlan",
            data: globalEvent?.data,
          });
        } else {
          setGlobalEvent({ type: "responseCheckPlanError", data: {} });
        }
      }

      if (globalEvent?.data.type == "diaryItems") {
        console.log(items);
        if (
          (items ? items.length : 0) < currentPlan?.limits?.diaryItems.count
        ) {
          setGlobalEvent({
            type: "responseCheckPlan",
            data: globalEvent?.data,
          });
        } else {
          setGlobalEvent({ type: "responseCheckPlanError", data: {} });
        }
      }
    }

    if (globalEvent?.type == "responseCheckPlanError") {
      setGlobalEvent({
        type: "showPlanUpgrade",
        data: {},
      });
    }

    if (globalEvent?.type == "goHome") {
      navigate("/");
    }

    //ping
    if (globalEvent?.type == "doPing") {
      callAxios("get", "/ping", globalEvent?.data, "responsePing");
      //axios.get(process.env.REACT_APP_BASE_URL+'/ping',{headers: authHeader()}).then(res => {setGlobalEvent({type:'showToast',data:{message:res.data}})})
    }

    if (globalEvent?.type == "responsePing") {
      if (globalEvent?.data.hasOwnProperty("message")) {
        setGlobalEvent({ type: "showToast", data: globalEvent?.data });
      } else {
        setGlobalEvent({
          type: "showToast",
          data: { message: globalEvent?.data },
        });
      }
    }

    if (globalEvent?.type == "responsePingError") {
      if (globalEvent?.data.hasOwnProperty("message")) {
        setGlobalEvent({ type: "showToast", data: globalEvent?.data });
      } else {
        setGlobalEvent({
          type: "showToast",
          data: { message: globalEvent?.data, toastType: "error" },
        });
      }
    }

    //register

    if (globalEvent?.type == "doRegister") {
      let sendData = globalEvent?.data;

      delete sendData.confirm_password;

      console.log("regConsentToMarketing", regConsentToMarketing);
      //sendData["locale"] = language;

      sendData["emailMarketing"] = false;

      if (regInvitedToken) {
        sendData["invitationToken"] = regInvitedToken;
      }

      if (regReferralToken) {
        sendData["referralToken"] = regReferralToken;
      }

      if (regConsentToMarketing) {
        sendData["emailMarketing"] = regConsentToMarketing;
      }

      if (sendData.promoCode === "") {
        sendData["couponCode"] = sendData.promoCode;
      }

      console.log("*********** RegData before delete *************");
      console.log(sendData);
      console.log("************************");

      //delete sendData.invitedToken;
      //delete sendData.referralToken;
      if (sendData.promoCode == "") delete sendData.promoCode;
      //delete sendData.consentToMarketing;

      setRegData(sendData);
      setGlobalEvent({ type: "doRegisterConsent", data: sendData });

      //navigate("/registration/consent");

      //callAxios('post',process.env.REACT_APP_REGISTER_URL,sendData,'responseRegister')
    }

    if (globalEvent?.type == "responseRegister") {
      if (globalEvent?.data.hasOwnProperty("token")) {
        localStorage.setItem("token", globalEvent?.data.token);
        //window.token = globalEvent?.data.token;
        if (globalEvent?.data.hasOwnProperty("currentHatId")) {
          localStorage.setItem("chid", globalEvent?.data.currentHatId);
        } else {
          localStorage.setItem("chid", null);
        }
        //navigate("/registration/end", { replace: true });
        navigate("/", { replace: true });
      } else if (globalEvent?.data.hasOwnProperty("authorisation")) {
        if (globalEvent?.data.authorisation.hasOwnProperty("token")) {
          console.log(globalEvent?.data.authorisation.token);
          localStorage.setItem("token", globalEvent?.data.authorisation.token);
          //window.token = globalEvent?.data.authorisation.token;
          //navigate("/registration/end", { replace: true });
          navigate("/", { replace: true });
        } else {
          setGlobalEvent({
            type: "showToast",
            data: { message: "Unexpected Error", toastType: "error" },
          });
        }
      } else {
        setGlobalEvent({
          type: "showToast",
          data: { message: "Unexpected Error", toastType: "error" },
        });
      }
    }

    if (globalEvent?.type == "doRegisterVerify") {
      navigate("/registration/verify", { replace: true });
    }

    if (globalEvent?.type == "doRegisterConsent") {
      callAxios(
        "post",
        process.env.REACT_APP_REGISTER_URL,
        regData,
        "responseRegister"
      );
    }

    if (globalEvent?.type == "setInvitedToken") {
      setRegInvitedToken(globalEvent?.data.invitedToken);
    }

    if (globalEvent?.type == "setReferralToken") {
      setRegReferralToken(globalEvent?.data.referralToken);
    }

    //if (globalEvent?.type == 'setPromoCode'){
    //	setRegPromoCode(globalEvent?.data.promoCode);
    //}

    //emailconfirm

    if (globalEvent?.type == "doEmailConfirm") {
      let sendData = globalEvent?.data;

      callAxios(
        "post",
        "/users/current/email-verification",
        sendData,
        "responseEmailConfirm"
      );
    }

    //loginconfirm

    if (globalEvent?.type == "doLoginConfirm") {
      let sendData = loginData;
      sendData["twoFactorCode"] = globalEvent?.data.verificationCode;
      callAxios("post", "/tokens", sendData, "responseLogin");
    }

    //changeemail

    if (globalEvent?.type == "doChangeEmailStart") {
      setEmailData({ user: globalEvent?.data });
      callAxios(
        "post",
        "/users/current/email-verification-code",
        globalEvent?.data,
        "responseChangeEmailStart"
      );
    }

    if (globalEvent?.type == "doChangeEmailSave") {
      let sendData = emailData;
      sendData["user"]["emailVerificationCode"] =
        globalEvent?.data.verificationCode;

      callAxios("patch", "/users/current", sendData, "responseChangeEmailSave");
    }

    //deleteuser

    if (globalEvent?.type == "doDeleteUserReason") {
      setDelData(globalEvent?.data);
    }

    if (globalEvent?.type == "doDeleteUserStart") {
      let sendData = delData;
      callAxios(
        "post",
        "/users/current/removal/check-reason",
        sendData,
        "responseDeleteUserStart"
      );
    }

    if (globalEvent?.type == "responseDeleteUserStart") {
      if (globalEvent?.data.hasOwnProperty("nextStep")) {
        if (globalEvent?.data.nextStep == "password_verification") {
          navigate("/deleteuser/step5", { replace: true });
        }
        if (globalEvent?.data.nextStep == "email_verification") {
          navigate("/deleteuser/step6", { replace: true });
        }
      }
    }

    if (globalEvent?.type == "doDeleteUserPassword") {
      callAxios(
        "post",
        "/users/current/removal/check-password",
        globalEvent?.data,
        "responseDeleteUserPassword"
      );
    }

    if (globalEvent?.type == "responseDeleteUserPassword") {
      if (globalEvent?.data.hasOwnProperty("nextStep")) {
        if (globalEvent?.data.nextStep == "email_verification") {
          navigate("/deleteuser/step6", { replace: true });
        }
      }
    }

    if (globalEvent?.type == "doDeleteUserConfirm") {
      let sendData = {};
      sendData["emailVerificationCode"] = globalEvent?.data.verificationCode;
      callAxios(
        "post",
        "/users/current/removal/check-email",
        sendData,
        "responseDeleteUserConfirm"
      );
    }

    if (globalEvent?.type == "responseDeleteUserConfirm") {
      navigate("/deleteuser/step7", { replace: true });
    }
    //social disconnect

    if (globalEvent?.type == "doSocialDisconnect") {
      let sendData = globalEvent?.data;
      callAxios(
        "patch",
        "/users/current",
        sendData,
        "responseSocialDisconnect"
      );
    }

    //forgotten password

    if (globalEvent?.type == "doForgotPassword") {
      let sendData = globalEvent?.data;
      //sendData["locale"] = language;
      console.log(sendData);

      callAxios(
        "post",
        "/users/password-reset-token",
        sendData,
        "responseForgotPasswordGetCode"
      );
    }

    if (globalEvent?.type == "doStoreForgotPassword") {
      let sendData = globalEvent?.data;
      delete sendData.confirm_password;
      sendData["newPassword"] = sendData.password;
      delete sendData.password;
      callAxios(
        "post",
        "/users/password",
        sendData,
        "responseStoreForgotPassword"
      );
    }

    //change password

    if (globalEvent?.type == "doStoreChangePassword") {
      setPasswordData(globalEvent?.data);
      let sendData = {};
      callAxios(
        "post",
        "/users/current/password-verification",
        sendData,
        "responseChangePasswordGetCode"
      );
    }

    if (globalEvent?.type == "responseChangePasswordGetCode") {
      navigate("/change-password/step2", { replace: true });
    }

    if (globalEvent?.type == "doChangePasswordConfirm") {
      let sendData = {};

      sendData["password"] = passwordData.old_password;
      sendData["newPassword"] = passwordData.password;
      sendData["passwordVerificationCode"] = globalEvent?.data.verificationCode;
      callAxios(
        "post",
        "users/current/password-reset",
        sendData,
        "responseChangePasswordConfirm"
      );
    }

    //delete sendData.confirm_password;

    //post api/v1/users/current/password-verification

    //resend code

    if (globalEvent?.type == "doResendCode") {
      if (globalEvent?.data.validationType == "login") {
        callAxios(
          "post",
          process.env.REACT_APP_LOGIN_URL,
          loginData,
          "responseResend"
        );
      } else if (globalEvent?.data.validationType == "userdata") {
        callAxios(
          "post ",
          "/users/current/phone-verification",
          {},
          "responseResend"
        );
      } else if (globalEvent?.data.validationType == "changepassword") {
        callAxios(
          "post",
          "/users/current/password-verification",
          {},
          "responseResend"
        );
      } else {
        callAxios(
          "post",
          "/users/current/email-verification-code",
          {},
          "responseResend"
        );
      }
    }

    //login

    if (globalEvent?.type == "doLogin") {
      let sendData = globalEvent?.data;

      if (regInvitedToken) {
        sendData["invitationToken"] = regInvitedToken;
      }

      setLoginData(sendData);

      callAxios(
        "post",
        process.env.REACT_APP_LOGIN_URL,
        sendData,
        "responseLogin"
      );
    }

    if (globalEvent?.type == "responseLogin") {
      if (globalEvent?.data.hasOwnProperty("user")) {
        if (globalEvent?.data.user.hasOwnProperty("locale")) {
          i18n.changeLanguage(globalEvent?.data.user.locale, (err, t) => {
            if (err) return console.log("something went wrong loading", err);
          });
        }
      }
      if (
        globalEvent?.data.hasOwnProperty("status") &&
        globalEvent?.data.status == "two_factor_code_sent"
      ) {
        navigate("/login/confirm", { replace: true });
      } else if (globalEvent?.data.hasOwnProperty("token")) {
        localStorage.setItem("token", globalEvent?.data.token);
        //window.token = globalEvent?.data.token;
        if (globalEvent?.data.hasOwnProperty("currentHatId")) {
          localStorage.setItem("chid", globalEvent?.data.currentHatId);
        } else {
          localStorage.setItem("chid", null);
        }
        navigate("/", { replace: true });
      } else if (globalEvent?.data.hasOwnProperty("authorisation")) {
        if (globalEvent?.data.authorisation.hasOwnProperty("token")) {
          console.log(globalEvent?.data.authorisation.token);
          localStorage.setItem("token", globalEvent?.data.authorisation.token);
          //window.token = globalEvent?.data.authorisation.token;
          navigate("/", { replace: true });
        } else {
          setGlobalEvent({
            type: "showToast",
            data: { message: "Unexpected Error", toastType: "error" },
          });
        }
      } else {
        setGlobalEvent({
          type: "showToast",
          data: { message: "Unexpected Error", toastType: "error" },
        });
      }
    }

    //logout

    //lifesignal

    if (globalEvent?.type == "doSaveLifeSignal") {
      console.log(globalEvent?.data);
      callAxios(
        "patch",
        "/users/current",
        globalEvent?.data,
        "responseSaveLifeSignal"
      );
    }

    //currency

    if (globalEvent?.type == "doSaveCurrency") {
      console.log(globalEvent?.data);
      callAxios(
        "patch",
        "/users/current",
        globalEvent?.data,
        "responseSaveCurrency"
      );
    }

    if (globalEvent?.type == "responseSaveCurrency") {
      dispatch(fetchAssets());
    }

    //currency

    if (globalEvent?.type == "doSaveLocale") {
      console.log(globalEvent?.data);
      callAxios(
        "patch",
        "/users/current",
        globalEvent?.data,
        "responseSaveCurrency"
      );
    }

    //change hat

    if (globalEvent?.type == "doChangeHat") {
      console.log(globalEvent?.data);
      callAxios(
        "patch",
        "/users/current",
        globalEvent?.data,
        "responseChangeHat"
      );
    }

    if (globalEvent?.type == "responseChangeHat") {
      localStorage.setItem("token", globalEvent?.data.token);
      console.log("chid", globalEvent?.data.currentHatId);
      localStorage.setItem("chid", globalEvent?.data.currentHatId);

      if (window.location.pathname === "/") {
        window.location.reload();
      } else {
        navigate("/", { replace: true });
      }
    }

    //getUser

    if (globalEvent?.type == "doGetUser") {
      callAxios("get", "/users/current", globalEvent?.data, "responseGetUser");
    }

    if (globalEvent?.type == "responseGetUser") {
      setUser(globalEvent?.data.user);

      if (globalEvent?.data.user.hasOwnProperty("referralToken")) {
        localStorage.setItem(
          "referralToken",
          globalEvent?.data.user.referralToken
        );
      } else {
        localStorage.setItem("referralToken", null);
      }

      if (globalEvent?.data.user.hasOwnProperty("displayCurrency")) {
        localStorage.setItem(
          "baseCurrency",
          globalEvent?.data.user.displayCurrency
        );
      } else {
        localStorage.setItem("baseCurrency", null);
      }

      callAxios("get", "/hats", globalEvent?.data, "responseGetHat");
    }

    if (globalEvent?.type == "responseGetHat") {
      setHats(globalEvent?.data);
      callAxios("get", "/contacts", globalEvent?.data, "responseGetContacts");
    }

    //getContacts

    if (globalEvent?.type == "responseGetContacts") {
      setContacts(globalEvent?.data);
      callAxios("get", "/assets", globalEvent?.data, "responseGetSums");
    }

    if (globalEvent?.type == "responseGetContactsError") {
      callAxios("get", "/assets", globalEvent?.data, "responseGetSums");
    }

    //getSums

    if (globalEvent?.type == "responseGetSums") {
      setSums(globalEvent?.data);
    }

    //saveAsset

    if (globalEvent?.type == "doSaveAsset") {
      if (globalEvent?.data?.asset?.id * 1 > 0) {
        let tempId = globalEvent?.data?.asset?.id;
        let tempData = globalEvent?.data;
        delete tempData.asset.id;
        callAxios("patch", "/assets/" + tempId, tempData, "responseSaveAsset");
      } else {
        callAxios("post", "/assets", globalEvent?.data, "responseSaveAsset");
      }
    }

    if (globalEvent?.type == "doSaveAssetImage") {
      if (globalEvent?.data?.asset?.id * 1 > 0) {
        let tempId = globalEvent?.data?.asset?.id;
        let tempData = globalEvent?.data;
        delete tempData.asset.id;
        callAxios(
          "patch",
          "/assets/" + tempId,
          tempData,
          "responseSaveAssetImage"
        );
      } else {
        callAxios(
          "post",
          "/assets",
          globalEvent?.data,
          "responseSaveAssetImage"
        );
      }
    }

    if (globalEvent?.type == "responseSaveAsset") {
      dispatch(fetchAssets());
      navigate("/asset-list", { replace: true });
    }

    if (globalEvent?.type == "doDeleteAsset") {
      setGlobalEvent({
        type: "showConfirm",
        data: {
          callroot: "deleteAsset",
          calldata: globalEvent?.data,
          title: t("common.areyousure"),
          description: "",
        },
      });
    }

    if (globalEvent?.type == "responseDeleteAsset") {
      dispatch(fetchAssets());
      navigate("/asset-list", { replace: true });
      setGlobalEvent({
        type: "showToast",
        data: { message: t("asset.delete") },
      });
    }

    //saveContact
    if (globalEvent?.type === "doDeleteContact") {
      setGlobalEvent({
        type: "showConfirm",
        data: {
          callroot: "deleteContact",
          calldata: globalEvent?.data,
          title: t("common.areyousure"),
          description: "",
        },
      });
    }

    if (globalEvent?.type === "responseDeleteContact") {
      dispatch(fetchContacts());
      navigate("/contact-list", { replace: true });
      setGlobalEvent({
        type: "showToast",
        data: { message: t("contact.delete"), toastType: "success" },
      });
    }

    if (globalEvent?.type == "doSaveContact") {
      if (globalEvent?.data?.contact?.id * 1 > 0) {
        let tempId = globalEvent?.data?.contact?.id;
        let tempData = globalEvent?.data;
        delete tempData.contact.id;
        callAxios(
          "patch",
          "/contacts/" + tempId,
          tempData,
          "responseSaveContact"
        );
      } else {
        callAxios(
          "post",
          "/contacts",
          globalEvent?.data,
          "responseSaveContact"
        );
      }
    }

    if (globalEvent?.type == "responseSaveContact") {
      dispatch(fetchContacts());
      navigate("/contact-list", { replace: true });
    }

    //attachments

    if (globalEvent?.type == "doFileUpload") {
      var formdata = new FormData();
      formdata.append(
        "attachment",
        globalEvent?.data?.file,
        globalEvent?.data?.file?.name
      );
      formdata.append("attachmentType", globalEvent?.data?.uploadType);

      callAxiosFile("post", "/attachments", formdata, "responseFileUpload");
    }

    if (globalEvent?.type == "responseFileUploadError") {
      if (globalEvent?.data.hasOwnProperty("errors")) {
        if (
          globalEvent?.data.errors[0] &&
          globalEvent?.data.errors[0].hasOwnProperty("message")
        ) {
          setGlobalEvent({
            type: "showToast",
            data: {
              message: t(
                "errors.upload." + globalEvent?.data.errors[0].message
              ),
              toastType: "error",
            },
          });
        }
      }
    }

    if (globalEvent?.type == "doGetAttachments") {
      callAxios(
        "get",
        "/assets/" + globalEvent?.data?.id + "/attachments",
        globalEvent?.data,
        "responseGetAttachments"
      );
    }

    if (globalEvent?.type == "doDeleteAttachment") {
      setGlobalEvent({
        type: "showConfirm",
        data: {
          callroot: "deleteAttachment",
          calldata: globalEvent?.data,
          title: t("common.areyousure"),
          description: "",
        },
      });
    }

    if (globalEvent?.type == "confirmAnswered") {
      if (globalEvent?.data.callroot == "deleteAttachment") {
        if (globalEvent?.data.answer === true) {
          callAxios(
            "delete",
            "/attachments/" + globalEvent?.data?.calldata.id,
            globalEvent?.data?.calldata,
            "responseDeleteAttachment"
          );
        }
      }

      if (globalEvent?.data.callroot == "deleteAsset") {
        if (globalEvent?.data.answer === true) {
          callAxios(
            "delete",
            "/assets/" + globalEvent?.data?.calldata.id,
            globalEvent?.data?.calldata,
            "responseDeleteAsset"
          );
        }
      }

      if (globalEvent?.data.callroot === "deleteContact") {
        if (globalEvent?.data.answer === true) {
          callAxios(
            "delete",
            "/contacts/" + globalEvent?.data?.calldata.id,
            globalEvent?.data?.calldata,
            "responseDeleteContact"
          );
        }
      }
    }

    //User Data

    if (globalEvent?.type == "responseGetUser") {
      setUserData(globalEvent?.data);
    }

    if (globalEvent?.type == "doStoreUserData") {
      setUserData(globalEvent?.data);
      callAxios(
        "post ",
        "/users/current/phone-verification",
        {},
        "responsePhoneVerification"
      );
    }

    if (globalEvent?.type == "responsePhoneVerification") {
      navigate("/add-user-data/step2", { replace: true });
    }

    if (globalEvent?.type == "responsePhoneVerificationError") {
    }

    if (globalEvent?.type == "doSaveUserData") {
      let sendData = userData;
      sendData["phoneVerificationCode"] = globalEvent?.data.verificationCode;
      callAxios("patch", "/users/current", sendData, "responseSaveUserData");
    }

    if (globalEvent?.type == "doGetTempUser") {
      if (userData) {
        setGlobalEvent({ type: "responseGetTempUser", data: userData });
      } else {
        setGlobalEvent({ type: "doGetUser", data: {} });
      }
    }

    //getInvoices

    if (globalEvent?.type == "doGetInvoices") {
      callAxios("get", "/invoices", globalEvent?.data, "responseGetInvoices");
    }

    if (globalEvent?.type == "doGetInvoice") {
      callAxios(
        "get",
        "/invoices/" + globalEvent?.data.id,
        {},
        "responseGetInvoice"
      );
    }

    if (globalEvent?.type == "responseGetInvoice") {
      window.open(globalEvent?.data?.publicUrl, "_blank");
    }
  }, [globalEvent]);

  return <></>;
};

export default DataModule;
