import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import DeleteButton from "./DeleteButton";
import { t } from "i18next";

export const DeleteProfileCard = () => {
  return (
    <>
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          mt: 2,
          boxShadow: "4px 4px 20px 0px #0000001A",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={8}>
              <Box>
                <Box sx={{}}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {t("profile.deleteProfile.title")}
                  </Typography>
                  <Typography variant="body2">
                    {t("profile.deleteProfile.text")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <DeleteButton />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
