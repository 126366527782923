import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { PricesCardComponent } from "../../HomePage/Prices/PricesCardComponent";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createPaymentIntent,
  setSelectedPlan,
} from "../../../store/Slices/paymentSlice";
import PricingCard from "./PricingCard";
import { fetchPlans } from "../../../store/Actions/planAction";

const EditSubscription = ({ globalEvent, setGlobalEvent, title }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [selected, setSelected] = useState("premium");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { plans, loading } = useSelector((state) => state.plan);
  const freeplan = plans.find((row) => row.name === "free");

  useState(() => {
    if (plans.length == 0 && loading === false) {
      dispatch(fetchPlans());
    }
  }, []);

  const onCardSelected = async (type) => {
    console.log("on card selected", type);
    if (type === "free") {
      dispatch(setSelectedPlan({ plan: type }));
      await dispatch(createPaymentIntent("free"));
      navigate("/profile");
    } else {
      dispatch(setSelectedPlan({ plan: type }));
      navigate("/payment");
    }
  };

  return (
    <div>
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
            width: "100%",
            textAlign: "start",
          }}
        >
          {t("subscription.title")}
        </Typography>
      </Box>
      <Stack
        direction="column"
        spacing={6}
        sx={{
          mt: { xs: 0, sm: 4 },
          width: { xs: "100%", md: "auto" },
          justifyContent: "center",
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          {plans.map((plan) =>
            plan.name !== "free" ? (
              <Grid item key={plan.id} sm={12}>
                <PricingCard
                  data={plan}
                  selected={selected === plan.id}
                  onSelect={onCardSelected}
                />
              </Grid>
            ) : (
              <></>
            )
          )}
        </Grid>
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Button
            onClick={() => onCardSelected("free")}
            variant="text"
            sx={{ mt: 3, mb: 2, boxShadow: "none", py: 2 }}
          >
            {t("subscription.btnnext")}
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

export default EditSubscription;
