import React, { useEffect, useReducer, useState, Suspense } from "react";
import {   Box,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Grid } from "@mui/material";
  
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EyeOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

import "./registration.css";

export const DelUsrStep5 = ({ globalEvent, setGlobalEvent }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
	setValue
  } = useForm({
    mode: "onBlur"
  });

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [password, setPassword] = useState("password");
  const [type, setType] = useState("password");
  const [visibility, setVisibility] = useState(true);

  const doDeleteUserPassword = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doDeleteUserPassword", data: data });
  };
  
  const onSubmit = (data, e) => {
    doDeleteUserPassword(e, data);
	console.log(data, e);
  };
  const onError = (errors, e) => {
    console.log(errors, e);
  };
  
  return (
    <Box
      sx={{
        mt: { sx: 0, sm: 16 },
        mb: 5,
        px: { xs: 2, lg: 0 },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("deleteuser.step5title"))}
        </Typography>
        <Typography variant="body3">
          {ReactHtmlParser(t("deleteuser.step5text"))}
        </Typography>
      </Box>


	  <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
		  <TextField
            key="password"
            className="fullwidth-field"
            label={ReactHtmlParser(t("field.password"))}
            type={type}
            {...register("password", {
              required: t("errors.field.required"),
            })}
            InputProps={{
				onChange:(e) => {
                  setPassword(e.target.value);
				},
              endAdornment: (
                <InputAdornment sx={{mt: -1.5}} position="end">
                  <IconButton
                    onClick={(e) => {
                      setType(visibility ? "text" : "password");
                      setVisibility(!visibility);
                    }}
                  >
                    {visibility ? <EyeIcon /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.password && (
            <span role="alert">{errors.password.message}</span>
          )}


          <Button
            type="submit"
            variant="contained"
            className="fullwidth-nextbtn"
			sx={{            
			  bgcolor: "#EB8176"
            }}
            endIcon={
              	  <DeleteIcon/>
            }
          >
            {ReactHtmlParser(t("deleteuser.buttondodeleteuserconfirm"))}
          </Button>
        </Box>
    

	
	
	
	</Box>
  );
};
