import React, { useEffect, useReducer, useState, Suspense } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import { RegistrationButton } from "./RegistrationButton";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import "./registration.css";

export const RegStep1 = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.AppleID.auth.init({
        clientId: "hu.wst.b120.dev",
        scope: "name email",
        redirectURI: "https://b120-api.wst.hu",
        state: "SignInUserAuthenticationRequest",
        nonce: String(Date.now()),
        usePopup: true,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleButtonDiv"),
        {
          theme: "outline",
          size: "large",
          shape: "circle",
          width: window.screen.width <= 900 ? "310" : "375",
          logo_alignment: "center",
        }
      );
    }, 300);
  });

  const showRegStep2 = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "showRegStep2", data: data });
  };

  return (
    <Box
      sx={{
        mt: { sx: 0, sm: 16 },
        mb: 5,
        px: { xs: 2, lg: 0 },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("registration.homeTitle"))}
        </Typography>
        <Typography
          variant="body3"
          sx={{
            color: "rgba(43, 54, 116, .7)",
          }}
        >
          {ReactHtmlParser(t("registration.text"))}
        </Typography>
      </Box>

      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          mt: { xs: 4, sm: 4 },
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{ width: "100%" }}
            id="googleButtonDiv"
            className="google-btn"
            data-context="signin"
            data-ux_mode="popup"
            data-auto_prompt="false"
          ></div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{ width: "100%" }}
            id="appleid-signin"
            className="signin-button apple-btn"
            data-color="black"
            data-border="true"
            data-type="sign-in"
            data-border-radius="50"
            data-height="44"
          ></div>
        </div>

        <RegistrationButton
          bColor="secondary-blue-btn"
          onClick={(e) => {
            showRegStep2(e, {});
          }}
          text={t("registration.buttonregwithemail")}
        />
      </Stack>

      <Stack
        direction={"row"}
        sx={{
          mt: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ lineHeight: "20.16px", fontWeight: 500 }}
        >
          {ReactHtmlParser(t("common.alreadyauser"))}
        </Typography>
        <Button
          className="secondary-blue-text-btn"
          onClick={(e) => {
            navigate("/login");
          }}
          sx={{
            lineHeight: "20.16px",
            fontWeight: 700,
          }}
        >
          {ReactHtmlParser(t("common.dologin"))}
        </Button>
      </Stack>
    </Box>
  );
};
