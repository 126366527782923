import { Card, CardContent, Typography, Grid, Box, Stack } from "@mui/material";
import DeleteButton from "./DeleteButton";
import ConnectButton from "./ConnectButton";
import { t } from "i18next";

const SocialChannelsCard = ({ user, globalEvent, setGlobalEvent }) => {
  const handleClick = (e) => {
    const targetParent = e.currentTarget.closest(".MuiGrid-container");
    const btn = targetParent.querySelector("button");
    btn.click();
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          mt: 2,
          boxShadow: "4px 4px 20px 0px #0000001A",
        }}
      >
        <CardContent>
          <Grid container alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 3, fontWeight: 700 }}>
                {t("profile.socialChannels.title")}
              </Typography>
            </Grid>
            <Grid item xs={9} md={10} sx={{ pr: 2 }}>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "32px",
                  boxShadow: "0px 0px 10px 0px #0000001A",
                  px: { xs: "8px", sm: "12px" },
                  py: "16px",
                }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="23.3739"
                      height="23.3739"
                      transform="translate(0 0.312988)"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.4395 12.2543C22.4395 11.4601 22.3682 10.6965 22.2359 9.96338H11.6875V14.2957H17.7151C17.4555 15.6957 16.6664 16.8819 15.4802 17.6761V20.4863H19.0999C21.2177 18.5365 22.4395 15.6652 22.4395 12.2543Z"
                      fill="#4285F4"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.6874 23.1997C14.7114 23.1997 17.2467 22.1968 19.0998 20.4862L15.4802 17.676C14.4773 18.348 13.1943 18.7451 11.6874 18.7451C8.77035 18.7451 6.30126 16.775 5.42053 14.1277H1.67871V17.0295C3.52162 20.6899 7.30926 23.1997 11.6874 23.1997Z"
                      fill="#34A853"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.4204 14.128C5.1964 13.456 5.06912 12.7382 5.06912 12C5.06912 11.2619 5.1964 10.544 5.4204 9.87203V6.97021H1.67858C0.920032 8.48221 0.487305 10.1928 0.487305 12C0.487305 13.8073 0.920032 15.5179 1.67858 17.0299L5.4204 14.128Z"
                      fill="#FBBC05"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.6874 5.25435C13.3318 5.25435 14.8082 5.81944 15.9689 6.92926L19.1813 3.7169C17.2416 1.90962 14.7063 0.799805 11.6874 0.799805C7.30926 0.799805 3.52162 3.30962 1.67871 6.96999L5.42053 9.8718C6.30126 7.22453 8.77035 5.25435 11.6874 5.25435Z"
                      fill="#EA4335"
                    />
                  </svg>

                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#1F1F1F8A",
                    }}
                  >
                    Google
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  onClick={(event) => handleClick(event)}
                  sx={{
                    pr: 1,
                    fontWeight: 700,
                    fontSize: "12px",
                    color: "primary.light",
                    cursor: "pointer",
                  }}
                >
                  {user?.googleId
                    ? t("profile.socialChannels.connected")
                    : t("profile.socialChannels.connect")}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <ConnectButton
                type={user?.googleId ? "remove_google" : "add_google"}
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} sx={{ mt: 3 }}>
            <Grid item xs={9} md={10} sx={{ pr: 2 }}>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "32px",
                  boxShadow: "0px 0px 10px 0px #0000001A",
                  px: { xs: "8px", sm: "12px" },
                  py: "16px",
                }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_254_9904)">
                      <path
                        d="M20.7383 18.2601C20.3993 19.0432 19.9981 19.764 19.5332 20.4267C18.8996 21.3301 18.3808 21.9555 17.9809 22.3028C17.3611 22.8728 16.697 23.1647 15.9859 23.1813C15.4754 23.1813 14.8597 23.036 14.143 22.7413C13.424 22.448 12.7632 22.3028 12.159 22.3028C11.5254 22.3028 10.8458 22.448 10.1189 22.7413C9.39089 23.036 8.80441 23.1896 8.35601 23.2048C7.67407 23.2339 6.99435 22.9336 6.31587 22.3028C5.88283 21.9251 5.34118 21.2776 4.6923 20.3603C3.99611 19.3808 3.42375 18.2449 2.97535 16.9499C2.49513 15.5512 2.25439 14.1967 2.25439 12.8854C2.25439 11.3833 2.57897 10.0878 3.22909 9.00211C3.74002 8.13007 4.41975 7.44219 5.27048 6.9372C6.12121 6.43221 7.04042 6.17488 8.03033 6.15841C8.57198 6.15841 9.28228 6.32596 10.165 6.65524C11.0452 6.98562 11.6103 7.15317 11.8581 7.15317C12.0434 7.15317 12.6712 6.95726 13.7356 6.56669C14.7421 6.20449 15.5916 6.05451 16.2875 6.11359C18.1732 6.26578 19.59 7.00914 20.5321 8.34839C18.8456 9.37027 18.0114 10.8015 18.028 12.6376C18.0432 14.0677 18.562 15.2578 19.5817 16.2028C20.0438 16.6414 20.5598 16.9803 21.134 17.2211C21.0095 17.5822 20.878 17.928 20.7383 18.2601ZM16.4134 1.24137C16.4134 2.36232 16.0039 3.40894 15.1876 4.37768C14.2025 5.52933 13.011 6.1948 11.7189 6.08979C11.7025 5.95531 11.6929 5.81378 11.6929 5.66505C11.6929 4.58895 12.1614 3.4373 12.9933 2.49567C13.4086 2.01891 13.9369 1.62249 14.5774 1.30626C15.2166 0.994744 15.8212 0.82247 16.3899 0.792969C16.4065 0.942821 16.4134 1.09268 16.4134 1.24136V1.24137Z"
                        fill="#241C28"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_254_9904">
                        <rect
                          width="23.3883"
                          height="23.3883"
                          fill="white"
                          transform="translate(0 0.305908)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Apple
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  onClick={(event) => handleClick(event)}
                  sx={{
                    pr: 1,
                    fontWeight: 700,
                    fontSize: "12px",
                    color: "socialLink.main",
                    cursor: "pointer",
                  }}
                >
                  {user?.appleId
                    ? t("profile.socialChannels.connected")
                    : t("profile.socialChannels.connect")}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <ConnectButton
                type={user?.appleId ? "remove_apple" : "add_apple"}
                globalEvent={globalEvent}
                setGlobalEvent={setGlobalEvent}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SocialChannelsCard;
