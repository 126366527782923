const initialState = {
  user: {},
  loading: false,
  loggedIn: true,
  regConsentToMarketing: false,
  error: null,
  recoverModalDisplayed: true
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CURRENT_USER_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_CURRENT_USER_SUCCESS":
      return { ...state, loading: false, user: action.payload, loggedIn: true };
    case "FETCH_CURRENT_USER_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "USER_LOGOUT":
      return { ...state, loading: false, user: {}, loggedIn: false };
    case "SET_CURRENT_USER_COUNTRY":
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          billingAddress: {
            ...state.user.billingAddress,
            country: action.payload,
          },
        },
      };
    case "UPDATE_CURRENT_USER_BILLING_ADDRESS_REQUEST":
      return { ...state, loading: true, error: null };
    case "UPDATE_CURRENT_USER_BILLING_ADDRESS_SUCCESS":
      return { ...state, loading: false, user: action.payload, loggedIn: true };
    case "UPDATE_CURRENT_USER_BILLING_ADDRESS_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_CURRENT_USER_MARKETING_PREFERENCE_REQUEST":
      return { ...state, loading: true, error: null };
    case "UPDATE_CURRENT_USER_MARKETING_PREFERENCE_SUCCESS":
      return { ...state, loading: false, user: action.payload, loggedIn: true };
    case "UPDATE_CURRENT_USER_MARKETING_PREFERENCE_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_CURRENT_USER_2FA_REQUEST":
      return { ...state, loading: true, error: null };
    case "UPDATE_CURRENT_USER_2FA_SUCCESS":
      return { ...state, loading: false, user: action.payload, loggedIn: true };
    case "UPDATE_CURRENT_USER_2FA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "SET_REG_CONSENT_TO_MARKETING":
      return {
        ...state,
        loading: false,
        regConsentToMarketing: action.payload,
      };
    case "UPDATE_CURRENT_USER_COUPON_CODE_REQUEST":
      return { ...state, loading: true, error: null };
    case "UPDATE_CURRENT_USER_COUPON_CODE_SUCCESS":
      return { ...state, loading: false, user: action.payload, loggedIn: true };
    case "UPDATE_CURRENT_USER_COUPON_CODE_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default userReducer;
