import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithAuth } from "../api";

const initialState = {
  exchangeRates: {},
  status: "idle",
  error: null,
};

export const fetchExchangeRates = createAsyncThunk(
  "hats/fetchHats",
  async () => {
    const response = await fetchWithAuth("exchange-rates");
    const data = response.exchangeRates;

    localStorage.setItem("exchangeRates", JSON.stringify(data));
    return data;
  }
);

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.exchangeRates = action.payload;
      })
      .addCase(fetchExchangeRates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default exchangeRatesSlice.reducer;
