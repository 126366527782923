import React, { useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import CloseBtnIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/CloseRounded";
import InfoIcon from "@mui/icons-material/ErrorOutlineOutlined";

const ToastModal = ({
  open,
  onClose,
  title,
  description,
  success = "error",
  icon
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    width: { sm: 500, xs: "90%" },
    bgcolor: "#FAFCFC",
    boxShadow: "4px 4px 20px 0px #0000001A",
    p: { sm: "20px", xs: "15px" },
    borderRadius: "32px",
  };
  const [vertical, setVertial] = useState("top");
  const [horizontal, setHorizontal] = useState("center");

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onClose}
      key={vertical + horizontal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Stack direction={"row"} justifyContent={"space-between"} sx={style}>
        <Stack direction={"row"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
		   {icon ? icon : (
			<Stack
              sx={{
                width: { sm: 100, xs: 50 },
                height: { sm: 100, xs: 50 },
                bgcolor:
                  success === "success"
                    ? "#62C275"
                    : success === "info"
                    ? "#2B3674"
                    : "#EB8176",
                boxShadow: "0 0 50px rgba(0,0,0,0.1)",
                borderRadius: "100px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {success === "success" ? (
                <CheckIcon
                  sx={{ fontSize: { sm: 60, xs: 30 }, color: "#FFFFFF" }}
                />
              ) : success === "info" ? (
                <InfoIcon
                  sx={{ fontSize: { sm: 60, xs: 30 }, color: "#FFFFFF" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: { sm: 60, xs: 30 }, color: "#FFFFFF" }}
                />
              )}
		   </Stack> )}
          </Box>
          <Stack
            direction={"column"}
            sx={{ ml: { sm: 3, xs: 1 }, mt: { sm: 2, xs: 0 } }}
          >
		  {title && (
			<Typography
              id="modal-title"
              variant="h6"
              component="div"
              sx={{
                fontSize: { sm: "24px", xs: "18px" },
                fontWeight: 700,
                lineHeight: "40px",
              }}
            >
              {title}
            </Typography>
			)}
            <Typography
              id="modal-description"
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: 400,
                lineHeight: "22px",
              }}
            >
              {description}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} justifyContent={"end"}>
          <CloseBtnIcon
            onClick={onClose}
            sx={{
              color: "#1C1B1F",
              ml: { sm: 0, xs: 1 },
              mt: { sm: 2, xs: 0 },
              cursor: "pointer",
            }}
          />
        </Stack>
      </Stack>
    </Snackbar>
  );
};

export default ToastModal;
