import React, { useEffect, useState } from "react";
import { Typography, Grid, Stack, Box, Button } from "@mui/material";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import { BillingCard } from "./BillingCard";
import { EmptyCard } from "./EmptyCard";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AuthLayout from "../Layout/ProfileLayout";
import loginbg from "../Assets/login-background.png";

const BillingListIndex = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [invoices, setInvoices] = useState();
  const [onLoad, setOnLoad] = useState(true);

  useEffect(() => {
	setOnLoad(true);
    setGlobalEvent({ type: "doGetInvoices", data: {} });
  }, [setGlobalEvent]);


  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG === "on") {
      console.log("------ Global Event ------ ContactList");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type === "responseGetInvoices") {
	  setInvoices(globalEvent.data.invoices);
	  setOnLoad(false);
    }

  }, [globalEvent]);


  
  return (
    <AuthLayout backgroundImage={loginbg}>
        <div className="login-container">

        <Grid
          sx={{
            mt: { xs: 0, sm: 8 },
            mb: 20,
            textAlign: "left",
          }}
          container
        >
          <Grid item xs={12} md={12}  sx={{ pr: { md: 3 } }}>
            <Stack
              direction={{ sx: "column", md: "row" }}
              justifyContent={"space-between"}
              sx={{ mb: 6 }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: { xs: 3, md: 0 },
                  pl: { xs: 2, sm: 0 },
                  fontWeight: 700,
                }}
              >
                    <>{ReactHtmlParser(t("billing.billingListList"))}</>
              </Typography>
            </Stack>
          </Grid>


		  <Grid item xs={12} md={12} sx={{ pr: { md: 3 } }}>
		  {(!onLoad)&&(<>

      <Stack spacing={2}>
        {invoices.map(invoice => (
          <BillingCard
            key={invoice.id}
            title={invoice.itemComment}
            info={invoice.fulfillmentDate}
			value={invoice.grossTotal}
			currency={invoice.currency}
            linkTo={()=>{setGlobalEvent({ type: "doGetInvoice", data: {id:invoice.id} });}}
          />
        ))}
      </Stack>
            
            {invoices.length * 1 < 1 &&
               (
                <EmptyCard/>
            )}
          </>)}
		  </Grid>
        </Grid>


		</div>
    </AuthLayout>
  );
};

export default BillingListIndex;
