import { t } from "i18next";
import { SideBarCard } from "./SideBarCard";
import SupportIcon from "@mui/icons-material/Support";

export const HelpCard = () => {
  return (
    <SideBarCard
      IconComponent={SupportIcon}
      title={t("cards.help.title")}
      description={t("cards.help.description")}
      mailto="help@wst.hu"
      mailtoText={t("cards.help.linkText")}
      subject={t("cards.help.subjectHelp")}
      body={t("cards.help.bodyHelp")}
    />
  );
};
