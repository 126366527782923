import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";

const AssetHeaderBottom = ({ titleKey, setEditMode, setGlobalEvent, id, chid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack
      direction={{ md: "row", xs: "column" }}
      justifyContent={"space-between"}
	  sx={{ mt: 6 }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
      >
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={{ md: "end", xs: "start" }}
        sx={{ mb: { xs: 6, md: 0 } }}
      >
        {(!chid || chid === "null") && (
          <>
            <Button
              onClick={() => setEditMode(true)}
              variant="outlined"
              className="small-btn outlined-btn"
              startIcon={<EditIcon />}
              sx={{
                boxShadow: "0px 0px 10px 0px #0000001A",
              }}
            >
              {ReactHtmlParser(t("asset.buttondoedit"))}
            </Button>
            <Button
              variant="text"
              sx={{
                minWidth: "50px",
                width: "50px !important",
                height: "50px",
                p: 0,
                boxShadow: "0px 0px 10px 0px #0000001A",
                borderRadius: "32px",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setGlobalEvent({
                  type: "doDeleteAsset",
                  data: { id: id },
                });
              }}
            >
              <DeleteIcon sx={{ color: "info.secondary" }} />
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AssetHeaderBottom;
