import { SideBarCard } from "./SideBarCard";
import { useTranslation } from 'react-i18next';
import ChatPasteGoIcon from '../../Commons/Icons/ChatPasteGoIcon';
import { useNavigate } from "react-router-dom";

const MakeOwnAssetCard = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const callbackFn = () => {
      localStorage.removeItem('chid');
      navigate('/add-new-asset');
    }

    return (
      <SideBarCard
          IconComponent={ChatPasteGoIcon}
          title={t("cards.makeOwnAssetList.title")}
          description={t("cards.makeOwnAssetList.description")}
          buttonText={t("cards.makeOwnAssetList.linkText")}
          buttonCallback={callbackFn}
          buttonColor={'#62C275'}
        />
    );
};

export default MakeOwnAssetCard;