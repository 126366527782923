import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";

export const TotalValueCard = ({ title, value, currency }) => {
  const formatValue = (price) => {
    let fPrice = !isNaN(price) ? price * 1 : 0;
    //fPrice = Math.ceil(fPrice);
    let formatedPrice = fPrice.toLocaleString("fr-FR");
    return formatedPrice.replaceAll(" ", "  ");
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 1,
        pt: { xs: 1, sm: 1 },
        pb: 0,
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent sx={{ pb: 0 }}>
        <Grid container>
          <Grid item xs={5} md={6}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Stack>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    color: "primary.light",
                  }}
                >
                  {title}:
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={7}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              pr: 4,
              alignItems: "center",
            }}
          >
            <Typography variant="body3" sx={{ fontWeight: 700 }}>
              {formatValue(value)} {currency}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
