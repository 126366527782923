import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  Stack,
  Button,
  Fade,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useState } from "react";

export const PricesCardComponent = ({ pricesData, onCardSelected }) => {
  const { t, i18n } = useTranslation();
  const [hide, setHide] = useState(false);

  const handleHide = () => {
    setHide(!hide);
  };

  const cardSelected = (type) => {
    if (onCardSelected) {
      onCardSelected(type);
    }
  };

  const formatPrice = (price) => {
    let formatedPrice = price.toLocaleString("hu-HU");
    return formatedPrice.replaceAll(" ", "  ");
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: "320px",
          borderRadius: "32px",
          mt: pricesData?.localized?.highlight
            ? { xs: 4, md: 0 }
            : { xs: 4, md: 5 },
          mx: { md: 0.5 },
          boxShadow: "0px 0px 5px 0px #0000001A",
        }}
      >
        {pricesData?.localized?.highlight ? (
          <Box
            sx={{
              bgcolor: "primary.main",
              color: "secondary.main",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "44px",
            }}
          >
            <Typography
              sx={{
                color: "secondary.main",
                fontWeight: 800,
                fontSize: 16,
                textTransform: "uppercase",
                letterSpacing: "2px",
              }}
            >
              {pricesData?.localized?.highlightLabel}
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <CardContent
          sx={{
            pt: 4,
            pb: 4,
            px: 3,
            height: { md: "860px" },
            bgcolor:
              pricesData?.displayProperties?.template === "secondary"
                ? "#FFF27E40"
                : "#FFFFFF",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: "18px",
                borderRadius: "8px",
                bgcolor:
                  pricesData?.displayProperties?.template === "secondary"
                    ? "primary.main"
                    : "secondary.main",
                color:
                  pricesData?.displayProperties?.template === "secondary"
                    ? "secondary.main"
                    : "primary.main",
                width: "fit-content",
                py: "8px",
                px: "16px",
                mb: 3,
              }}
            >
              {pricesData?.localized?.name}
            </Typography>
            <Stack direction={"row"} spacing={2} sx={{ alignItems: "end" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 800,
                  lineHeight: "50.4px",
                  fontSize: "40px",
                }}
              >
                {formatPrice(pricesData.price)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 800,
                  lineHeight: "40px",
                  fontSize: { xs: "24px" },
                  ml: "8px !important",
                }}
              >
                {t(`currencySymbols.${pricesData.currency}`)}
              </Typography>
            </Stack>
            <Typography
              variant={"body4"}
              sx={{ fontWeight: 700, opacity: "60%", mb: 3, minHeight: "18px" }}
            >
              {pricesData.price > 0
                ? t(`billingCycle.${pricesData.billingCycle}`)
                : ""}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px" },
                fontWeight: 700,
                lineHeight: "17.64px",
                width: "100%",
              }}
            >
              {pricesData?.localized?.description}
            </Typography>
            <Button
              variant="contained"
              className="green-btn"
              sx={{
                my: 2,
                py: 2,
                px: 4,
                width: "100%",
                textAlign: "center",
              }}
              onClick={() => cardSelected(pricesData.name)}
              endIcon={
                <svg
                  width="13"
                  height="10"
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9609 5.60156H1.16797C0.891826 5.60156 0.667969 5.37771 0.667969 5.10156C0.667969 4.82542 0.891826 4.60156 1.16797 4.60156H10.9609L7.81442 1.45512C7.61915 1.25985 7.61915 0.943271 7.81442 0.748009C8.00968 0.552747 8.32626 0.552747 8.52152 0.748009L12.5215 4.74801C12.7168 4.94327 12.7168 5.25985 12.5215 5.45512L8.52152 9.45512C8.32626 9.65038 8.00968 9.65038 7.81442 9.45512C7.61915 9.25985 7.61915 8.94327 7.81442 8.74801L10.9609 5.60156Z"
                    fill="white"
                  />
                </svg>
              }
            >
              {ReactHtmlParser(t("common.ichooseit"))}
            </Button>
            <Button
              variant="text"
              onClick={handleHide}
              sx={{
                color: "primary.main",
                fontWeight: 700,
                display: { xs: hide === false ? "flex" : "none", md: "none" },
                width: "100%",
                textAlign: "center",
              }}
              endIcon={
                <svg
                  width="7"
                  height="9"
                  viewBox="0 0 7 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33317 7.5299L3.33317 1.0013C3.33317 0.817207 3.48241 0.667969 3.6665 0.667969C3.8506 0.667969 3.99984 0.817207 3.99984 1.0013L3.99984 7.5299L6.09747 5.43227C6.22764 5.30209 6.4387 5.30209 6.56887 5.43227C6.69905 5.56244 6.69905 5.7735 6.56887 5.90367L3.90221 8.57034C3.77203 8.70051 3.56098 8.70051 3.4308 8.57034L0.764135 5.90367C0.63396 5.7735 0.63396 5.56244 0.764135 5.43227C0.89431 5.30209 1.10537 5.30209 1.23554 5.43227L3.33317 7.5299Z"
                    fill="#2B3674"
                  />
                </svg>
              }
            >
              {ReactHtmlParser(t("common.packagedescription"))}
            </Button>
          </Box>
          <List sx={{ display: { md: "block", xs: hide ? "block" : "none" } }}>
            {pricesData?.localized?.contents &&
              pricesData.localized.contents.map((desc, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{
                      px: 0,
                      pt: 2,
                      pb: 2,
                      borderTop: "solid 1px #EBEBEB",
                      lineHeight: "17.64px",
                      fontSize: "14px",
                    }}
                  >
                    {desc}
                  </ListItem>
                );
              })}
          </List>

          <Button
            variant="text"
            onClick={handleHide}
            sx={{
              color: "primary.main",
              fontWeight: 700,
              display: { xs: hide === true ? "flex" : "none", md: "none" },
              width: "100%",
              textAlign: "center",
            }}
            endIcon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.66683 4.4701L8.66683 10.9987C8.66683 11.1828 8.51759 11.332 8.3335 11.332C8.1494 11.332 8.00016 11.1828 8.00016 10.9987L8.00016 4.4701L5.90253 6.56773C5.77236 6.69791 5.5613 6.69791 5.43113 6.56773C5.30095 6.43756 5.30095 6.2265 5.43113 6.09633L8.09779 3.42966C8.22797 3.29949 8.43902 3.29949 8.5692 3.42966L11.2359 6.09633C11.366 6.2265 11.366 6.43756 11.2359 6.56773C11.1057 6.69791 10.8946 6.69791 10.7645 6.56773L8.66683 4.4701Z"
                  fill="#2B3674"
                />
              </svg>
            }
          >
            {ReactHtmlParser(t("common.closePackagedescription"))}
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
