import { Box, Typography } from "@mui/material";

export const FunctionCardComponent = ({
  functionSvg,
  functionTitle,
  functionText,
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        mb: { xs: 2, md: 6 },
        width: "249px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          mb: { xs: 2, md: 3 },
          width: "120px",
          height: "120px",
          bgcolor: "#FFF27E33",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {functionSvg}
      </Box>
      <Typography
        sx={{ mb: { xs: 2, md: 4 }, fontWeight: 700, height: "60px" }}
      >
        {functionTitle}
      </Typography>
      <Typography sx={{ mb: { xs: 3, md: 0 } }} variant="body2">
        {functionText}
      </Typography>
    </Box>
  );
};
