import React from "react";

const B120Logo = () => {
  return (
    <svg
      width="73"
      height="41"
      viewBox="0 0 73 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.94337 26.9717V22.087H11.4817C12.4219 22.087 13.1214 21.8462 13.5801 21.3646C14.0387 20.883 14.2681 20.1835 14.2681 19.2662C14.2681 18.3489 14.0387 17.6609 13.5801 17.2022C13.1214 16.7206 12.4219 16.4799 11.4817 16.4799H5.94337V12.6959H11.2753C13.0411 12.6959 14.6235 12.9138 16.0224 13.3495C17.4213 13.7623 18.5221 14.4503 19.3248 15.4135C20.1274 16.3767 20.5288 17.6609 20.5288 19.2662V19.7478C20.5288 21.2614 20.1733 22.5571 19.4624 23.6349C18.7515 24.7128 17.708 25.5384 16.332 26.1117C14.956 26.685 13.2705 26.9717 11.2753 26.9717H5.94337ZM0.439453 26.9717V1.44727H6.63136V26.9717H0.439453ZM5.94337 15.5167V11.7327H10.5529C11.4932 11.7327 12.1697 11.5034 12.5825 11.0447C13.0182 10.5631 13.2361 9.89808 13.2361 9.04956C13.2361 8.1781 13.0182 7.51305 12.5825 7.05439C12.1697 6.57279 11.4932 6.332 10.5529 6.332H5.94337V1.44727H10.2777C13.2131 1.44727 15.4835 2.06646 17.0888 3.30484C18.6941 4.52029 19.4968 6.2632 19.4968 8.53357V9.04956C19.4968 10.6319 19.084 11.9047 18.2584 12.8679C17.4557 13.8082 16.355 14.4847 14.956 14.8975C13.5801 15.3103 12.0206 15.5167 10.2777 15.5167H5.94337Z"
        fill="#2B3674"
      />
      <path
        d="M26.6977 26.7645V3.30401L28.2113 4.4736H21.125V1.65284H30.0001V26.7645H26.6977Z"
        fill="#2B3674"
      />
      <path
        d="M33.9877 26.7645V22.6365C33.9877 21.6275 34.1253 20.7675 34.4005 20.0566C34.6987 19.3456 35.2032 18.7035 35.9141 18.1302C36.625 17.5339 37.5882 16.9606 38.8037 16.4102L43.5164 14.2431C44.663 13.7156 45.5689 13.0391 46.234 12.2135C46.899 11.365 47.2315 10.2756 47.2315 8.94553C47.2315 7.34022 46.7843 6.09037 45.89 5.19598C44.9956 4.3016 43.7343 3.8544 42.106 3.8544C40.5007 3.8544 39.2394 4.3016 38.3221 5.19598C37.4047 6.09037 36.9461 7.34022 36.9461 8.94553H33.7125C33.7125 7.50075 34.0107 6.17064 34.6069 4.95519C35.2261 3.73974 36.1549 2.77655 37.3933 2.06563C38.6317 1.33177 40.2026 0.964844 42.106 0.964844C44.0095 0.964844 45.5689 1.33177 46.7843 2.06563C48.0227 2.77655 48.94 3.71681 49.5363 4.88639C50.1555 6.05597 50.4651 7.32875 50.4651 8.70473V9.18633C50.4651 10.998 49.9376 12.5231 48.8827 13.7615C47.8507 14.9998 46.4518 16.0204 44.686 16.823L39.9732 18.9902C38.9183 19.4718 38.2074 19.9534 37.8405 20.435C37.4735 20.9166 37.2901 21.5587 37.2901 22.3613V25.5261L36.3957 23.9437H50.7747V26.7645H33.9877Z"
        fill="#2B3674"
      />
      <path
        d="M62.6826 27.3837C60.8021 27.3837 59.1967 27.0626 57.8666 26.4205C56.5594 25.7554 55.4931 24.861 54.6675 23.7373C53.8648 22.6136 53.28 21.3179 52.9131 19.8502C52.5462 18.3595 52.3627 16.7886 52.3627 15.1374V13.2799C52.3627 10.9636 52.7182 8.87673 53.4291 7.01916C54.1629 5.16158 55.2867 3.69387 56.8002 2.61602C58.3368 1.51524 60.2975 0.964844 62.6826 0.964844C65.0676 0.964844 67.0169 1.51524 68.5305 2.61602C70.0441 3.69387 71.1563 5.16158 71.8672 7.01916C72.6011 8.87673 72.968 10.9636 72.968 13.2799V15.1374C72.968 16.7886 72.7845 18.3595 72.4176 19.8502C72.0507 21.3179 71.4544 22.6136 70.6288 23.7373C69.8262 24.861 68.7713 25.7554 67.4641 26.4205C66.1569 27.0626 64.5631 27.3837 62.6826 27.3837ZM62.6826 24.3565C65.0676 24.3565 66.8105 23.5539 67.9113 21.9485C69.035 20.3203 69.5969 17.7518 69.5969 14.2431C69.5969 10.5738 69.0235 7.94794 67.8769 6.36557C66.7532 4.78319 65.0217 3.992 62.6826 3.992C60.3205 3.992 58.5661 4.78319 57.4194 6.36557C56.2957 7.94794 55.7339 10.5508 55.7339 14.1743C55.7339 17.7059 56.2957 20.2859 57.4194 21.9141C58.5432 23.5424 60.2975 24.3565 62.6826 24.3565Z"
        fill="#2B3674"
      />
      <path
        d="M3.51259 40.4169C3.06294 40.4169 2.69123 40.3602 2.39746 40.2467C2.10969 40.1273 1.89386 39.9302 1.74997 39.6555C1.60608 39.3748 1.53414 38.9986 1.53414 38.5268L1.54313 34.1193H2.71221L2.70322 38.6074C2.70322 38.8463 2.76617 39.0314 2.89207 39.1628C3.02397 39.2882 3.20982 39.3509 3.44964 39.3509H4.21404V40.4169H3.51259ZM0.760742 36.4037V35.4899H4.21404V36.4037H0.760742Z"
        fill="#2B3674"
      />
      <path
        d="M5.26319 40.3542V33.8147H6.51322V37.6309H6.29738C6.29738 37.1412 6.36033 36.7262 6.48624 36.3857C6.61214 36.0453 6.79799 35.7855 7.0438 35.6064C7.2956 35.4272 7.61335 35.3376 7.99705 35.3376H8.05101C8.60857 35.3376 9.03124 35.5287 9.31902 35.911C9.60679 36.2932 9.75068 36.8486 9.75068 37.5772V40.3542H8.50066V37.4607C8.50066 37.1502 8.41073 36.9053 8.23087 36.7262C8.05701 36.547 7.82619 36.4574 7.53841 36.4574C7.23265 36.4574 6.98385 36.5589 6.792 36.762C6.60614 36.9591 6.51322 37.2189 6.51322 37.5414V40.3542H5.26319Z"
        fill="#2B3674"
      />
      <path
        d="M13.2799 40.5244C12.8602 40.5244 12.4915 40.4528 12.1737 40.3094C11.862 40.1661 11.6012 39.975 11.3913 39.7361C11.1875 39.4913 11.0316 39.2195 10.9237 38.9209C10.8218 38.6223 10.7708 38.3177 10.7708 38.0072V37.837C10.7708 37.5145 10.8218 37.2039 10.9237 36.9053C11.0316 36.6007 11.1875 36.332 11.3913 36.0991C11.6012 35.8602 11.859 35.6721 12.1647 35.5347C12.4705 35.3914 12.8242 35.3197 13.2259 35.3197C13.7535 35.3197 14.1941 35.4362 14.5479 35.6691C14.9076 35.896 15.1774 36.1976 15.3572 36.5739C15.5371 36.9441 15.627 37.3443 15.627 37.7743V38.2222H11.3014V37.4607H14.8446L14.4579 37.837C14.4579 37.5264 14.413 37.2607 14.323 37.0397C14.2331 36.8187 14.0952 36.6485 13.9094 36.5291C13.7295 36.4096 13.5017 36.3499 13.2259 36.3499C12.9501 36.3499 12.7163 36.4126 12.5244 36.538C12.3326 36.6634 12.1857 36.8456 12.0838 37.0845C11.9879 37.3174 11.9399 37.5981 11.9399 37.9266C11.9399 38.2311 11.9879 38.5029 12.0838 38.7417C12.1797 38.9747 12.3266 39.1598 12.5244 39.2972C12.7223 39.4285 12.9741 39.4942 13.2799 39.4942C13.5856 39.4942 13.8344 39.4345 14.0263 39.3151C14.2181 39.1897 14.341 39.0374 14.395 38.8582H15.5461C15.4741 39.1926 15.3362 39.4853 15.1324 39.7361C14.9286 39.9869 14.6678 40.181 14.35 40.3184C14.0383 40.4558 13.6815 40.5244 13.2799 40.5244Z"
        fill="#2B3674"
      />
      <path
        d="M18.711 40.3542V33.8147H19.988V40.3542H18.711ZM19.8082 40.3542V39.2345H22.641V40.3542H19.8082Z"
        fill="#2B3674"
      />
      <path
        d="M23.6997 40.3542V33.8147H24.9767V40.3542H23.6997Z"
        fill="#2B3674"
      />
      <path
        d="M26.615 40.3542V33.8147H27.892V40.3542H26.615ZM27.7122 37.6309V36.5559L30.3381 36.547V37.622L27.7122 37.6309ZM27.7122 34.8897V33.8147H30.464V34.8897H27.7122Z"
        fill="#2B3674"
      />
      <path
        d="M31.6472 40.3542V33.8147H32.8972V40.3542H31.6472ZM32.7174 40.3542V39.2792H35.694V40.3542H32.7174ZM32.7174 37.5682V36.4932H35.4602V37.5682H32.7174ZM32.7174 34.8897V33.8147H35.6221V34.8897H32.7174Z"
        fill="#2B3674"
      />
      <path
        d="M38.4972 40.3542V34.2626H39.1806V40.3542H38.4972ZM36.4468 34.4329V33.8147H41.231V34.4329H36.4468Z"
        fill="#2B3674"
      />
      <path
        d="M42.507 40.3542V33.7879H43.1905V40.3542H42.507ZM46.5808 40.3542L44.3416 37.4607H45.142L47.4172 40.3542H46.5808ZM42.9117 37.8728V37.2636H44.6743C44.9981 37.2636 45.2679 37.2009 45.4837 37.0755C45.6995 36.9501 45.8614 36.7799 45.9693 36.5649C46.0772 36.3499 46.1312 36.1051 46.1312 35.8303C46.1312 35.5556 46.0772 35.3108 45.9693 35.0958C45.8614 34.8808 45.6995 34.7106 45.4837 34.5851C45.2679 34.4538 44.9981 34.3881 44.6743 34.3881H42.9117V33.7879H44.5574C45.0431 33.7879 45.4537 33.8655 45.7895 34.0208C46.1312 34.1701 46.389 34.394 46.5629 34.6926C46.7427 34.9853 46.8327 35.3436 46.8327 35.7676V35.893C46.8327 36.3171 46.7427 36.6784 46.5629 36.977C46.383 37.2696 46.1252 37.4936 45.7895 37.6488C45.4537 37.7982 45.0431 37.8728 44.5574 37.8728H42.9117Z"
        fill="#2B3674"
      />
      <path
        d="M48.6809 40.3542V33.8147H49.3644V40.3542H48.6809ZM49.2025 40.3542V39.7361H52.4849V40.3542H49.2025ZM49.2025 37.3353V36.7262H52.1792V37.3353H49.2025ZM49.2025 34.4329V33.8147H52.386V34.4329H49.2025Z"
        fill="#2B3674"
      />
      <path
        d="M56.1248 40.5065C55.5733 40.5065 55.1086 40.4199 54.7309 40.2467C54.3532 40.0676 54.0655 39.8227 53.8676 39.5122C53.6758 39.1956 53.5798 38.8403 53.5798 38.4461H54.2633C54.2633 38.697 54.3232 38.9329 54.4432 39.1538C54.5691 39.3688 54.7669 39.545 55.0367 39.6824C55.3125 39.8137 55.6752 39.8794 56.1248 39.8794C56.5385 39.8794 56.8802 39.8197 57.15 39.7003C57.4258 39.5808 57.6297 39.4196 57.7616 39.2165C57.8994 39.0135 57.9684 38.7865 57.9684 38.5357C57.9684 38.2192 57.8425 37.9564 57.5907 37.7474C57.3449 37.5324 56.9732 37.404 56.4756 37.3622L55.7202 37.2905C55.1566 37.2427 54.7069 37.0696 54.3712 36.7709C54.0415 36.4664 53.8766 36.0662 53.8766 35.5705C53.8766 35.1943 53.9665 34.8628 54.1464 34.5762C54.3262 34.2895 54.581 34.0626 54.9108 33.8954C55.2465 33.7281 55.6482 33.6445 56.1158 33.6445C56.5895 33.6445 56.9942 33.7311 57.3299 33.9043C57.6716 34.0715 57.9324 34.3045 58.1123 34.6031C58.2921 34.9017 58.3821 35.248 58.3821 35.6422H57.6986C57.6986 35.4033 57.6446 35.1794 57.5367 34.9703C57.4288 34.7613 57.258 34.5941 57.0241 34.4687C56.7903 34.3373 56.4876 34.2716 56.1158 34.2716C55.7621 34.2716 55.4684 34.3313 55.2345 34.4508C55.0067 34.5702 54.8358 34.7285 54.7219 34.9256C54.614 35.1226 54.5601 35.3376 54.5601 35.5705C54.5601 35.8632 54.665 36.111 54.8748 36.3141C55.0847 36.5171 55.3874 36.6336 55.7831 36.6634L56.5385 36.7351C56.9822 36.7709 57.3599 36.8695 57.6716 37.0307C57.9894 37.186 58.2322 37.392 58.4001 37.6488C58.5679 37.8997 58.6519 38.1953 58.6519 38.5357C58.6519 38.9179 58.5469 39.2583 58.3371 39.5569C58.1333 39.8556 57.8425 40.0885 57.4648 40.2557C57.0871 40.4229 56.6404 40.5065 56.1248 40.5065Z"
        fill="#2B3674"
      />
      <path
        d="M63.1687 40.5065C62.5992 40.5065 62.1045 40.405 61.6849 40.2019C61.2652 39.9989 60.9175 39.7301 60.6417 39.3957C60.3659 39.0613 60.1591 38.697 60.0212 38.3028C59.8833 37.9086 59.8143 37.5234 59.8143 37.1472V36.9859C59.8143 36.5858 59.8833 36.1887 60.0212 35.7945C60.1651 35.3944 60.3779 35.0331 60.6597 34.7106C60.9415 34.3881 61.2922 34.1313 61.7119 33.9402C62.1315 33.7431 62.6171 33.6445 63.1687 33.6445C63.7263 33.6445 64.2119 33.7431 64.6256 33.9402C65.0452 34.1313 65.396 34.3881 65.6777 34.7106C65.9595 35.0331 66.1694 35.3944 66.3073 35.7945C66.4511 36.1887 66.5231 36.5858 66.5231 36.9859V37.1561C66.5231 37.5324 66.4541 37.9176 66.3163 38.3118C66.1844 38.6999 65.9775 39.0613 65.6957 39.3957C65.42 39.7301 65.0722 39.9989 64.6526 40.2019C64.2329 40.405 63.7383 40.5065 63.1687 40.5065ZM63.1687 39.8794C63.5884 39.8794 63.9631 39.8048 64.2928 39.6555C64.6226 39.5002 64.9014 39.2912 65.1292 39.0284C65.357 38.7597 65.5309 38.4581 65.6508 38.1236C65.7707 37.7892 65.8306 37.4398 65.8306 37.0755C65.8306 36.6933 65.7707 36.335 65.6508 36.0005C65.5309 35.6661 65.354 35.3705 65.1202 35.1137C64.8924 34.8509 64.6136 34.6449 64.2838 34.4956C63.9541 34.3463 63.5824 34.2716 63.1687 34.2716C62.761 34.2716 62.3923 34.3463 62.0626 34.4956C61.7328 34.6449 61.4511 34.8509 61.2172 35.1137C60.9894 35.3705 60.8126 35.6661 60.6867 36.0005C60.5668 36.335 60.5068 36.6933 60.5068 37.0755C60.5068 37.4398 60.5668 37.7892 60.6867 38.1236C60.8066 38.4581 60.9804 38.7597 61.2082 39.0284C61.4361 39.2912 61.7149 39.5002 62.0446 39.6555C62.3803 39.8048 62.755 39.8794 63.1687 39.8794Z"
        fill="#2B3674"
      />
      <path
        d="M68.0895 40.3542V33.7879H68.773V40.3542H68.0895ZM72.1634 40.3542L69.9241 37.4607H70.7245L72.9997 40.3542H72.1634ZM68.4942 37.8728V37.2636H70.2569C70.5806 37.2636 70.8504 37.2009 71.0662 37.0755C71.2821 36.9501 71.4439 36.7799 71.5518 36.5649C71.6598 36.3499 71.7137 36.1051 71.7137 35.8303C71.7137 35.5556 71.6598 35.3108 71.5518 35.0958C71.4439 34.8808 71.2821 34.7106 71.0662 34.5851C70.8504 34.4538 70.5806 34.3881 70.2569 34.3881H68.4942V33.7879H70.1399C70.6256 33.7879 71.0362 33.8655 71.372 34.0208C71.7137 34.1701 71.9715 34.394 72.1454 34.6926C72.3252 34.9853 72.4152 35.3436 72.4152 35.7676V35.893C72.4152 36.3171 72.3252 36.6784 72.1454 36.977C71.9655 37.2696 71.7077 37.4936 71.372 37.6488C71.0362 37.7982 70.6256 37.8728 70.1399 37.8728H68.4942Z"
        fill="#2B3674"
      />
    </svg>
  );
};

export default B120Logo;
