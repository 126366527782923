import ReactHtmlParser from "html-react-parser";
import { t } from "i18next";
import DefaultLayout from "../Layout/DefaultLayout";

const LifeSignal = ({ globalEvent, setGlobalEvent, title }) => {
  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        {ReactHtmlParser(t("lifesignal.description"))}
      </DefaultLayout>
    </>
  );
};
export default LifeSignal;
