import { fetchWithAuth } from "../api";

export const fetchPlans = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PLANS_REQUEST" });
    try {
      const data = await fetchWithAuth("plans");
      dispatch({ type: "FETCH_PLANS_SUCCESS", payload: data.plans });
    } catch (error) {
      dispatch({ type: "FETCH_PLANS_FAILURE", payload: error });
    }
  };
};