import { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import authHeader from './AuthHeader';

const DownloadFileWithAuth = (props) => { 
  const [download, setDownload] = useState(false);



  useEffect(() => {
    async function downloadApi() {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL+props.fileUrl,
          {
            responseType: "blob", // this is important!
            headers:  {...authHeader()},
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data])); 
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", props.fileName); 
        link.click();
       setTimeout(() => window.URL.revokeObjectURL(url), 0);
        setDownload(false);
      } catch (e) {} 
    }

    if (download) {
      downloadApi();
    }
  }, [download]);

  return (<Box {...props} onClick={() => setDownload(true)}>{props.children}</Box>);
}

export default DownloadFileWithAuth;