import React, { useState } from "react";
import { t } from "i18next";
import { SideBarCard } from "./SideBarCard";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import DialogReferral from "../../PopupHandler/DialogReferral";
import ReactHtmlParser from "html-react-parser";

export const ReferralCard = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <SideBarCard
        IconComponent={GroupAddOutlinedIcon}
        title={t("cards.referral.title")}
        description={t("cards.referral.description")}
        buttonText={t("cards.referral.buttonText")}
        buttonCallback={() => {
          setDialogOpen(true);
          console.log("invite callback");
        }}
      />
      <DialogReferral
        closeLabel={ReactHtmlParser(t("common.modal.close"))}
        dialogTitle={ReactHtmlParser(t("dialog.referral.title"))}
        dialogText={ReactHtmlParser(t("dialog.referral.text"))}
        isOpen={dialogOpen}
        subject={ReactHtmlParser(t("dialog.message.subject"))}
        url={
          window.location.origin +
          "/registration?referralToken=" +
          localStorage.getItem("referralToken")
        }
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};
