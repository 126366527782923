import deleteuserbg from "../Assets/DeleteUser-bg.png";
import { DelUsrStep1 } from "./DelUsrStep1";
import { DelUsrStep2 } from "./DelUsrStep2";
import { DelUsrStep3 } from "./DelUsrStep3";
import { DelUsrStep4 } from "./DelUsrStep4";
import { DelUsrStep5 } from "./DelUsrStep5";
import { ValidationStep1 } from "../ValidationComponents/ValidationStep1";
import { ValidationStep2 } from "../ValidationComponents/ValidationStep2";
import ReactHtmlParser from "html-react-parser";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import AuthLayout from "../Layout/ProfileLayout";
import { useDispatch } from "react-redux";
import { resetPlan } from "../../store/Slices/paymentSlice";

const DeleteUser = ({ globalEvent, setGlobalEvent, title, cStep }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenInvited, setTokenInvited] = useState("");

  const { t, i18n } = useTranslation();

  const mql = window.matchMedia("(max-width: 600px)");

  //tokenReferral
  //tokenInvited
  //consentToHandling
  //consentToMarketing

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ DeleteUser");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "showDelUsrStep1") {
      navigate("/deleteuser/step1");
    }

    if (globalEvent?.type == "showDelUsrStep2") {
      setStep(2);
      navigate("/deleteuser/step2");
    }

    if (globalEvent?.type == "showDelUsrStep3") {
      navigate("/deleteuser/step3");
    }

    if (globalEvent?.type == "showDelUsrStep4") {
      navigate("/deleteuser/step4");
    }

    if (globalEvent?.type == "showDelUsrStep5") {
      navigate("/deleteuser/step5", { replace: true });
    }
  }, [globalEvent]);

  useEffect(() => {
    setStep(cStep);
  }, [cStep]);

  useEffect(() => {
    if (window.location.pathname == "/deleteuser") {
    }
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [step, setStep] = useState(cStep);

  useEffect(() => {
    if (step === 7) {
      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("chid");
      }, 1000);
    }
  }, [step]);

  return (
    <>
      <AuthLayout
        backgroundImage={deleteuserbg}
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
      >
        <div className="login-container">
          {step === 1 ? (
            <DelUsrStep1
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 2 ? (
            <DelUsrStep2
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 3 ? (
            <DelUsrStep3
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 4 ? (
            <DelUsrStep4
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 5 ? (
            <DelUsrStep5
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 6 ? (
            <ValidationStep1
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
              validationType="deleteuser"
            />
          ) : step === 7 ? (
            <ValidationStep2
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
              title={"A fiók törlése sikeres!"}
              text={ReactHtmlParser(t("deleteuser.deleteUserValidation"))}
              link={"/"}
            />
          ) : (
            <DelUsrStep1
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          )}
        </div>
      </AuthLayout>
    </>
  );
};

export default DeleteUser;
