import React from "react";
import { Box, Fab, Grid, Stack, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";

const StyledFab = styled(Fab)(({ theme }) => ({
  backgroundColor: "white",
  color: "#2B3674",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  position: "fixed",
  right: "2%",
  zIndex: 1,
  top: "20vh",
  "&:hover": {
    backgroundColor: "white",
  },
  [theme.breakpoints.down("md")]: {
    bottom: "16px",
    top: "auto",
    right: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    right: "16px",
    bottom: "16px",
    top: "auto",
  },
}));

const FAB = () => {
  const navigate = useNavigate();

  return (
    <StyledFab onClick={() => navigate("/faq")}>
      <HelpOutlineIcon />
    </StyledFab>
  );
};

export default FAB;
