import { Button } from "@mui/material";
import LinkIcon from "@mui/icons-material/InsertLinkOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
	
import { useState, useEffect } from "react";
import { ConnectModal } from "./ConnectModal";

const ConnectButton = ({ type, globalEvent, setGlobalEvent}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => { setOpen(false) };
  const handleClick = () => {
							    if (type=='remove_google') setGlobalEvent({ type: "doSocialDisconnect", data: {user:{googleId:null}} });
								if (type=='remove_apple') setGlobalEvent({ type: "doSocialDisconnect", data: {user:{appleId:null}} });
							};
  

 useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ ConnectButton");
      console.log(globalEvent);
      console.log("------------");
    }
	
	if (globalEvent?.type == "responseSocialDisconnect") {
		handleClose()
	}

  }, [globalEvent]);

  return (
    <>
      <Button
        onClick={(ev)=>{setOpen(true)}}
        variant="text"
        sx={{
          minWidth: "50px",
          width: "50px !important",
          height: "50px",
          p: 0,
          boxShadow: "0px 0px 10px 0.2px #0000001A",
          borderRadius: "32px",
        }}
      >
	  {((type=='remove_google')||(type=='remove_apple'))&&(
	    <DeleteIcon sx={{ color: "info.secondary" }} />
	  )}
	  {((type=='add_google')||(type=='add_apple'))&&(
		<LinkIcon sx={{ color: "socialLink.main" }} />
	  )}		
      </Button>
	  

	  
      <ConnectModal type={type} open={open} handleClose={handleClose} handleClick={handleClick} />
    </>
  );
};

export default ConnectButton;
