import React, { useEffect, useState } from 'react';
import { checkHasToken, checkTokenIsValid } from "../Components/DataModule/tokenFunctions";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const tokenExists = checkHasToken();

    if (!tokenExists || !checkTokenIsValid()) {
      setShouldRedirect(true);
    } else {
      setShouldRedirect(false);
    }
  }, [dispatch]);

  if (shouldRedirect) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthProvider;
