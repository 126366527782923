import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Stack,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { t } from "i18next";
import { ArrowUpward } from "@mui/icons-material";

function PricingCard({ data, selected, onSelect }) {
  const [hide, setHide] = useState(true);

  const handleHide = () => {
    setHide(!hide);
  };

  useEffect(() => {
    console.log("HELÓÓ");
    console.log(data);
  });

  return (
    <Card
      variant="outlined"
      sx={{
        m: 1,
        bgcolor: data.billingCycle === null ? "#FFF27E40" : "#fff",
        border: selected ? "2px solid #62C275" : "1px solid #ddd",
        borderRadius: "32px",
        padding: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, .1)",
      }}
    >
      <CardContent>
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          sx={{
            justifyContent: "space-between",
            mb: 2,
            alignItems: {
              xs: "left",
              sm: "center",
            },
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              bgcolor: data.billingCycle === null ? "primary.main" : "#FFF27E",
              color: data.billingCycle === null ? "#FFF27E" : "primary.main",
              borderRadius: "8px",
              p: "10px 20px",
              lineHeight: "18px",
            }}
          >
            {data?.localized?.name.toUpperCase()}
          </Typography>
          <Stack sx={{ textAlign: { sm: "right" } }}>
            <Typography
              variant="body3"
              gutterBottom
              component="div"
              color="primary"
              sx={{
                fontSize: 24,
                fontWeight: 800,
              }}
            >
              {data.price} {t(`currencySymbols.${data.currency}`)}
            </Typography>
            {data.price > 0 ? (
              <Typography
                variant="body4"
                sx={{ fontWeight: 700, color: "rgba(43, 54, 116, .6)" }}
              >
                {t(`billingCycle.${data.billingCycle}`)}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
        </Stack>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: 14,
            fontWeight: 700,
            color: "#2B3674",
          }}
        >
          {data?.localized?.description}
        </Typography>
        <Divider
          sx={{
            mt: 3,
            bgcolor: "#EBEBEB",
          }}
        ></Divider>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Button
          variant="text"
          onClick={handleHide}
          endIcon={hide ? <ArrowDownwardIcon /> : <ArrowUpward />}
          type="small"
          sx={{
            fontSize: 14,
            order: {
              xs: 2,
              sm: 1,
            },
          }}
        >
          {t("common.packagedescription")}
        </Button>
        <Button
          variant="contained"
          endIcon={selected ? <CheckIcon /> : <ArrowForwardIcon />}
          onClick={() => {
            console.log("asd", data);
            onSelect(data.name);
          }}
          sx={{
            order: {
              xs: 1,
              sm: 2,
            },
            lineHeight: "20.16px",
            my: 0,
            py: 2,
            px: 4,
            width: { xs: "100%", md: "auto" },
            justifyContent: "space-between",
            bgcolor: "#62C275",
            borderRadius: "50px",
            boxShadow: "none",
            fontSize: "16px",
            fontWeight: 600,
            color: "#fff",
            "&:hover": {
              bgcolor: "#4D975C",
              boxShadow: "none",
            },
          }}
        >
          {selected ? t("subscription.selected") : t("subscription.select")}
        </Button>
      </CardActions>
      {hide === false && (
        <CardContent>
          <List>
            {data?.localized?.contents &&
              data.localized.contents.map((desc, index) => {
                return (
                  <ListItem
                    key={desc}
                    sx={{
                      px: 0,
                      pt: 2,
                      pb: 2,
                      borderTop: "solid 1px #EBEBEB",
                      lineHeight: "17.64px",
                      fontSize: "14px",
                    }}
                  >
                    {desc}
                  </ListItem>
                );
              })}
          </List>
        </CardContent>
      )}
    </Card>
  );
}

export default PricingCard;
