import {
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  Box,
  Link,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactHtmlParser from "html-react-parser";
import { Mailto } from "../../Commons/Mailto";

export const SideBarCard = ({
  IconComponent,
  title,
  description,
  link,
  linkText,
  buttonText,
  buttonCallback,
  mailto,
  mailtoText,
  subject,
  body,
  buttonColor = "main.light",
}) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "1px solid",
        borderColor: "#2B36741A",
        borderRadius: "16px",
        px: { xs: 1, sm: 2 },
        pt: { xs: 1, sm: 2 },
        pb: { xs: 1, sm: 1 },
        mt: 2,
      }}
    >
      <CardContent>
        <Stack direction={{ xs: "column" }} spacing={2} alignItems={"left"}>
          <Box
            bgcolor="rgba(248, 248, 248, 1)"
            borderRadius={100}
            maxWidth="34px"
            p="10px"
            display="inline-flex"
          >
            {IconComponent ? (
              <IconComponent
                fontSize="large"
                htmlColor="rgba(43, 54, 116, 1)"
                xs={{
                  color: "rgba(43, 54, 116, 1)",
                }}
              />
            ) : null}
          </Box>

          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                mb: 1,
              }}
            >
              {ReactHtmlParser(title)}
            </Typography>

            <Typography
              variant="body3"
              sx={{
                mt: 1,
                mb: 2,
                color: "rgba(43, 54, 116, 0.6)",
                lineHeight: "22px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {ReactHtmlParser(description)}
            </Typography>

            {mailto && mailtoText && (
              <Mailto
                className="green-text-link"
                email={mailto}
                subject={subject}
                body={body}
                variant="caption"
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#62C275",
                  textDecoration: "none",
                  mt: 1,
                }}
              >
                {mailtoText}

                <ArrowForwardIcon sx={{ fontSize: "inherit", ml: 1 }} />
              </Mailto>
            )}

            {link && linkText && (
              <Link
                className="green-text-link"
                href={link}
                variant="caption"
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#62C275",
                  textDecoration: "none",
                  mt: 1,
                }}
              >
                {linkText}

                <ArrowForwardIcon sx={{ fontSize: "inherit", ml: 1 }} />
              </Link>
            )}

            {buttonText && buttonCallback && (
              <Button
                variant="contained"
                className="secondary-blue-btn"
                sx={{
                  bgcolor:
                    buttonColor +
                    (buttonColor !== "main.light" ? "!important" : ""),
                  height: "50px",
                  mt: 2,
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                onClick={() => buttonCallback()}
              >
                {buttonText}
                <ArrowForwardIcon
                  sx={{ fontSize: "inherit", strokeWidth: "1", ml: 3 }}
                />
              </Button>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
