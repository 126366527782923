import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithLocale } from '../api';

const initialState = {
    messages: [],
    dismissedMessageIds: JSON.parse(localStorage.getItem('dismissedMessageIds') || '[]'),
    status: 'idle',
    error: null,
};

export const fetchSystemMessages = createAsyncThunk('systemMessages/fetchSystemMessages', async () => {
    const data = await fetchWithLocale('system-messages');
    return data;
});

const systemMessagesSlice = createSlice({
    name: 'systemMessages',
    initialState,
    reducers: {
        dismissMessage: (state, action) => {
            state.dismissedMessageIds.push(action.payload);
            localStorage.setItem('dismissedMessageIds', JSON.stringify(state.dismissedMessageIds));
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSystemMessages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSystemMessages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.messages = action.payload.filter((message) =>
                    !state.dismissedMessageIds.includes(message.id));
            })
            .addCase(fetchSystemMessages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { dismissMessage } = systemMessagesSlice.actions;

export default systemMessagesSlice.reducer;
