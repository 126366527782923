import React from "react";
import { Button } from "@mui/material";

export const RegistrationButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      variant="contained"
      className={`fullwidth-nextbtn ${props.bColor}`}
      sx={{
        maxWidth: { xs: "310px", md: "375px" },
        minWidth: { xs: "310px", md: "375px" },
      }}
    >
      {props.text}
    </Button>
  );
};
