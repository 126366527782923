import { Outlet, useNavigate } from "react-router-dom";
import loginbg from "../Assets/login-background.png";
import AuthLayout from "./ProfileLayout";
import { createContext, useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loadSelectedPlan } from "../../store/Slices/paymentSlice";
import { fetchPlans } from "../../store/Actions/planAction";

const HandleNextContext = createContext({ handleNext: () => {} });

const PaymentLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {paymentIntentSecret} = useSelector(state => state.payment);

  const handleNext = (step) => {
    switch (step) {
      case "step1":
        return navigate("/payment");
      case "step2":
        return navigate("/payment/card-data");
      default:
        return navigate("/payment");
    }
  };

  useEffect(() => {
    dispatch(loadSelectedPlan());
    dispatch(fetchPlans());
  }, []);

  return (
    <HandleNextContext.Provider value={{ handleNext }}>
      <AuthLayout backgroundImage={loginbg}>
        <div className="login-container">
          <Box
            sx={{
              mt: 16,
              px: { xs: 2, md: 0 },
            }}
          >
            <Outlet context={{ handleNext }} />
          </Box>
        </div>
      </AuthLayout>
    </HandleNextContext.Provider>
  );
};

export default PaymentLayout;
export { HandleNextContext };
