const exchangeVal = function (val, from, to) {
	if(from === to) return val;

	try {
	  const exchangeRates = JSON.parse(localStorage.getItem('exchangeRates'))[0];
	  if (!exchangeRates || !exchangeRates.details) throw new Error("Exchange rates not found in local storage");

	  const fromRate = from !== "usd" ? exchangeRates.details[from].inverseRate : 1;
	  const toRate = to !== "usd" ? exchangeRates.details[to].rate : 1;
  
	  return val * fromRate * toRate;
	} catch (error) {
	  console.error("Error calculating exchange value:", error);
	  return 0;
	}
  };
  
  export default exchangeVal;
  