import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationModal = ({
  open,
  onClose,
  title,
  description,
  cancelText = "Cancel",
  confirmText = "Confirm",
  confirmIcon: ConfirmIcon,
  confirmIconColor = "inherit",
  onConfirm,
  icon: Icon,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            sx={{
              width: 100,
              maxWidth: 100,
              height: 100,
              p: "50px",
              bgcolor: "#FFF",
              boxShadow: "0 0 50px rgba(0,0,0,0.1)",
              borderRadius: "100px",
              mb: 3,
            }}
          >
            {Icon && React.isValidElement(Icon) ? Icon : Icon && <Icon sx={{ mr: 1, fontSize: "100px", color: "#2B3674" }} />}
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="div"
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "40px",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography
            id="modal-description"
            sx={{
              my: 2,
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "22px",
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            onClick={onClose}
            sx={{ mr: 1, color: "#1542B9", width: "50%" }}
          >
            {cancelText}
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack sx={{mr: 1}}>
            {confirmText}
            </Stack>
            {" "}
            {ConfirmIcon && React.isValidElement(ConfirmIcon) ? (
              React.cloneElement(ConfirmIcon, { color: { ml: 1, color: confirmIconColor } })
            ) : (
              ConfirmIcon && <ConfirmIcon color={confirmIconColor} sx={{ ml: 1 }} />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FAFCFC",
  boxShadow: 24,
  p: "64px",
  borderRadius: "32px",
};

export default ConfirmationModal;
