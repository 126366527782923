import React, { useEffect, useReducer, useState, Suspense } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCardOutlined";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";


export const EmptyCard = ({}) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  
  return (
    <Card
      sx={{
        borderRadius: "16px",
        p: 4,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent sx={{ display: "flex", justifyContent: "center" }}>
        <Stack
          direction={"column"}
          spacing={2}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "64px",
              height: "80px",
              width: "80px",
              bgcolor: "#F8F8F8",
            }}
          >
            <CreditCardIcon />
          </Box>
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {ReactHtmlParser(t("billing.noBillingTitle"))}
          </Typography>
          <Typography variant="body2">
            {ReactHtmlParser(t("billing.noBillingText"))}
          </Typography>          
        </Stack>
      </CardContent>
    </Card>
  );
};
