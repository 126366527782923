import { t } from "i18next";
import { fetchWithAuth } from "../api";
import exchangeVal from "../../Components/Commons/CurrencyExchange";

export const fetchAssets = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_REQUEST" });
    try {
      const data = await fetchWithAuth("assets");
      dispatch({ type: "FETCH_ASSETS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_ASSETS_FAILURE", payload: error });
    }
  };
};

export const prepareAssetCards = (items) => {
  const vCards = [];
  const assetCounts = {
    realEstate: 0,
    bankAccount: 0,
    credit: 0,
    housingSavings: 0,
    insurance: 0,
    security: 0,
    share: 0,
    vehicle: 0,
    claim: 0,
    crypto: 0,
    other: 0,
  };

  const sumsLocal = {
    realEstate: 0,
    bankAccount: 0,
    credit: 0,
    housingSavings: 0,
    insurance: 0,
    security: 0,
    share: 0,
    vehicle: 0,
    claim: 0,
    crypto: 0,
    other: 0,
  };

  //const filteredItems = items.filter(
    //(item) => item.value !== null && item.currency !== null
  //);
  const filteredItems = items;

  filteredItems.forEach((item) => {
    const type = item.assetType;
    let val = 0;
    val = !isNaN(item.value * 1) ? item.value * 1 : 0;
    if (item.assetType === "credit") {
      val = val > 0 ? val * -1 : val;
    }
    const bCurrency = localStorage.getItem("baseCurrency")
      ? localStorage.getItem("baseCurrency").toLowerCase()
      : "huf";
    if (item.currency?.toLowerCase() == bCurrency?.toLowerCase()) {
      sumsLocal[type] =
        (!isNaN(sumsLocal[type] * 1) ? sumsLocal[type] * 1 : 0) + val;
      sumsLocal["total"] =
        (!isNaN(sumsLocal["total"] * 1) ? sumsLocal["total"] * 1 : 0) + val;
    } else {
      val = exchangeVal(
        val,
        item.currency ? item.currency.toLowerCase() : "huf",
        "usd"
      );
      val = Math.ceil(exchangeVal(val, "usd", bCurrency.toLowerCase()) * 1);
      sumsLocal[type] =
        (!isNaN(sumsLocal[type] * 1) ? sumsLocal[type] * 1 : 0) + val;
      sumsLocal["total"] =
        (!isNaN(sumsLocal["total"] * 1) ? sumsLocal["total"] * 1 : 0) + val;
    }
  });

  filteredItems.forEach((item) => {
    const type = item.assetType;
    assetCounts[type] += 1;

    if (!vCards.some((card) => card.type === type)) {
      const typeMap = {
        bankAccount: "bank-account",
        houseSavings: "housing-savings",
        realEstate: "real-estate",
      };

      const alterType = typeMap[type] || type;

      vCards.push({
        key: vCards.length,
        type: type,
        vtype: "valuable",
        icon: type,
        title: t(`asset-type.${type}`) + ` (${assetCounts[type]})`,
        info: sumsLocal[type],
        currency: "usd",
        linTo: "/asset-list",
        linToData: { filterType: type },
      });
    } else {
      const index = vCards.findIndex((card) => card.type === type);
      vCards[index].title = t(`asset-type.${type}`) + ` (${assetCounts[type]})`;
    }
  });

  return {
    type: "PREPARE_ASSET_CARDS",
    payload: vCards,
  };
};
