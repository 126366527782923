import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCardOutlined";


export const BillingCard = ({
  icon,
  title,
  info,
  linkTo,
  value,
  currency
}) => {
  const formatValue = (price) => {
    let formatedPrice = price?.toLocaleString("fr-FR");
    return formatedPrice.replaceAll(" ", "  ");
  };


  return (
    <Card
      onClick={linkTo}
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Box>
                <CreditCardIcon
                  sx={{
                    color: "primary.main",
                    width: "32px",
                    height: "32px",
                  }}
                />
              </Box>

              <Stack>
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {title}{" "}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 400,
                    color: "primary.main",
                  }}
                >
                  {info}
                </Typography>
              </Stack>
			</Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
             <Typography
                  variant="body2"
                >
                      {`${formatValue(
                        Math.ceil(value)
                      )} ${currency}`}
                </Typography>
            <Button
              variant="text"
              sx={{ minWidth: "44px", width: "44px !important", height: "44px" }}
            >
			  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2Z" fill="#2B3674"/>
			  </svg>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
