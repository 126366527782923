import { MainMenu } from "../Navigation/MainMenu";
import { MainMobileMenu } from "../Navigation/MainMobileMenu";
import { Footer } from "../Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkHasToken, checkTokenIsValid } from "../DataModule/tokenFunctions";
import { useDispatch, useSelector } from "react-redux";
import { DesktopTopMenu } from "../Navigation/HomeNavigation/DesktopTopMenu";
import { Alert, Box, Snackbar } from "@mui/material";
import { MobileTopMenu } from "../Navigation/HomeNavigation/MobileTopMenu";
import mainBackground from "../Assets/home-welcome-bg.png";
import mobileBackground from "../Assets/daniel-j-schwarz-YtY724tdl7Y-unsplash 1.png";
import { getCurrentUser, logoutUser, recoverProfile } from "../../store/Actions/userAction";
import { checkAccess, fetchHats } from "../../store/Slices/hatsSlice";
import { hideSnackbar } from "../../store/Slices/snackbarSlice";
import { fetchExchangeRates } from "../../store/Slices/exchangeRatesSlice";
import FAB from "../Common/FAB";
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import { t } from "i18next";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";

const DefaultLayout = ({ children, globalEvent, setGlobalEvent }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.user);
  const { status: hatsStatus } = useSelector((state) => state.hats);
  const snackbar = useSelector((state) => state.snackbar);
  const { status: exchangeRatesStatus } = useSelector(
    (state) => state.exchangeRates
  );
  const {user} = useSelector((state) => state.user);
  const [recoverOpen, setRecoverOpen] = useState(false);
  const navigate = useNavigate();

  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  const chid = localStorage.getItem('chid');

  useEffect(() => {
    dispatch(getCurrentUser());
    if (typeof setGlobalEvent === "function") {
      setGlobalEvent({ type: "doGetUser", data: {} });
    }

    if (exchangeRatesStatus === "idle") {
      dispatch(fetchExchangeRates());
    }

    console.log('USER LOG', loggedIn, user);
  }, []);

  useEffect(() => {
    if(chid && chid !== 'null') {
      dispatch(checkAccess(chid));
    }

    if(user && user?.deletedAt) {
      console.log('user deleted at', user);
      setRecoverOpen(true);
    }
  }, [chid, dispatch, hatsStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, location.pathname);

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia("(max-width: 900px)");
      setMobileView(mql.matches);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSnackbarClose = () => {
    dispatch(hideSnackbar());
  };

  const handleRecoverClose = () => {
    setRecoverOpen(false);
    dispatch(logoutUser());
    navigate('/login');
  };

  const handleRecover = () => {
    dispatch(recoverProfile());
    setRecoverOpen(false);
  };

  return (
    <>
      {loggedIn && user?.id ? (
        !mobileView ? (
          <MainMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
        ) : (
          <MainMobileMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
        )
      ) : !mobileView ? (
        <>
          <DesktopTopMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MobileTopMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
        </Box>
      )}
      <div class="my-container">
        <div style={{ flex: 1, position: "relative" }}>
          <FAB />
        </div>
      </div>
      <div style={{ flex: 1, position: "relative" }}>{children}</div>

      <Footer />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      

      <ConfirmationModal
          open={recoverOpen}
          onClose={handleRecoverClose}
          title={t("recoverProfileModal.title")}
          description={t("recoverProfileModal.text")}
          cancelText={t("recoverProfileModal.cancelBtn")}
          confirmText={t("recoverProfileModal.confirmBtn")}
          onConfirm={handleRecover}
          icon={<HandGestureIcon />}
        />
    </>
  );
};

export default DefaultLayout;
