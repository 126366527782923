import React, { useState } from 'react';

import { 
Button, 
Dialog, 
DialogTitle,
DialogContent, 
DialogContentText,
DialogActions,
Typography,
Box,
TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import upgradeImg from '../../Assets/Phone-Smiley--Streamline-Ux.svg.svg'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DialogPlanUpg = ({ dialogTitle, dialogText, closeLabel, upgradeLabel, isOpen, onClose, onUpgrade, url, subject  }) => {
  const navigate = useNavigate();
  const style = {    
	'& .MuiPaper-root': {
    borderRadius: "32px",
    bgcolor: "background.paper",
    boxShadow: "4px 4px 20px 0px #0000001A",
    textAlign: "center",
	padding: 6
    },	
  };

	return (
    <Dialog disableRestoreFocus open={isOpen} onClose={onClose} sx={style}>
      <DialogTitle>
	        
        <Box
              component="img"
              alt="background"
              src={upgradeImg}
              sx={{
                maxWidth: "100%",
                height: "auto",
              }}
            ></Box>
		  
	  <Typography id="modal-modal-title" variant="h6" component="h2">
      {dialogTitle}
      </Typography>
	  </DialogTitle>
        <DialogContent>
          <DialogContentText>
		  <Typography
            id="modal-modal-description"
            variant="body3"
            sx={{ mt: 2 }}
          >
		  {dialogText}
          </Typography>
          </DialogContentText>
		  
		  
        </DialogContent>
        <DialogActions sx={{alignItems: "center"}}>
          <Button onClick={onClose} sx={{mr: 3}}>{closeLabel}</Button>
		            <Button
            variant="contained"
            className="small-btn green-btn"
            sx={{
              lineHeight: "20.16px",
              my: 3,
              py: 2,
              px: 4,
            }}
			onClick={onUpgrade}
            endIcon={
              <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z" fill="white"/>
              </svg>
              
            }
          >
			{upgradeLabel}
          </Button>
		  
        </DialogActions>	
    </Dialog>
  );
}
export default DialogPlanUpg;