import React, { useEffect, useState, useCallback, useMemo } from "react";
import { t } from "i18next";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Stack,
  MenuItem,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/Actions/userAction";
import ProfileImage from "../Profile/ProfileImage";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import CheckIcon from "@mui/icons-material/Check";
import LogoffIcon from "../Commons/Icons/LogoffIcon";
import ProfileImageDisplayer from "../Profile/ProfileImageDisplayer";
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";

const DialogProfile = ({ globalEvent, setGlobalEvent, isOpen, onClose }) => {
  const style = useMemo(
    () => ({
      "& .MuiPaper-root": {
        borderRadius: "1rem",
        bgcolor: "background.paper",
        boxShadow: "4px 4px 20px 0px #0000001A",
        padding: 0,
        minWidth: "300px",
        top: "2rem",
        right: 0,
        position: { md: "absolute" },
      },
    }),
    []
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const [hats, setHats] = useState();
  const [chid, setChid] = useState(localStorage.chid);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = useCallback(() => {
    dispatch(logoutUser());
    onClose();
    setIsModalOpen(false);
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/", { replace: true });
    }
  }, [dispatch, navigate, location.pathname, onClose]);

  const showProfile = useCallback(() => {
    onClose();
    navigate("/profile");
  }, [navigate, onClose]);

  const logOff = () => {
    setIsModalOpen(true);
    onClose();
  };

  const handleHatChange = useCallback(
    (currentHatId, newHatId) => {
      if (currentHatId !== newHatId) {
        setGlobalEvent({ type: "doChangeHat", data: { hatId: newHatId } });
      }
    },
    [setGlobalEvent]
  );

  useEffect(() => {
    if (globalEvent?.type === "responseGetUser") {
      setUser(globalEvent?.data.user);
    } else if (globalEvent?.type === "responseGetHat") {
      setHats(globalEvent?.data.hats);
    } else if (globalEvent?.type === "responseChangeHat") {
      setChid(globalEvent?.data.currentHatId);
      onClose();
    }
  }, [globalEvent, onClose]);

  return (
    <>
    <Dialog disableRestoreFocus open={isOpen} onClose={onClose} sx={style}>
      <DialogContent sx={{ px: 1, pb: 2, pt: 3 }}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 1 }}
        >
          <Box sx={{ position: "relative", width: 60, height: 60 }}>
            <ProfileImage withUpload={true} version="popup" pos="right" />
          </Box>
          <Box>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={3}
              alignItems="center"
              justifyContent="start"
            >
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "17px",
                  }}
                >
                  {user?.lastName} {user?.firstName}
                </Typography>
                <Typography
                  variant="body3"
                  sx={{ fontSize: "10px", lineHeight: "12.64px", color: 'rgba(43, 54, 116, .6)' }}
                >
                  {user?.email}
                </Typography>

                <Button
                  className="secondary-blue-text-btn"
                  variant="text"
                  sx={{
                    mt: 1,
                    fontSize: "12px",
                    lineHeight: "15px",
                    textAlign: "center",
                    display: "block",
                    width: "100%",
                    fontWeight: 500,
                  }}
                  onClick={showProfile}
                >
                  {t("profile.settings")}
                </Button>
              </div>
            </Stack>
          </Box>
        </Stack>
        {hats?.length > 0 ? (
        <MenuItem
          sx={{ borderTop: "solid 1px #ebebeb", px: 3, py: 2 }}
          onClick={() => handleHatChange(chid, null)}
          direction="row"
          spacing={2}
          alignItems="center"
        >
          {chid && chid !== "null" ? (
            <Button
              variant="text"
              sx={{ minWidth: "32px", width: "32px", height: "32px", mr: 2 }}
            >
              <PublishedWithChangesIcon />
            </Button>
          ) : (
            <Button
              variant="text"
              sx={{ minWidth: "32px", width: "32px", height: "32px", mr: 2 }}
            >
              <CheckIcon />
            </Button>
          )}
          <Stack direction="column" alignItems="start">
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: "primary.main",
                fontSize: "12px",
                lineHeight: "15.12px",
              }}
            >
              {t("profile.myself")}
            </Typography>
          </Stack>
        </MenuItem>)
        : (<></>)}
        {hats?.map((i) => (
          <MenuItem
            key={i.id}
            sx={{ borderTop: "solid 1px #ebebeb", px: 3, py: 2 }}
            onClick={() => handleHatChange(chid, i.id)}
            direction="row"
            spacing={2}
            alignItems="center"
          >
            {chid !== i.id ? (
              <Button
                variant="text"
                sx={{ minWidth: "32px", width: "32px", height: "32px", mr: 2 }}
              >
                <ProfileImageDisplayer name={i.owner} version="hat" />
              </Button>
            ) : (
              <Button
                variant="text"
                sx={{ minWidth: "32px", width: "32px", height: "32px", mr: 3 }}
              >
                <CheckIcon />
              </Button>
            )}
            <Stack direction="column" alignItems="start">
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: "primary.main",
                  fontSize: "12px",
                  lineHeight: "15.12px",
                }}
              >
                {t("profile.baccount")}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 300,
                  color: "rgba(43, 54, 116, .6)",
                  fontSize: "10px",
                  lineHeight: "15px",
                }}
              >
                {i.owner} {t("profile.baccountowner", { name: i.owner })}
              </Typography>
            </Stack>
          </MenuItem>
        ))}

        <MenuItem
          sx={{ borderTop: "solid 1px #ebebeb", px: 3, py: 2 }}
          onClick={logOff}
        >
          <Stack direction="row" spacing={0} alignItems="center">
            <Box>
              <Button sx={{ minWidth: "32px", width: "32px", mr: 1, p: 0 }}><LogoffIcon sx={{ width: "32px" }}/></Button>
            </Box>
            <Stack>
              <Typography
                variant="body4"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "15.12px",
                }}
              >
                {t("mainMenu.logout")}
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: 400, color: "primary.main" }}
              ></Typography>
            </Stack>
          </Stack>
        </MenuItem>
      </DialogContent>
    </Dialog>

    <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={t('common.modal.logout_title')}
        description={t('common.modal.logout_description')}
        cancelText={t('common.modal.cancel')}
        confirmText={t('common.modal.logout_confirm')}
        confirmIcon={LogoffIcon}
        confirmIconColor="#E5FE4A"
        onConfirm={handleConfirm}
        icon={<HandGestureIcon />}
      />
    </>
  );
};

export default DialogProfile;
