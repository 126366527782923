import { useEffect } from "react";
import Entry from "./Entry";
import { Typography } from "@mui/material";
import { t } from "i18next";

const EntryList = ({ entries, isLoading, error, entryType, displayMenu }) => {
  return (
    <div>
      {isLoading ? (
        <Typography variant="h6">{t("timeCapsule.loading")}</Typography>
      ) : error?.length > 0 ? (
        <Typography variant="h6">{t("timeCapsule.error_loading")}</Typography>
      ) : entries?.length ? (
        entries?.map((entry) => (
          <Entry key={entry.id} entryData={entry} entryType={entryType} displayMenu={displayMenu} />
        ))
      ) : (
        ""
      )}
    </div>
  );
};

export default EntryList;
