import {
  Box,
  Typography,
  Button,
  Stack,
  TextField,
  FormControl,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadSelectedPlan } from "../../store/Slices/paymentSlice";

import { PinInput, StatefulPinInput } from "react-input-pin-code";

export const ValidationStep1 = ({
  globalEvent,
  setGlobalEvent,
  validationType,
}) => {
  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const pinLength = 6;
  const [pin, setPin] = useState(new Array(pinLength).fill(""));
  const [activePin, setActivePin] = useState(0);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { plan } = useSelector((state) => state.payment);

  const handleOnchange = ({ target }, index) => {
    const { value } = target;
    const newPin = [...pin];
    newPin[index] = value;

    if (value) {
      setActivePin(index + 1);
    }

    setPin(newPin);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (pin[activePin] === "") {
        setActivePin(index - 1);
      } else {
        setActivePin(index);
      }
    }
    if (e.ctrlKey && (e.key === "v" || e.key === "V")) {
      navigator.clipboard.readText().then((cliptext) => {
        if (cliptext.length >= pinLength) {
          let aryTemp = cliptext.substring(0, pinLength).split("");
          setPin(aryTemp);
        } else {
          while (cliptext.length < pinLength) {
            cliptext += " ";
          }
          setPin(cliptext.substring(0, pinLength).split(""));
        }
      });
      setActivePin(5);
    }
  };

  const resendCode = () => {
    setGlobalEvent({
      type: "doResendCode",
      data: { validationType: validationType },
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ Validation");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseEmailConfirm") {
      if (plan !== "free") {
        navigate("/payment", { replace: true });
      } else {
        navigate("/", { replace: true });
        window.location.reload();
      }
    }

    if (globalEvent?.type == "responseChangePasswordConfirm") {
      navigate("/profile", { replace: true });
    }

    if (globalEvent?.type == "responseSaveUserData") {
      navigate("/profile", { replace: true });
    }

    if (globalEvent?.type == "responseSaveUserDataError") {
      
	  setPin(new Array(pinLength).fill(""));
      setActivePin(0);
      document.activeElement.parentElement.firstChild.focus();

	  
	  if(globalEvent?.data?.errors?.length > 0 && globalEvent?.data?.errors[0].message) {
        if (globalEvent?.data?.toastType) {globalEvent.data.errors[0].toastType = globalEvent.data.toastType};
		setGlobalEvent({
          type: "showToast",
          data: {
            message: t(`errorResponse.${globalEvent?.data?.errors[0].message}`), toastType: 'error'
          }
        });
      } else {
        navigate("/add-user-data", { replace: true });
      }
    }

    if (globalEvent?.type == "responseChangeEmailSave") {
      navigate("/profile", { replace: true });
    }

    if (globalEvent?.type == "responseEmailConfirmError") {
      setPin(new Array(pinLength).fill(""));
      setActivePin(0);
      document.activeElement.parentElement.firstChild.focus();

      setGlobalEvent({
        type: "showToast",
        data: { message: t("verify.error"), toastType: 'error' },
      });
    }

    if (globalEvent?.type == "responseLoginError") {
      setPin(new Array(pinLength).fill(""));
      setActivePin(0);
      document.activeElement.parentElement.firstChild.focus();

      if (
        globalEvent?.data?.errors?.length > 0 &&
        globalEvent?.data?.errors[0].message
      ) {
        if (globalEvent?.data?.toastType) {globalEvent.data.errors[0].toastType = globalEvent.data.toastType};
		setGlobalEvent({
          type: "showToast",
		  data: globalEvent?.data?.errors[0]
        });
      } else {
        setGlobalEvent({
          type: "showToast",
          data: { message: t("login.error"), toastType: 'error' },
        });
      }
    }

    if (globalEvent?.type == "responseChangePasswordConfirmError") {
      setPin(new Array(pinLength).fill(""));
      setActivePin(0);
      document.activeElement.parentElement.firstChild.focus();
      
	  setGlobalEvent({
        type: "showToast",
        data: { message: t("verify.error") },
      });
    }

    if (globalEvent?.type == "responseResend") {
      setGlobalEvent({
        type: "showToast",
        data: { message: t("verify.codesent") },
      });
    }

    dispatch(loadSelectedPlan());
  }, [globalEvent]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activePin]);

  useEffect(() => {
    console.log(pin);
    if (activePin == 6)
      setTimeout(() => {
        if (validationType == "userdata") {
          setGlobalEvent({
            type: "doSaveUserData",
            data: { verificationCode: pin.join("") },
          });
        } else if (validationType == "login") {
          setGlobalEvent({
            type: "doLoginConfirm",
            data: { verificationCode: pin.join("") },
          });
        } else if (validationType == "changepassword") {
          setGlobalEvent({
            type: "doChangePasswordConfirm",
            data: { verificationCode: pin.join("") },
          });
        } else if (validationType == "deleteuser") {
          setGlobalEvent({
            type: "doDeleteUserConfirm",
            data: { verificationCode: pin.join("") },
          });
        } else if (validationType == "changeemail") {
          setGlobalEvent({
            type: "doChangeEmailSave",
            data: { verificationCode: pin.join("") },
          });
        } else {
          if(pin.join("").length == 6) {
            setGlobalEvent({
              type: "doEmailConfirm",
              data: { emailVerificationCode: pin.join("") },
            });
          }
        }
      }, 300);
  }, [pin, activePin]);

  useEffect(() => {
    console.log(validationType);
  });

  return (
    <Box
      sx={{
        mt: { xs: 0, sm: 16 },
        px: { xs: 2, md: 1, lg: 0 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("verify.title"+(validationType == ""?"":"_"+validationType)))}
        </Typography>
        <Typography variant="body3">
          {ReactHtmlParser(t("verify.text"+(validationType == ""?"":"_"+validationType)))}
        </Typography>
      </Box>
      <FormControl variant="standard" sx={{ mt: 3 }}>
        <StatefulPinInput
          values={pin}
          onChange={(value, index, values) => {
            setPin(values);
          }}
          size={"lg"}
          onComplete={() => {
            setActivePin(6);
          }}
          placeholder=" "
          autoFocus={true}
          length={pinLength}
          showState={false}
          inputStyle={{
            width: "72.96px",
            height: "80px",
            fontSize: "56px",
            fontWeight: 400,
            lineHeight: "80px",
            zoom: mobileView ? 0.6 : 1,
          }}
        />

        <Stack
          direction={"row"}
          spacing={{ xs: 1, lg: 2 }}
          justifyContent={"center"}
          sx={{ display: "none" }}
        >
          {pin.map((_, key) => {
            return (
              <TextField
                key={key}
                inputProps={{
                  maxLength: 1,
                  focus: key === activePin ? "true" : "false",
                  autoFocus: key === activePin ? true : false,
                  ref: key === activePin ? inputRef : null,
                  type: "number",
                  inputMode: "numeric",
                  pattern: "\\d*",
                }}
                onChange={(e) => handleOnchange(e, key)}
                onKeyDown={(e) => handleKeyDown(e, key)}
                label=""
                required
                value={pin[key]}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #7C7C7C",
                  },
                  "& .MuiInputBase-root": {
                    textAlign: "center",
                    maxWidth: "72.96px",
                    height: "80px",
                    border: "2px",
                    borderColor: "#7C7C7C",
                    fontSize: "56px",
                    fontWeight: 400,
                    lineHeight: "80px",
                    color: "primary.main",
                    paddingLeft: 0,
                    "@media (max-width:900px)": {
                      width: "52.84px",
                    },
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    "[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  },
                }}
              />
            );
          })}
        </Stack>
      </FormControl>
      <Stack
        direction={"row"}
        sx={{ mt: 4, alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="body2" sx={{ lineHeight: "20.16px" }}>
          {ReactHtmlParser(t("verify.dontgetthecode"))}
        </Typography>
        <Button
          href="#"
          onClick={() => {
            resendCode();
          }}
          sx={{
            lineHeight: "20.16px",
            fontWeight: 700,
            color: "primary.light",
          }}
        >
          {ReactHtmlParser(t("verify.resend"))}
        </Button>
      </Stack>
    </Box>
  );
};
