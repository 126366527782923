import { Avatar, Box } from "@mui/material";

const ProfileImageDisplayer = ({ version = "small", image, name }) => {
  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials;
  };

  const avatarStyles = {
    bgcolor: "#EBEBEB",
    color: "rgba(43, 54, 116, .6)",
    width: version === "small" ? 45 : version === "hat" ? 32 : 100,
    height: version === "small" ? 45 : version === "hat" ? 32 : 100,
    fontSize: version === "small" ? 24 : version === "hat" ? 16 : 36,
  };

  const boxStyle = {
    width: version === "small" ? 45 : version === "hat" ? 32 : 100,
    height: version === "small" ? 45 : version === "hat" ? 32 : 100,
  };

  return (
    <Box sx={boxStyle}>
      <Avatar
        sx={avatarStyles}
        src={image ? `data:image/jpeg;base64,${image}` : undefined}
      >
        {!image && getInitials(name)}
      </Avatar>
    </Box>
  );
};

export default ProfileImageDisplayer;
