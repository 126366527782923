const initialState = {
  files: [],
  loading: false,
  error: null,
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOAD_FILE_REQUEST":
      return { ...state, loading: true, error: null };
    case "UPLOAD_FILE_SUCCESS":
      return {
        ...state,
        loading: false,
        files: [...state.files, action.payload],
      };
    case "SET_UPLOADED_FILES":
      console.log('SET_UPLOADED_FILES', action.payload);
      return {
        ...state,
        loading: false,
        files: action.payload,
      };
    case "UPLOAD_FILE_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "UPLOAD_FILE_REMOVE":
      return {
        ...state,
        files: state.files.filter((f) => f.id !== action.payload),
      };
    case "RESET_FILE_STATE":
        return { ...state, loading: false, files: [] };
    default:
      return state;
  }
};
export default fileReducer;
