import ReactHtmlParser from "html-react-parser";
import { t } from "i18next";
import DefaultLayout from "../Layout/DefaultLayout";
import { Box, Typography, Stack, Button } from "@mui/material";
import { AboutUsCard } from "./AboutUsCard";
import { InfoCard } from "./InfoCard";
import { useNavigate } from "react-router-dom";
import img1 from "../../Assets/Heni 1.png";
import img2 from "../../Assets/József 1.png";

const AboutUs = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  const registrationPage = () => {
    navigate("/registration");
  };

  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        <div
          className="my-container"
          style={{ minHeight: "100vh", paddingBottom: 10 }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ mt: 8, mb: 6 }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="text"
              sx={{
                height: "50px",
                width: "50px !important",
                minWidth: "50px",
                p: 0,
                boxShadow: "0px 0px 10px 0px #0000001A",
                borderRadius: "32px",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.19697 14.5546L8.4042 15.3477C8.06852 15.6834 7.52573 15.6834 7.19362 15.3477L0.251538 8.40706C-0.0841421 8.07128 -0.0841421 7.52833 0.251538 7.19612L7.19362 0.251947C7.5293 -0.0838342 8.07209 -0.0838342 8.4042 0.251947L9.19697 1.04496C9.53622 1.38431 9.52908 1.93798 9.18268 2.27018L4.87959 6.37096H15.1427C15.6177 6.37096 15.9998 6.75317 15.9998 7.22826V8.37134C15.9998 8.84643 15.6177 9.22865 15.1427 9.22865H4.87959L9.18268 13.3294C9.53264 13.6616 9.53978 14.2153 9.19697 14.5546Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
            <Typography variant="body1" sx={{ fontWeight: 700, pl: 6 }}>
              {ReactHtmlParser(t("aboutUs.mainTitle"))}
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ mb: 3 }}>
              {ReactHtmlParser(t("aboutUs.title1"))}
            </Typography>
            <svg
              width="175"
              height="13"
              viewBox="0 0 175 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3288_8544)">
                <path
                  d="M38.0874 2.64715C54.7105 2.2664 71.3427 2.02163 87.9749 1.91284C104.607 1.80406 121.085 1.84032 137.636 2.01256C147.038 2.11229 156.449 2.24827 165.851 2.42958L167.127 0.571142C145.663 0.94283 124.207 1.40517 102.752 1.95817C81.2967 2.51117 59.8414 3.15482 38.386 3.8982C26.3417 4.31521 14.3064 4.75036 2.26211 5.22177C1.55628 5.24897 0.49754 5.43028 0.0993808 6.11926C-0.271631 6.76291 0.416098 7.09834 0.98619 7.08021C23.3464 6.50908 45.7248 6.45468 68.0941 6.92609C90.4634 7.3975 112.815 8.40378 135.139 9.92679C147.681 10.779 160.214 11.8034 172.729 12.9909C173.407 13.0544 174.529 12.719 174.891 12.0935C175.289 11.4135 174.547 11.1778 174.004 11.1325C151.735 9.02024 129.411 7.4247 107.068 6.35496C84.7263 5.28523 62.3479 4.75036 39.9786 4.7413C27.4095 4.7413 14.8403 4.89541 2.27116 5.22177L0.995239 7.08021C22.4415 6.23711 43.8969 5.49374 65.3522 4.83195C86.8076 4.17923 108.263 3.6081 129.718 3.13669C141.763 2.87379 153.816 2.62902 165.86 2.42051C166.566 2.41145 167.634 2.20294 168.023 1.52303C168.412 0.843109 167.697 0.571142 167.136 0.562077C150.495 0.253848 133.872 0.0634713 117.24 0.0181436C100.607 -0.0271842 84.1291 0.0544058 67.5783 0.281045C58.1763 0.407962 48.7653 0.580208 39.3633 0.797781C38.6575 0.815912 37.5897 1.01535 37.2006 1.69527C36.8296 2.33892 37.5173 2.67435 38.0874 2.65622V2.64715Z"
                  fill="#2B3674"
                />
              </g>
              <defs>
                <clipPath id="clip0_3288_8544">
                  <rect width="175" height="13" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Typography sx={{ textAlign: "center", px: { md: 9 }, mt: 8 }}>
              {ReactHtmlParser(t("aboutUs.text1"))}
            </Typography>
          </Box>
          <InfoCard
            textPos="right"
            title={ReactHtmlParser(t("aboutUs.cardTitle1"))}
            mainText={ReactHtmlParser(t("aboutUs.cardText1"))}
            subText={ReactHtmlParser(t("aboutUs.cardSubText1"))}
          />
          <Box
            sx={{
              mt: 8,
              mb: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ mb: 3 }}>
              {ReactHtmlParser(t("aboutUs.title2"))}
            </Typography>
            <svg
              width="175"
              height="13"
              viewBox="0 0 175 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3288_8544)">
                <path
                  d="M38.0874 2.64715C54.7105 2.2664 71.3427 2.02163 87.9749 1.91284C104.607 1.80406 121.085 1.84032 137.636 2.01256C147.038 2.11229 156.449 2.24827 165.851 2.42958L167.127 0.571142C145.663 0.94283 124.207 1.40517 102.752 1.95817C81.2967 2.51117 59.8414 3.15482 38.386 3.8982C26.3417 4.31521 14.3064 4.75036 2.26211 5.22177C1.55628 5.24897 0.49754 5.43028 0.0993808 6.11926C-0.271631 6.76291 0.416098 7.09834 0.98619 7.08021C23.3464 6.50908 45.7248 6.45468 68.0941 6.92609C90.4634 7.3975 112.815 8.40378 135.139 9.92679C147.681 10.779 160.214 11.8034 172.729 12.9909C173.407 13.0544 174.529 12.719 174.891 12.0935C175.289 11.4135 174.547 11.1778 174.004 11.1325C151.735 9.02024 129.411 7.4247 107.068 6.35496C84.7263 5.28523 62.3479 4.75036 39.9786 4.7413C27.4095 4.7413 14.8403 4.89541 2.27116 5.22177L0.995239 7.08021C22.4415 6.23711 43.8969 5.49374 65.3522 4.83195C86.8076 4.17923 108.263 3.6081 129.718 3.13669C141.763 2.87379 153.816 2.62902 165.86 2.42051C166.566 2.41145 167.634 2.20294 168.023 1.52303C168.412 0.843109 167.697 0.571142 167.136 0.562077C150.495 0.253848 133.872 0.0634713 117.24 0.0181436C100.607 -0.0271842 84.1291 0.0544058 67.5783 0.281045C58.1763 0.407962 48.7653 0.580208 39.3633 0.797781C38.6575 0.815912 37.5897 1.01535 37.2006 1.69527C36.8296 2.33892 37.5173 2.67435 38.0874 2.65622V2.64715Z"
                  fill="#2B3674"
                />
              </g>
              <defs>
                <clipPath id="clip0_3288_8544">
                  <rect width="175" height="13" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Typography sx={{ textAlign: "center", px: { md: 9 }, mt: 8 }}>
              {ReactHtmlParser(t("aboutUs.text2"))}
            </Typography>
          </Box>
          <AboutUsCard
            textPos="left"
            color="#C3F3FF"
            textColor="#2B3674"
            title={ReactHtmlParser(t("aboutUs.cardTitle2"))}
            subTitle={ReactHtmlParser(t("aboutUs.cardSubTitle2"))}
            mainText={ReactHtmlParser(t("aboutUs.cardText2"))}
            link={registrationPage}
            btnText={ReactHtmlParser(t("aboutUs.cardBtnText2"))}
            imgsrc={img1}
          />
          <AboutUsCard
            textPos="right"
            color="#C3F3FF"
            textColor="#2B3674"
            title={ReactHtmlParser(t("aboutUs.cardTitle3"))}
            subTitle={ReactHtmlParser(t("aboutUs.cardSubTitle3"))}
            mainText={ReactHtmlParser(t("aboutUs.cardText3"))}
            link={registrationPage}
            btnText={ReactHtmlParser(t("aboutUs.cardBtnText3"))}
            imgsrc={img2}
          />
        </div>
      </DefaultLayout>
    </>
  );
};

export default AboutUs;
