import React, { useEffect, useReducer, useState, Suspense } from "react";
import { Box, Typography, Button, Stack, Grid, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import "./registration.css";
import { fetchPlans } from "../../store/Actions/planAction";
import { useDispatch, useSelector } from "react-redux";
import PricingCard from "../Profile/Subscription/PricingCard";
import { PricesCardComponent } from "./PricesCardComponent";

export const DelUsrStep4 = ({ globalEvent, setGlobalEvent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { plans, loading } = useSelector((state) => state.plan);

  useState(() => {
    if(plans.length == 0 && loading === false) {
      dispatch(fetchPlans());
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ DeleteUser");
      console.log(globalEvent);
      console.log("------------");
    }

  }, [globalEvent]);

  return (
    <Box
      sx={{
        mt: { sx: 0, sm: 16 },
        mb: 5,
        px: { xs: 2, lg: 0 },
      }}
    >
      <Card 
	  
	  sx={{
          p: 6,
		  borderRadius: "32px",
          mt: { xs: 4, md: 0 },
          mx: { md: 0.5 },
          boxShadow: "0px 0px 5px 0px #0000001A",
        }}>
        
		<Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("deleteuser.step4title"))}
        </Typography>
        <Typography variant="body3">
          {ReactHtmlParser(t("deleteuser.step4text"))}
        </Typography>	  
	  
	  <Grid container justifyContent="center" alignItems="center">
          {plans.map((plan) => (
			(plan.name == 'lifetime')&&(
            <Grid item key={plan.id} sm={12}>
			  <PricesCardComponent
                pricesData={plan}
                onCardSelected={()=>{}}
				onCancelSelected={()=>{setGlobalEvent({ type: "doDeleteUserStart", data: {} });}}
              />
            </Grid>)
          ))}
        </Grid>

      </Card>
    </Box>
  );
};
