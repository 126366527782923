import { Typography, Grid, Stack, Box, Button } from "@mui/material";
import { GamificationCard } from "../Dashboard/GamificationCard";
import { AssetCard } from "./Assetcard";
import { TotalValueCard } from "./TotalValueCard";
import { EmptyListCard } from "./EmptyListCard";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";

import React, { useEffect, useReducer, useState, Suspense } from "react";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

import { useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";

import exchangeVal from "../Commons/CurrencyExchange";
import BannerDisplayer from "../Banner/BannerDisplayer";

import { useDispatch, useSelector } from "react-redux";
import { checkAccess, fetchHats } from "../../store/Slices/hatsSlice";
import { EmptyCard } from "../Common/EmptyCard";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import NoAccess from "../Commons/NoAccess";

const AssetListIndex = ({ globalEvent, setGlobalEvent, title }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const [user, setUser] = useState();
  const [contacts, setContacts] = useState();
  const [assets, setAssets] = useState();
  const [sums, setSums] = useState();

  const [filterType, setFilterType] = useState("");
  const [fixFilter, setFixFilter] = useState(false);
  const [recCount, setRecCount] = useState(3);

  const [vehicleList, setVehicleList] = useState([]);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [realEstateList, setRealEstateList] = useState([]);

  const [insuranceList, setInsuranceList] = useState([]);
  const [securityList, setSecurityList] = useState([]);
  const [housingSavingsList, setHousingSavingsList] = useState([]);
  const [creditList, setCreditList] = useState([]);
  const [shareList, setShareList] = useState([]);
  const [claimList, setClaimList] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const chid = localStorage.getItem("chid");

  const [assetType, setAssetType] = useState("");

  const [baseCurrency, setBaseCurrency] = useState(() => {
    const bCurrency = localStorage.getItem("baseCurrency");
    return bCurrency ? bCurrency : "huf";
  });

  //sum assets function

  const sumAssets = (assetsList) => {
    let sumsLocal = [];

    let veList = [];
    let baList = [];
    let reList = [];

    let inList = [];
    let seList = [];
    let hsList = [];
    let crList = [];
    let shList = [];
    let cyList = [];
    let clList = [];
    let otList = [];

    let val = 0;
    //	  console.log('baseCurrency:'+baseCurrency);
    for (let i = 0; i < assetsList?.length; i++) {
      val = 0;
      val = !isNaN(assetsList[i].value * 1) ? assetsList[i].value * 1 : 0;
      //		console.log('val '+assetsList[i].currency+':'+val);
      if (
        assetsList[i].currency?.toLowerCase() == baseCurrency?.toLowerCase()
      ) {
        sumsLocal[assetsList[i].assetType] =
          (!isNaN(sumsLocal[assetsList[i].assetType] * 1)
            ? sumsLocal[assetsList[i].assetType] * 1
            : 0) + val;
        sumsLocal["total"] =
          (!isNaN(sumsLocal["total"] * 1) ? sumsLocal["total"] * 1 : 0) + val;
      } else {
        val = exchangeVal(
          val,
          assetsList[i].currency ? assetsList[i].currency.toLowerCase() : "huf",
          "usd"
        );
        ///		console.log('val USD:'+val);
        val = Math.ceil(
          exchangeVal(val, "usd", baseCurrency.toLowerCase()) * 1
        );
        //		console.log('val'+baseCurrency+':'+val)
        sumsLocal[assetsList[i].assetType] =
          (!isNaN(sumsLocal[assetsList[i].assetType] * 1)
            ? sumsLocal[assetsList[i].assetType] * 1
            : 0) + val;
        sumsLocal["total"] =
          (!isNaN(sumsLocal["total"] * 1) ? sumsLocal["total"] * 1 : 0) + val;
      }

      if (assetsList[i].assetType == "bankAccount") {
        baList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "realEstate") {
        reList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "vehicle") {
        veList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "insurance") {
        inList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "security") {
        seList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "housingSavings") {
        hsList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "credit") {
        crList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "crypto") {
        cyList.push(assetsList[i]);
      }
      if (assetsList[i].assetType == "claim") {
        clList.push(assetsList[i]);
      }

      if (assetsList[i].assetType == "share") {
        shList.push(assetsList[i]);
      }
      if (assetsList[i].assetType == "other") {
        otList.push(assetsList[i]);
      }
    }

    setSums(sumsLocal);

    console.log(sumsLocal);

    setBankAccountList(baList.reverse());
    setVehicleList(veList.reverse());
    setRealEstateList(reList.reverse());
    setInsuranceList(inList.reverse());
    setSecurityList(seList.reverse());
    setHousingSavingsList(hsList.reverse());
    setCreditList(crList.reverse());
    setClaimList(clList.reverse());
    setCryptoList(cyList.reverse());

    setShareList(shList.reverse());
    setOtherList(otList.reverse());
  };

  const dispatch = useDispatch();
  const { access, status: hatsStatus } = useSelector((state) => state.hats);

  useEffect(() => {
    dispatch(checkAccess(chid));
  }, [chid, dispatch, hatsStatus]);

  const [onLoad, setOnLoad] = useState(true);

  useEffect(() => {
    setOnLoad(true);
    setGlobalEvent({ type: "doGetUser", data: {} });
  }, []);

  useEffect(() => {
    //const filteredItems = assets?.filter(item => item.value !== null && item.currency !== null);
    const filteredItems = assets;
    sumAssets(filteredItems);
  }, [baseCurrency]);

  useEffect(() => {
    console.log(state);
    setFilterType(state?.filterType ? state?.filterType : "");
    setFixFilter(state?.filterType ? true : false);
  }, [state]);

  useEffect(() => {
    setRecCount(filterType == "" ? 3 : 9999);
  }, [filterType]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      console.log("------ Global Event ------ AssetList");
      console.log(globalEvent);
      console.log("------------");
    }

    if (globalEvent?.type == "responseGetUser") {
      console.log(globalEvent?.data.user);
      setUser(globalEvent?.data.user);
    }

    if (globalEvent?.type == "responseGetContacts") {
      console.log(globalEvent?.data);
      setContacts(globalEvent?.data?.contacts);
    }

    if (globalEvent?.type == "responseGetSums") {
      console.log(globalEvent?.data);

      //const filteredItems = globalEvent?.data?.assets?.filter(item => item.value !== null && item.currency !== null);
      const filteredItems = globalEvent?.data?.assets;
      setAssets(filteredItems);
      sumAssets(filteredItems);
      setOnLoad(false);
    }

    if (globalEvent?.type == "responseCheckPlan") {
      if (globalEvent?.data.link == assetType) {
        navigate(globalEvent?.data.link, {
          state: {
            editmode: true,
            data: {
              value: "0",
              currency: baseCurrency?.toUpperCase(),
              details: {},
            },
          },
        });
      }
    }
  }, [globalEvent]);

  if (
    access?.accessAssets == false &&
    access?.accessTypeAssets == "onDeath" &&
    assets?.length * 1 == 0
  ) {
    return (
      <NoAccess
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
        title={title}
      />
    );
  } else {
    return (
      <>
        <DefaultLayout
          globalEvent={globalEvent}
          setGlobalEvent={setGlobalEvent}
          title={title}
        >
          <BannerDisplayer position={"top"} page={"asset_page"} />

          <Box className="my-container">
            <Grid
              sx={{
                mt: { xs: 0, sm: 8 },
                mb: 20,
                textAlign: "left",
              }}
              container
            >
              <Grid item xs={12} sx={{ pr: { md: 3 } }}>
                <Stack
                  direction={{ sx: "column", md: "row" }}
                  justifyContent={"space-between"}
                  sx={{ mb: 6 }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mb: { xs: 3, md: 0 },
                      pl: { xs: 2, sm: 0 },
                      fontWeight: 700,
                    }}
                  >
                    {fixFilter === false &&
                      realEstateList?.length * 1 < 1 &&
                      bankAccountList?.length * 1 < 1 &&
                      vehicleList?.length * 1 < 1 &&
                      insuranceList?.length * 1 < 1 &&
                      securityList?.length * 1 < 1 &&
                      housingSavingsList?.length * 1 < 1 &&
                      creditList?.length * 1 < 1 &&
                      cryptoList?.length * 1 < 1 &&
                      claimList?.length * 1 < 1 &&
                      shareList?.length * 1 < 1 &&
                      otherList?.length * 1 < 1 && (
                        <>{ReactHtmlParser(t("asset.assetList"))}</>
                      )}

                    {fixFilter === false &&
                      (realEstateList?.length * 1 > 0 ||
                        bankAccountList?.length * 1 > 0 ||
                        vehicleList?.length * 1 > 0 ||
                        insuranceList?.length * 1 > 0 ||
                        securityList?.length * 1 > 0 ||
                        housingSavingsList?.length * 1 > 0 ||
                        creditList?.length * 1 > 0 ||
                        cryptoList?.length * 1 > 0 ||
                        claimList?.length * 1 > 0 ||
                        shareList?.length * 1 > 0 ||
                        otherList?.length * 1 > 0) && (
                        <>
                          {ReactHtmlParser(t("asset.assetList"))}{" "}
                          {"(" +
                            (realEstateList?.length * 1 +
                              bankAccountList?.length * 1 +
                              vehicleList?.length * 1 +
                              insuranceList?.length * 1 +
                              securityList?.length * 1 +
                              housingSavingsList?.length * 1 +
                              creditList?.length * 1 +
                              claimList?.length * 1 +
                              cryptoList?.length * 1 +
                              shareList?.length * 1 +
                              otherList?.length * 1) +
                            ")"}
                        </>
                      )}

                    {fixFilter === true && filterType == "vehicle" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.vehicle"))} (
                        {vehicleList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "bankAccount" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.bankAccount"))} (
                        {bankAccountList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "realEstate" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.realEstate"))} (
                        {realEstateList?.length}){" "}
                      </>
                    )}

                    {fixFilter === true && filterType == "insurance" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.insurance"))} (
                        {insuranceList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "security" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.security"))} (
                        {securityList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "housingSavings" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.housingSavings"))} (
                        {housingSavingsList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "credit" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.credit"))} (
                        {creditList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "crypto" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.crypto"))} (
                        {cryptoList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "claim" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.claim"))} (
                        {claimList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "share" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.share"))} (
                        {shareList?.length}){" "}
                      </>
                    )}
                    {fixFilter === true && filterType == "other" && (
                      <>
                        {" "}
                        {ReactHtmlParser(t("asset-type.other"))} (
                        {otherList?.length}){" "}
                      </>
                    )}
                  </Typography>
                  {!chid || chid === "null" ? (
                    <Button
                      onClick={(e) => {
                        navigate("/add-new-asset");
                      }}
                      className="small-btn green-btn"
                      variant="contained"
                      sx={{
                        height: "52px",
                        width: "auto !important",
                        bgcolor: "socialLink.main",
                      }}
                      endIcon={
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                            fill="#E5FE4A"
                          />
                        </svg>
                      }
                    >
                      {ReactHtmlParser(t("asset.addNewAsset"))}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                {(filterType === "" || filterType === "realEstate") &&
                  realEstateList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.realEstate"))} (
                                {realEstateList?.length * 1})
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(
                                  filterType == "" ? "realEstate" : ""
                                );
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {realEstateList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="property"
                                title={i.details?.name}
                                info={
                                  i.currency
                                    ? i.details?.aPostalcode +
                                      " " +
                                      i.details?.aCity +
                                      " " +
                                      i.details?.aStreet +
                                      " " +
                                      i.details?.aAddress
                                    : ""
                                }
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/real-estate", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.realEstateSum"))}
                            value={
                              !isNaN(sums?.realEstate * 1)
                                ? sums?.realEstate * 1
                                : 0
                            }
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/real-estate");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "realEstate",
                                      link: "/add-new-asset/real-estate",
                                    },
                                  });

                                  /*  navigate("/add-new-asset/real-estate", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: "0",
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
                                */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.realEstateAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "bankAccount") &&
                  bankAccountList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.bankAccount"))} (
                                {bankAccountList?.length * 1})
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(
                                  filterType == "" ? "bankAccount" : ""
                                );
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {bankAccountList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="bankAccount"
                                title={i.details?.bankName}
                                info={
                                  i.currency ? i.details?.accountNumber : ""
                                }
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/bank-account", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.bankAccountSum"))}
                            value={
                              !isNaN(sums?.bankAccount * 1)
                                ? sums?.bankAccount * 1
                                : 0
                            }
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/bank-account");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "bankAccount",
                                      link: "/add-new-asset/bank-account",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/bank-account", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: "0",
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
                                */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.bankAccountAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "vehicle") &&
                  vehicleList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.vehicle"))} (
                                {vehicleList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(
                                  filterType == "" ? "vehicle" : ""
                                );
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {vehicleList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="vehicle"
                                title={
                                  i.currency
                                    ? i.details?.make +
                                      " " +
                                      i.details?.model +
                                      " (" +
                                      i.details?.year +
                                      ")"
                                    : ""
                                }
                                info={i.details?.plateNumber}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/vehicle", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.vehicleSum"))}
                            value={
                              !isNaN(sums?.vehicle * 1) ? sums?.vehicle * 1 : 0
                            }
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/vehicle");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "vehicle",
                                      link: "/add-new-asset/vehicle",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/vehicle", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
                                */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.vehicleAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {/*============================================*/}

                {(filterType === "" || filterType === "insurance") &&
                  insuranceList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.insurance"))} (
                                {insuranceList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(
                                  filterType == "" ? "insurance" : ""
                                );
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {insuranceList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="insurance"
                                title={i.details?.name}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/insurance", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.insuranceSum"))}
                            value={
                              !isNaN(sums?.insurance * 1)
                                ? sums?.insurance * 1
                                : 0
                            }
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/insurance");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "insurance",
                                      link: "/add-new-asset/insurance",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/insurance", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
                                  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.insuranceAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "security") &&
                  securityList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.security"))} (
                                {securityList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(
                                  filterType == "" ? "security" : ""
                                );
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {securityList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="security"
                                title={i.details?.name}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/security", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.securitySum"))}
                            value={
                              !isNaN(sums?.security * 1)
                                ? sums?.security * 1
                                : 0
                            }
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/security");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "security",
                                      link: "/add-new-asset/security",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/security", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.securityAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "housingSavings") &&
                  housingSavingsList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(
                                  t("asset-type.housingSavings")
                                )}{" "}
                                ({housingSavingsList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(
                                  filterType == "" ? "housingSavings" : ""
                                );
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {housingSavingsList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="housingSavings"
                                title={i.details?.name}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/housing-savings", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(
                              t("asset.housingSavingsSum")
                            )}
                            value={
                              !isNaN(sums?.housingSavings * 1)
                                ? sums?.housingSavings * 1
                                : 0
                            }
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType(
                                    "/add-new-asset/housing-savings"
                                  );
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "housingSavings",
                                      link: "/add-new-asset/housing-savings",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/housing-savings", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.housingSavingsAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "credit") &&
                  creditList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.credit"))} (
                                {creditList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(filterType == "" ? "credit" : "");
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {creditList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="credit"
                                title={i.details?.bankName}
                                info={""}
                                value={
                                  ((i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )) * -1
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={-i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/credit", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}
                          {sums?.credit === 0 ? (
                            <></>
                          ) : (
                            <TotalValueCard
                              title={ReactHtmlParser(t("asset.creditSum"))}
                              value={
                                !isNaN(sums?.credit * 1) ? sums?.credit * -1 : 0
                              }
                              currency={baseCurrency.toUpperCase()}
                            />
                          )}
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/credit");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "credit",
                                      link: "/add-new-asset/credit",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/credit", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								 */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.creditAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "share") &&
                  shareList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.share"))} (
                                {shareList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(filterType == "" ? "share" : "");
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {shareList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="share"
                                title={i.details?.companyName}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/share", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          {false && (
                            <TotalValueCard
                              title={ReactHtmlParser(t("asset.shareSum"))}
                              value={!isNaN(sums?.share * 1) ? sums?.share : 0}
                              currency={baseCurrency.toUpperCase()}
                            />
                          )}
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/share");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "share",
                                      link: "/add-new-asset/share",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/share", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.shareAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "claim") &&
                  claimList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.claim"))} (
                                {claimList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(filterType == "" ? "claim" : "");
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {claimList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="claim"
                                title={i.details?.debtor}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency?.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/claim", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.claimSum"))}
                            value={!isNaN(sums?.claim * 1) ? sums?.claim : 0}
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/claim");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "claim",
                                      link: "/add-new-asset/claim",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/claim", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.claimAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "crypto") &&
                  cryptoList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.crypto"))} (
                                {cryptoList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(filterType == "" ? "crypto" : "");
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {cryptoList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="crypto"
                                title={i.details?.name}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/crypto", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.cryptoSum"))}
                            value={!isNaN(sums?.crypto * 1) ? sums?.crypto : 0}
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/crypto");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "crypto",
                                      link: "/add-new-asset/crypto",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/crypto", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.cryptoAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {(filterType === "" || filterType === "other") &&
                  otherList?.length > 0 && (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              mb: 6,
                              pl: { xs: 2, sm: 0 },
                              fontWeight: 700,
                            }}
                          >
                            {fixFilter === false && (
                              <>
                                {" "}
                                {ReactHtmlParser(t("asset-type.other"))} (
                                {otherList?.length}){" "}
                              </>
                            )}
                          </Typography>
                          {fixFilter === false && (
                            <Button
                              onClick={(e) => {
                                setFilterType(filterType == "" ? "other" : "");
                              }}
                              variant="text"
                              sx={{ height: "52px", width: "auto !important" }}
                              endIcon={
                                <svg
                                  width="16"
                                  height="12"
                                  viewBox="0 0 16 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                                    fill="#62C275"
                                  />
                                </svg>
                              }
                            >
                              {ReactHtmlParser(t("common.showAll"))}
                            </Button>
                          )}
                        </Stack>
                        <Stack spacing={2}>
                          {otherList.slice(0, recCount).map((i) => {
                            return (
                              <AssetCard
                                icon="other"
                                title={i.details?.name}
                                info={""}
                                value={
                                  (i.currency
                                    ? i.currency.toLowerCase()
                                    : "huf") == baseCurrency.toLowerCase()
                                    ? !isNaN(i.value * 1)
                                      ? i.value * 1
                                      : 0
                                    : Math.ceil(
                                        exchangeVal(
                                          !isNaN(i.value * 1) ? i.value * 1 : 0,
                                          i.currency
                                            ? i.currency.toLowerCase()
                                            : "huf",
                                          baseCurrency.toLowerCase()
                                        ) * 1
                                      )
                                }
                                currency={baseCurrency.toUpperCase()}
                                value2={i.value}
                                currency2={i.currency.toUpperCase()}
                                linkTo={(e) => {
                                  navigate("/add-new-asset/other", {
                                    state: { data: i },
                                  });
                                }}
                              />
                            );
                          })}

                          <TotalValueCard
                            title={ReactHtmlParser(t("asset.otherSum"))}
                            value={!isNaN(sums?.other * 1) ? sums?.other : 0}
                            currency={baseCurrency.toUpperCase()}
                          />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3 }}>
                          {(!chid || chid == "null") && (
                            <>
                              <Button
                                onClick={(event) => {
                                  setAssetType("/add-new-asset/other");
                                  setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "asset",
                                      subtype: "other",
                                      link: "/add-new-asset/other",
                                    },
                                  });
                                  /*
								  navigate("/add-new-asset/other", {
                                    state: {
                                      editmode: true,
                                      data: {
                                        value: 0,
                                        currency: baseCurrency?.toUpperCase(),
                                        details: {},
                                      },
                                    },
                                  });
								  */
                                }}
                                variant={"text"}
                                sx={{ color: "primary.light" }}
                                startIcon={
                                  <svg
                                    width="20"
                                    height="14"
                                    viewBox="0 0 20 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.49997 9.74995C1.28747 9.74995 1.10936 9.67805 0.965625 9.53425C0.821875 9.39043 0.75 9.21223 0.75 8.99965C0.75 8.78705 0.821875 8.60896 0.965625 8.46538C1.10936 8.32179 1.28747 8.25 1.49997 8.25H7C7.2125 8.25 7.39063 8.3219 7.53438 8.4657C7.67813 8.60952 7.75 8.78772 7.75 9.0003C7.75 9.2129 7.67813 9.39099 7.53438 9.53458C7.39063 9.67816 7.2125 9.74995 7 9.74995H1.49997ZM1.49997 5.74995C1.28747 5.74995 1.10936 5.67805 0.965625 5.53425C0.821875 5.39043 0.75 5.21223 0.75 4.99965C0.75 4.78705 0.821875 4.60896 0.965625 4.46538C1.10936 4.32179 1.28747 4.25 1.49997 4.25H11C11.2125 4.25 11.3906 4.3219 11.5344 4.4657C11.6781 4.60952 11.75 4.78772 11.75 5.0003C11.75 5.2129 11.6781 5.39099 11.5344 5.53458C11.3906 5.67816 11.2125 5.74995 11 5.74995H1.49997ZM1.49997 1.74995C1.28747 1.74995 1.10936 1.67805 0.965625 1.53425C0.821875 1.39043 0.75 1.21223 0.75 0.999651C0.75 0.787051 0.821875 0.60896 0.965625 0.465376C1.10936 0.321793 1.28747 0.25 1.49997 0.25H11C11.2125 0.25 11.3906 0.321901 11.5344 0.465701C11.6781 0.609518 11.75 0.787717 11.75 1.0003C11.75 1.2129 11.6781 1.39099 11.5344 1.53457C11.3906 1.67816 11.2125 1.74995 11 1.74995H1.49997ZM14.4997 13.75C14.2871 13.75 14.109 13.6781 13.9654 13.5343C13.8218 13.3906 13.75 13.2125 13.75 13V9.74995H10.5C10.2875 9.74995 10.1094 9.67805 9.96563 9.53425C9.82188 9.39043 9.75 9.21223 9.75 8.99965C9.75 8.78705 9.82188 8.60896 9.96563 8.46538C10.1094 8.32179 10.2875 8.25 10.5 8.25H13.75V4.99998C13.75 4.78748 13.8219 4.60935 13.9657 4.4656C14.1095 4.32187 14.2877 4.25 14.5003 4.25C14.7129 4.25 14.891 4.32187 15.0346 4.4656C15.1782 4.60935 15.25 4.78748 15.25 4.99998V8.25H18.5C18.7125 8.25 18.8906 8.3219 19.0344 8.4657C19.1781 8.60952 19.25 8.78772 19.25 9.0003C19.25 9.2129 19.1781 9.39099 19.0344 9.53458C18.8906 9.67816 18.7125 9.74995 18.5 9.74995H15.25V13C15.25 13.2125 15.1781 13.3906 15.0343 13.5343C14.8904 13.6781 14.7122 13.75 14.4997 13.75Z"
                                      fill="#1542B9"
                                    />
                                  </svg>
                                }
                              >
                                {ReactHtmlParser(t("asset.otherAdd"))}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}

                {!onLoad &&
                  realEstateList?.length * 1 < 1 &&
                  bankAccountList?.length * 1 < 1 &&
                  vehicleList?.length * 1 < 1 &&
                  insuranceList?.length * 1 < 1 &&
                  securityList?.length * 1 < 1 &&
                  housingSavingsList?.length * 1 < 1 &&
                  creditList?.length * 1 < 1 &&
                  claimList?.length * 1 < 1 &&
                  cryptoList?.length * 1 < 1 &&
                  shareList?.length * 1 < 1 &&
                  otherList?.length * 1 < 1 &&
                  (!chid || chid === "null" ? (
                    <EmptyCard
                      icon={
                        <ListAltRoundedIcon
                          sx={{ fontSize: 42, fill: "#2B3674" }}
                        />
                      }
                      buttonClass="small-btn green-btn"
                      title={t("dashboard.asset.noAssetTitle")}
                      description={t("dashboard.asset.noAssetDescription")}
                      buttonText={t("dashboard.asset.noAssetButtonText")}
                      buttonLink="/add-new-asset"
                    />
                  ) : chid ? (
                    <>{t("common.noitemtodisplay")}</>
                  ) : (
                    <></>
                  ))}

                <BannerDisplayer position={"bottom"} page={"asset_page"} />
              </Grid>

              <Grid item xs={12} md={5} sx={{ px: { md: 3 }, mt: 0 }}>
                <Stack direction={"column"} spacing={2}>
                  <HelpCard />
                  <ReferralCard />
                  <BannerDisplayer position={"right"} page={"asset_page"} />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </DefaultLayout>
      </>
    );
  }
};

export default AssetListIndex;
