import { Stack, Typography } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import { useTranslation } from "react-i18next";

const AccessDisplayRow = ({ accessData, title }) => {
  const { t } = useTranslation();
  return (
    <>
      {["instant", "timed", "onDeath"].includes(accessData) && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mt: 0, borderTop: "1px solid #EBEBEB", py: 1 }}
        >
          <Stack direction="column" spacing={0} alignItems="left">
            <Typography
              variant="body3"
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "18px",
              }}
            >
              {ReactHtmlParser(title)}
            </Typography>
            <Typography
              variant="body4"
              sx={{
                color: "primary.main",
                mt: 1,
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "12.6px",
              }}
            >
              {t("contact.accesstype." + accessData)}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default AccessDisplayRow;
